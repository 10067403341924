/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { Navigate } from 'react-router-dom';
import useLocalStorage from '../hooks/useLocalStorage';
import { getLocalStorage } from '../utils/functions';

const PublicRoute = ({ children, redirect }) => {
    const user = getLocalStorage('user');

    const isAuthenticated = getLocalStorage('isAuthenticated');
    console.log('isAuthenticated', isAuthenticated);

    if (isAuthenticated) {
        return <Navigate to="/" />;
    }

    return children;
};

export default PublicRoute;
