import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useSearchFicharPost } from '../FicharPost/store/store';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto', // Agregar scroll vertical cuando sea necesario
    maxHeight: '80vh' // Limitar la altura para evitar que el modal sea demasiado grande
};

export default function BasicModal({
    buttonTitle,
    title,
    children,
    styles = style,
    sxButton = {},
    free = false
}) {
    // const [open, setOpen] = React.useState(false);
    const { openModal, setOpenModal } = useSearchFicharPost();
    const [open, setOpen] = React.useState();
    const handleOpen = () => (free ? setOpen(true) : setOpenModal(true));
    const handleClose = () => (free ? setOpen(false) : setOpenModal(false));

    return (
        <Box>
            <Button {...sxButton} onClick={handleOpen}>
                {buttonTitle}
            </Button>
            <Modal
                open={free ? open : openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 1 }}>
                <Box sx={styles}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        {title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {children || 'No content'}
                    </Typography>
                </Box>
            </Modal>
        </Box>
    );
}
