import React, { useState } from 'react';
import { useEffect } from 'react';
import {
    Alert,
    Button,
    Col,
    Form,
    InputGroup,
    Modal,
    Row,
    Spinner,
    Tab,
    Tabs
} from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { ReactSortable } from 'react-sortablejs';
import { useDispatch, useStore } from '../../store/StoreProvider';
import {
    acortadorCustom3,
    acortadorCustom2,
    acortadorHdLatino
} from '../../utils/acortadores';
import {
    crearArrayInicialAcortadores,
    getLocalStorage,
    getNameValues,
    isValidURL
} from '../../utils/functions';
import UnstyledTextareaIntroduction from '../Telegram/components/TextArea';
import CustomTextField from '../FicharPost/components/CustomTextField';

// const userData = JSON.parse(localStorage.getItem('user')) || {};
// const userData = getLocalStorage('user');

// // const arrayItemsAcortadorInitial = [
// //     { id: 1, name: 'Ouo', key: 'ouo' },
// //     { id: 2, name: 'Cpmlink', key: 'cpmlink' },
// //     { id: 3, name: 'Shrinkme', key: 'shrinkme' },
// //     { id: 4, name: 'Exe', key: 'exe' },
// //     { id: 5, name: 'Fc', key: 'fc' }
// // ];
// const arrayItemsAcortadorInitial = crearArrayInicialAcortadores(
//     userData?.acortadores
// );
// const arrayItemsAcortador = [];

// for (let i = 0; i < arrayItemsAcortadorInitial.length; i++) {
//     let element = arrayItemsAcortadorInitial[i];
//     if (userData[element.key]?.length > 1) {
//         arrayItemsAcortador.push(element);
//     }
// }

// const objInputValues = {
//     ouo: '',
//     cpmlink: '',
//     shrinkme: '',
//     exe: '',
//     fc: ''
// };

const ObjAcortadorHdLatino = {
    id: 999999,
    acor_nombre: 'hdlatino',
    acor_token: 'https://hdlatino.us/api?api=',
    acor_userId: 1
};

function MyVerticallyCenteredModal(props) {
    const { handleAcortadorInputList, inputList } = props;
    console.log(inputList, 'inputList');

    const userData = getLocalStorage('user');
    const arrayItemsAcortadorInitial = crearArrayInicialAcortadores([
        ...(userData?.acortadores || [])
        // ObjAcortadorHdLatino
    ]);
    const arrayItemsAcortador = [];

    for (let i = 0; i < arrayItemsAcortadorInitial.length; i++) {
        let element = arrayItemsAcortadorInitial[i];
        if (userData[element.key]?.length > 1) {
            arrayItemsAcortador.push(element);
        }
    }

    const objInputValues = getNameValues(arrayItemsAcortadorInitial);

    const { CHANGE_ENLACE_STORED_SHORT } = useStore();
    const dispatch = useDispatch();
    // const [state, setState] = useState(arrayItemsAcortador);
    const [state, setState] = useState(arrayItemsAcortadorInitial);
    const [inputValues, setInputValues] = useState(objInputValues);
    const [loading, setLoading] = useState(false);
    // const [enlaceTemporal, setEnlaceTemporal] = useState('');

    // useEffect(() => {
    //     setEnlaceTemporal(props?.enlace);
    // }, [props?.enlace]);

    const handleSubmitSortable = async e => {
        e.preventDefault();
        e.stopPropagation();
        let linkAcortado = props?.enlace;

        for (const item of state) {
            const { id, name, key, host } = item;
            const { [key]: value } = inputValues;

            if (value) {
                setLoading(true);
                try {
                    for (let i = 0; i < value; i++) {
                        if (key === 'ouo') {
                            linkAcortado = await acortadorCustom3(
                                linkAcortado,
                                host
                            );
                            linkAcortado = `https://ouo.io${
                                new URL(linkAcortado).pathname
                            }`;
                            toast.success(linkAcortado);
                        } else if (key === 'hdlatino') {
                            linkAcortado = await acortadorHdLatino(
                                linkAcortado
                            );
                            linkAcortado = linkAcortado.replace(/\r\n/g, '');
                            toast.success(linkAcortado);
                        } else {
                            linkAcortado = await acortadorCustom3(
                                linkAcortado,
                                host
                            );
                            linkAcortado = linkAcortado.replace(/\r\n/g, '');
                            toast.success(linkAcortado);
                        }
                    }
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    toast.error('Error:', key, error);
                }
            }
        }
        return handleAcortadorInputList(linkAcortado, props?.id);
        // dispatch({ type: types.VIEW, payload: sumar });
    };
    const handleSubmitSortableAll = async e => {
        e.preventDefault();
        e.stopPropagation();

        for (const inputObject of inputList) {
            if (isValidURL(inputObject.enlace) === false) {
                continue;
            }
            let linkAcortado = inputObject.enlace;

            for (const item of state) {
                const { id, name, key, host } = item;
                const { [key]: value } = inputValues;

                if (value) {
                    setLoading(true);
                    try {
                        for (let i = 0; i < value; i++) {
                            if (key === 'ouo') {
                                linkAcortado = await acortadorCustom3(
                                    linkAcortado,
                                    host
                                );
                                linkAcortado = `https://ouo.io${
                                    new URL(linkAcortado).pathname
                                }`;
                                toast.success(linkAcortado);
                            } else if (key === 'hdlatino') {
                                linkAcortado = await acortadorHdLatino(
                                    linkAcortado
                                );
                                linkAcortado = linkAcortado.replace(
                                    /\r\n/g,
                                    ''
                                );
                                toast.success(linkAcortado);
                            } else {
                                linkAcortado = await acortadorCustom3(
                                    linkAcortado,
                                    host
                                );
                                linkAcortado = linkAcortado.replace(
                                    /\r\n/g,
                                    ''
                                );
                                toast.success(linkAcortado);
                            }
                        }
                        setLoading(false);
                    } catch (error) {
                        setLoading(false);
                        toast.error('Error:', key, error);
                    }
                }
            }
            handleAcortadorInputList(linkAcortado, inputObject?.id);
        }
        // dispatch({ type: types.VIEW, payload: sumar });
    };

    const linksAllTotext = inputList?.map(item => item.enlace)?.join('\n');

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Form
                id="sortable"
                onSubmit={
                    inputList && inputList.length > 0
                        ? handleSubmitSortableAll
                        : handleSubmitSortable
                }>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Acortar Enlaces
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <Row> */}
                    <Tabs
                        defaultActiveKey="oneLink"
                        id="justify-tab-example"
                        size="sm">
                        <Tab
                            eventKey="oneLink"
                            title="Default"
                            className="p-2"
                            style={{
                                border: '1px solid #dee2e6',
                                borderTop: 0
                            }}>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder="Enlace"
                                            aria-label="Enlace"
                                            aria-describedby="basic-addon1"
                                            value={props?.enlace}
                                            // onChange={e =>
                                            //     setEnlaceTemporal(e.target.value)
                                            // }
                                        />
                                    </InputGroup>
                                    <ReactSortable
                                        // chosenClass="alert"
                                        // group="alert"
                                        animation={200}
                                        // delayOnTouchStart={true}
                                        // delay={2}
                                        list={state}
                                        setList={setState}>
                                        {state.map(item => (
                                            <>
                                                <Row
                                                    className="d-flex gap-1 align-items-center justify-content-center"
                                                    key={item.id}>
                                                    <Col>
                                                        <Alert
                                                            // key={item.id}
                                                            variant="primary">
                                                            {item.name}
                                                        </Alert>
                                                    </Col>
                                                    <Col>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control
                                                                // key={item.id}
                                                                type="number"
                                                                placeholder="Numero de veces a acortar"
                                                                aria-label={
                                                                    item.name
                                                                }
                                                                name={item.key}
                                                                value={
                                                                    inputValues[
                                                                        item.key
                                                                    ]
                                                                }
                                                                onChange={e => {
                                                                    setInputValues(
                                                                        {
                                                                            ...inputValues,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                        }
                                                                    );
                                                                }}
                                                                aria-describedby="basic-addon1"
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </>
                                        ))}
                                    </ReactSortable>
                                </Col>
                            </Row>
                            <Col className="d-grid">
                                <Button
                                    className="d-flex align-items-center justify-content-center gap-2 fw-bold"
                                    form="sortable"
                                    variant="warning"
                                    type="button"
                                    onClick={e => handleSubmitSortable(e)}
                                    disabled={loading}>
                                    Acortar
                                    {loading && (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Button>
                            </Col>
                        </Tab>
                        <Tab
                            eventKey="linkAll"
                            title="Todos"
                            className="p-2"
                            style={{
                                border: '1px solid #dee2e6',
                                borderTop: 0
                            }}>
                            <Row>
                                <Col>
                                    <CustomTextField
                                        value={linksAllTotext}
                                        type="textarea"
                                        className="mb-2"
                                    />
                                    <ReactSortable
                                        // chosenClass="alert"
                                        // group="alert"
                                        animation={200}
                                        // delayOnTouchStart={true}
                                        // delay={2}
                                        list={state}
                                        setList={setState}>
                                        {state.map(item => (
                                            <>
                                                <Row
                                                    className="d-flex gap-1 align-items-center justify-content-center"
                                                    key={item.id}>
                                                    <Col>
                                                        <Alert
                                                            // key={item.id}
                                                            variant="primary">
                                                            {item.name}
                                                        </Alert>
                                                    </Col>
                                                    <Col>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control
                                                                // key={item.id}
                                                                type="number"
                                                                placeholder="Numero de veces a acortar"
                                                                aria-label={
                                                                    item.name
                                                                }
                                                                name={item.key}
                                                                value={
                                                                    inputValues[
                                                                        item.key
                                                                    ]
                                                                }
                                                                onChange={e => {
                                                                    setInputValues(
                                                                        {
                                                                            ...inputValues,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                        }
                                                                    );
                                                                }}
                                                                aria-describedby="basic-addon1"
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </>
                                        ))}
                                    </ReactSortable>
                                </Col>
                            </Row>
                            <Col className="d-grid">
                                <Button
                                    className="d-flex align-items-center justify-content-center gap-2 fw-bold"
                                    form="sortable"
                                    variant="warning"
                                    type="button"
                                    onClick={e => handleSubmitSortableAll(e)}
                                    disabled={loading}>
                                    Acortar todos
                                    {loading && (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Button>
                            </Col>
                        </Tab>
                    </Tabs>

                    {/* </Row> */}
                </Modal.Body>
            </Form>
        </Modal>
    );
}

const Sortable = props => {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)}>
                Acortar enlace
            </Button>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                {...props}
            />
            <Toaster position="top-center" reverseOrder={false} />
        </>
    );
};

export default Sortable;
