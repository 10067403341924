import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {
    fetchUserWP,
    getpostWordpress,
    getWordpressSearch
} from '../../utils/apiwp';

const SearchPostWordpress = ({ setDataPost }) => {
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState();

    const handleSearch = async query => {
        setIsLoading(true);
        const results = await getWordpressSearch(query);
        setOptions(results);
        setIsLoading(false);
    };

    const getPost = async postId => {
        const result = await getpostWordpress(postId);
        const user = await fetchUserWP({ userId: result?.author });

        setDataPost({ ...result, user });
    };

    useEffect(() => {
        if (selected) {
            getPost(selected?.id);
        }
    }, [selected]);

    return (
        <AsyncTypeahead
            // defaultInputValue={textABuscar}\
            className="custom-dropdown"
            autoFocus
            clearButton
            placeholder="Buscar post"
            ignoreDiacritics={true}
            isLoading={isLoading}
            emptyLabel="No se encontraron resultados"
            labelKey={option => `${option?.title}`}
            id="search"
            onChange={selected => setSelected(selected[0])}
            onSearch={query => {
                handleSearch(query);
            }}
            options={options}
        />
    );
};

export default SearchPostWordpress;
