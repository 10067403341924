import * as Yup from 'yup';

// matches(
//     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
//     'Enter correct url!'
// )

// Schema for yup
export const validationSchema = Yup.object().shape({
    // Calidad: Yup.string().required('*Calidad es obligatoria'),
    // Resolucion: Yup.string().required('*Resolucion es obligatorio'),
    // Idioma: Yup.string().required('*Idioma es obligatorio'),
    // Formato: Yup.string().required('*Formato es obligatorio'),
    // Code: Yup.string().required('*Code es obligatorio'),
    // Extra: Yup.string().required('*Extra es obligatorio'),
    PesoArchivo: Yup.string().required('*Peso del archivo es obligatorio'),
    // FormatoAudio2: Yup.string().required('*Formato de audio es obligatorio'),
    Subtitulos: Yup.array()
        .min(1, '*Subtitulos es obligatorio')
        .required('*Subtitulos es obligatorio')
});
export const validationSchemaToPoster = Yup.object().shape({
    Calidad: Yup.string().required('*Calidad es obligatoria'),
    Resolucion: Yup.string().required('*Resolucion es obligatorio'),
    Idioma: Yup.string().required('*Idioma es obligatorio'),
    Formato: Yup.string().required('*Formato es obligatorio'),
    Code: Yup.string().required('*Code es obligatorio'),
    Extra: Yup.string().required('*Extra es obligatorio')
});

export const validationSchema2 = Yup.object().shape({
    urlposter: Yup.string().url().required('*Poster es obligatorio'),
    urlpostergenerado: Yup.string().required('*Poster 2 es obligatorio'),
    captura1: Yup.string().required('*captura1 es obligatoria')
});
export const validationSchema2ToPoster = Yup.object().shape({
    urlposter: Yup.string().url().required('*Poster es obligatorio'),
    urlpostergenerado: Yup.string().required('*Poster 2 es obligatorio')
});

export const validationSchema3 = Yup.object().shape({
    misServidores: Yup.array()
        .min(1, '*Selecciona un servidor')
        .required('*Selecciona un servidor'),
    uploader: Yup.string().required('El campo es obligatorio'),
    enlaceFree: Yup.string()
        .url('Ingrese una url validad')
        .required('El campo es obligatorio')
});

export const validationSchemaConfigPaste = Yup.object().shape({
    nombreServidor: Yup.string().required(
        'El nombre del servidor es obligatorio'
    ),
    urlServidor: Yup.string()
        .url('La URL del servidor no es válida')
        .required('La URL del servidor es obligatoria'),
    urlImagenFree: Yup.string()
        .url('La URL de la imagen gratuita no es válida')
        .required('La URL de la imagen gratuita es obligatoria'),
    urlImagenVip: Yup.string()
        .url('La URL de la imagen VIP no es válida')
        .required('La URL de la imagen VIP es obligatoria')
});
