import React from 'react';
import { useDispatch } from '../../store/StoreProvider';
import { types } from '../../store/storeReducer';

const GroupImages = ({ movie }) => {
    const dispatch = useDispatch();

    const handleChangePosterRespaldo = posterUrl => {
        dispatch({
            type: types.MOVIE,
            payload: {
                ...movie,
                poster_path: posterUrl,
                poster_path_respaldo: posterUrl
            }
        });
    };
    return (
        <div className="row row-cols-1 row-cols-md-3 g-4">
            {movie?.images?.posters.map(image => (
                <div
                    className="col pe-auto"
                    onClick={() =>
                        handleChangePosterRespaldo(
                            `https://www.themoviedb.org/t/p/original${image?.file_path}`
                        )
                    }>
                    <a href="#!" className="pe-auto">
                        <div className="card">
                            <img
                                src={`https://www.themoviedb.org/t/p/w342${image?.file_path}`}
                                className="card-img-top"
                                alt="..."
                            />
                        </div>
                    </a>
                </div>
            ))}
        </div>
    );
};;

export default GroupImages;
