import React, { useEffect, useState } from 'react';
import { useDispatch, useStore } from '../../store/StoreProvider';
import { types } from '../../store/storeReducer';
import './hamburguesa.css';

const Hamburguesa = () => {
    const { buttonHamburguesa } = useStore();
    const dispatch = useDispatch();

    const handleCollapse = () => {
        dispatch({
            type: types.BUTTONHAMBURGUESA,
            payload: !buttonHamburguesa
        });
    };

    return (
        <label htmlFor="check" className="mi_label">
            <input
                type="checkbox"
                id="check"
                className="mi_input"
                onClick={() => handleCollapse()}
            />
            <span className="mi_span" />
            <span className="mi_span" />
            <span className="mi_span" />
        </label>
    );
};

export default Hamburguesa;
