import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CardNota from './CardNota';
import images from '../../../assets/images';
import useNotes from '../hooks/useNotes';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 295,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9'
    }
}));

export default function TriggersTooltips() {
    const [open, setOpen] = React.useState(false);
    const { notas, handleGetNotes } = useNotes();

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        handleGetNotes();
        setOpen(true);
    };

    return (
        <div>
            <Grid container justifyContent="center">
                <Grid item>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div>
                            <HtmlTooltip
                                PopperProps={{
                                    disablePortal: true
                                }}
                                onClose={handleTooltipClose}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 10,
                                            maxHeight: 800,
                                            // height: 800,
                                            overflow: 'auto'
                                        }}>
                                        {notas?.length > 0 ? (
                                            notas?.map((note, index) => (
                                                <div>
                                                    <CardNota
                                                        text={note.not_text}
                                                        id={note.id}
                                                        key={index}
                                                        isEdit={false}
                                                    />
                                                </div>
                                            ))
                                        ) : (
                                            <>
                                                <div>
                                                    <CardNota
                                                        text={'No hay notas'}
                                                        id={0}
                                                        isEdit={false}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                }>
                                <Button
                                    sx={{
                                        color: '#FFC206'
                                    }}
                                    onClick={handleTooltipOpen}>
                                    <div>
                                        <img
                                            src={images.note}
                                            alt=""
                                            width="25"
                                            height="25"
                                        />
                                    </div>
                                    {/* <NotesIcon /> */}
                                </Button>
                            </HtmlTooltip>
                            {/* <Tooltip
                                PopperProps={{
                                    disablePortal: true
                                }}
                                onClose={handleTooltipClose}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Add">
                                <Button onClick={handleTooltipOpen}>
                                    Click
                                </Button>
                            </Tooltip> */}
                        </div>
                    </ClickAwayListener>
                </Grid>
            </Grid>
        </div>
    );
}
