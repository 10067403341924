import React from 'react';
import FicharSearch from './FicharSearch';
import { Box } from '@mui/material';
import GridContainer from './components/GridContainer';
import FicharForm from './components/FicharForm';
import { useSearchFicharPost } from './store/store';

const FicharPost = () => {
    const { details } = useSearchFicharPost();
    return (
        <Box p={1}>
            <FicharSearch />
            {Object.keys(details).length > 0 ? (
                <FicharForm />
            ) : (
                <GridContainer />
            )}
            {/* <GridContainer /> */}
        </Box>
    );
};

export default FicharPost;
