import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FaRegImage, FaUpload } from 'react-icons/fa';
import { useDispatch } from '../../store/StoreProvider';
import { types } from '../../store/storeReducer';
import { crearPlantillaPoster, deleteImagen, getImages } from '../../utils/api';
import { subirPoster } from '../../utils/subirimg';
import Button from '../button/Button';
import FileUploaderPlantilla from '../upload/FileUploaderPlantilla';
import FileUploaderUrlPlantilla from '../upload/FileUploaderUrlPlantilla';
import { getLocalStorage } from '../../utils/functions';

const Groupplantillas = ({ movie, handleChangePoster }) => {
    const userData = getLocalStorage('user');
    const dispatch = useDispatch();
    const [images, setImages] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [isLoadingUrl, setisLoadingUrl] = useState({
        loading: false,
        id: 0
    });
    const [loadingPlantilla, setloadingPlantilla] = useState(false);
    const [loadingPlantilla2, setloadingPlantilla2] = useState(false);
    const [loadingPlantilla3, setloadingPlantilla3] = useState(false);

    const { poster_path_respaldo } = movie || {};

    /**
     *
     * @returns TRAERME TODAS LAS PLANTILLAS PARA MOSTRAR EN EL MODAL
     */
    const handledeGetImages = async () => {
        setisLoading(true);
        const { data, status } = await getImages();

        if (status !== 200) {
            setisLoading(false);
            return <div>Error</div>;
        }

        setImages(data);
        setisLoading(false);
    };

    /**
     *
     * @returns ELIMINAR UNA PLANTILA QUE ESTE EN EL MODAL
     */

    const handleDeleteImage = async id => {
        const { data, status } = await deleteImagen(id);

        if (status !== 200) {
            return <div>Error</div>;
        }

        handledeGetImages();
    };

    /**
     *
     * @returns CREAR UN POSTER CON UNA PLANTILLA
     */

    const handlePoster = async (plantilla, poster, id) => {
        setisLoadingUrl({ loading: true, id: id });
        const { data, status } = await crearPlantillaPoster({
            plantilla,
            poster
        });

        if (status !== 200) {
            setisLoadingUrl({ loading: false, id: id });
            return <div>Error</div>;
        }

        handleChangePoster(data?.poster, 'urlpostergenerado');

        setisLoadingUrl({ loading: false, id: id });
    };

    useEffect(() => {
        handledeGetImages();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="d-flex justify-content-center mb-5 gap-3">
                {userData.roles === 'admin' ? (
                    <>
                        <FileUploaderPlantilla
                            handledeGetImages={handledeGetImages}
                            setLoading={setloadingPlantilla}
                            loading={loadingPlantilla}>
                            <div className="d-flex gap-2 align-items-center">
                                {loadingPlantilla ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    // <FaUpload />
                                    <img
                                        src="http://www.google.com/s2/favicons?domain=www.imgbb.com"
                                        alt="subir imagen"
                                        title="Imgbb"
                                    />
                                )}
                                Imgbb
                            </div>
                        </FileUploaderPlantilla>
                        <FileUploaderPlantilla
                            handledeGetImages={handledeGetImages}
                            setLoading={setloadingPlantilla2}
                            loading={loadingPlantilla2}
                            imgur>
                            <div className="d-flex gap-2 align-items-center">
                                {loadingPlantilla2 ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    // <FaUpload />
                                    <img
                                        src="http://www.google.com/s2/favicons?domain=www.imgur.com"
                                        alt="Subir imagen"
                                        title="Imgur"
                                    />
                                )}
                                Imgur
                            </div>
                        </FileUploaderPlantilla>
                        <FileUploaderPlantilla
                            handledeGetImages={handledeGetImages}
                            setLoading={setloadingPlantilla3}
                            loading={loadingPlantilla3}
                            pixhost>
                            <div className="d-flex gap-2 align-items-center">
                                {loadingPlantilla3 ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    // <FaUpload />
                                    <img
                                        src="http://www.google.com/s2/favicons?domain=www.pixhost.to"
                                        alt="subir imagen"
                                        title="Pixhost"
                                    />
                                )}
                                Pixhost
                            </div>
                        </FileUploaderPlantilla>
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="row row-cols-1 row-cols-md-3 g-4">
                {images.map((image, i) => (
                    <div className="col" key={i}>
                        <div className="card">
                            <img
                                src={image.url}
                                className="card-img-top"
                                alt={image.name}
                            />
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    {/* <button
                                        type="button"
                                        class="btn btn-primary btn-sm">
                                        Generar
                                    </button> */}
                                    <div className="d-flex gap-2">
                                        <Button
                                            props={{
                                                size: 'sm',
                                                variant: 'flat',
                                                type: 'submit',
                                                disabled:
                                                    isLoadingUrl.loading && true
                                            }}
                                            onClick={() =>
                                                handlePoster(
                                                    image.url,
                                                    poster_path_respaldo,
                                                    i
                                                )
                                            }>
                                            <div className="d-flex gap-2 align-items-center">
                                                {isLoadingUrl.loading &&
                                                isLoadingUrl.id === i ? (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                ) : (
                                                    <FaRegImage />
                                                )}
                                                Generar
                                            </div>
                                        </Button>
                                    </div>
                                    {userData.roles === 'admin' ? (
                                        <>
                                            <button
                                                onClick={() =>
                                                    handleDeleteImage(image.id)
                                                }
                                                type="button"
                                                className="btn btn-outline-dark btn-sm">
                                                Eliminar
                                            </button>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Groupplantillas;
