import { Store } from 'pullstate';

const NotasStore = new Store({
    notas: [],
    notaEdit: {}
});

const setNotas = notas => {
    NotasStore.update(s => {
        s.notas = notas;
    });
};
const setNotaEdit = nota => {
    NotasStore.update(s => {
        s.notaEdit = nota;
    });
};
const setLoadingServer = loading => {
    NotasStore.update(s => {
        s.loadingNotasStore = loading;
    });
};

function useNotasStore() {
    const notas = NotasStore.useState(s => s.notas);
    const notaEdit = NotasStore.useState(s => s.notaEdit);

    return { notas, notaEdit, setNotas, setNotaEdit };
}

export { useNotasStore };
