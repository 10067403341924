import React from 'react';
import { FaSignInAlt, FaUser, FaWordpress } from 'react-icons/fa';
import Hamburguesa from '../button/Hamburguesa';
import images from '../../assets/images';
import './headers.css';
import { getLocalStorage } from '../../utils/functions';
import TriggersTooltips from '../Notas/components/TriggersTooltips';
import AppNotify from '../Notification';

const Header = () => {
    const userData = getLocalStorage('user');

    const { username, id_wordpress } = userData;

    const handleLogout = () => {
        localStorage.clear();
        window.location.href = '/';
    };
    return (
        <header className="p-3 bg-vino text-white">
            <div className="container">
                <div className="d-flex flex-wrap align-items-center justify-content-between ">
                    <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                        <Hamburguesa />
                        <a
                            href="/"
                            className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none mx-4">
                            <img src={images.logo} alt="" className="logo" />
                        </a>
                    </div>

                    <div>
                        <img
                            src={images.banner}
                            alt=""
                            width="100"
                            height="60"
                        />
                    </div>
                    <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                        <div className="d-flex flex-wrap align-items-center justify-content-center gap-2 mx-3">
                            <div className="d-flex flex-wrap align-items-center justify-content-center gap-1 fw-bold">
                                <div>
                                    <TriggersTooltips />
                                </div>
                                <div>
                                    <AppNotify />
                                </div>
                                <div>
                                    <img
                                        src={images.usuario}
                                        alt=""
                                        width="25"
                                        height="25"
                                    />
                                </div>
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        width: '69px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        color: '#ffc206'
                                    }}>
                                    {username}
                                </span>
                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-center gap-1 fw-bold">
                                <div>
                                    <img
                                        src={images.worldpress}
                                        alt=""
                                        width="25"
                                        height="25"
                                    />
                                </div>
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        width: '8px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        color: '#ffc206'
                                    }}>
                                    {id_wordpress}
                                </span>
                            </div>
                        </div>
                        <div className="text-end">
                            {/* <button
                            type="button"
                            className="btn btn-outline-light me-2">
                            Login
                        </button> */}
                            <button
                                type="button"
                                className="btn btn-warning d-flex align-items-center justify-content-center gap-1"
                                onClick={() => handleLogout()}>
                                Salir
                                <FaSignInAlt />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
