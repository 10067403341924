import axios from 'axios';
import { getLocalStorage } from './functions';
const API_REST = 'https://vip.hdlatino.us/pastes/api';
// const userData = JSON.parse(localStorage.getItem('user'));
const userData = getLocalStorage('user');
const API_KEY =
    'bc1pssax9hs02227xmkcxterglg6lljnvvlj8ndvwpqxldsd6g8re6qqvxd899bc1qve9gwcmlsv8srgkx9zd587ta98jkwcvqjsvurnhz7f3xydcr67yrdrmhn97lgl4hemkkz0x3dw05skvshfk8kfv5yqjszzt7';
const API_KEY_USER =
    'dqJ7FrnzhXJxRXdn5dbXmqpwKKyPFCr3nmyAXkh5Eh9UPcw0FQZCBDw846U4zEHUvWkupgCjFJ52mvbVm8KmjfY8bVWrScGDcMBCiSDf4w74R9iCXFSbZbG15YZ4jZ4RSnt4r8wCFJVWgbtfh2W5veUXxqh2c9k3jDHL6av96Fpe1PHCEN2ztkpc1GnjmS8d3HVmJnyMPku0wi9bVRtgAHZjdeXTpCK4uQPFPvhGkeANX3DWuLjrmRwd0qydgaJxWCtq8hR5iEkiWr0Tu85dthij1wEnaqBhKmS8ZUBLed8Q';
const headers = {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
};

// https://vip.hdlatino.us/pastes/api/pastes/?user=20&api_key=
// https://vip.hdlatino.us/pastes/api/users/login?api_key=Nx1tURMzHceBEso06X5Eo9nD4OwmyaBYZvYzHmZNa93xFXcAPyufrwyNxXCFC4San911qCwE0JmZPC9qu5JaDwbxcGXP51O3Qr9d7hRQgWs3VA1NLOq7bSCmmZS60nuC

// login
export const loginPaste = async (username, password) => {
    let formdata = new FormData();
    formdata.append('username', username);
    formdata.append('password', password);

    let bodyContent = formdata;

    try {
        const { data } = await axios({
            url: `${API_REST}/users/login?key=${API_KEY_USER}`,
            method: 'POST',
            headers: headers,
            data: bodyContent
        });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

// crear post
export const crearPaste = async values => {
    const obj = { ...values, user_id: userData?.id_paste };
    try {
        const { data } = await axios({
            url: `${API_REST}/pastes/?user=${userData?.id_paste}&api_key=${API_KEY}`,
            method: 'POST',
            headers: headers,
            data: obj
        });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

// obtener pastes
export const getPastes = async () => {
    try {
        const { data } = await axios({
            url: `${API_REST}/pastes/?user=${userData?.id_paste}&api_key=${API_KEY}`,
            method: 'GET',
            headers: headers
        });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};
// obtener usuarios
export const getUsersPaste = async () => {
    try {
        const { data } = await axios({
            url: `${API_REST}/users/?api_key=${API_KEY}`,
            method: 'GET',
            headers: headers
        });
        return data;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
// actualizar usuario del paste
export const putUpdateUserPaste = async (userId, userData) => {
    try {
        const { data } = await axios({
            url: `${API_REST}/users/${userId}?api_key=${API_KEY}`,
            method: 'PUT',
            headers: headers,
            data: userData
        });
        return data;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
// aplicar voucher a usuario
export const postApplicarVoucher = async userData => {
    try {
        const { data } = await axios({
            url: `${API_REST}/vouchers/vouchersApply?api_key=${API_KEY}`,
            method: 'POST',
            headers: headers,
            data: userData
        });
        return data;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
// obtener paste individual
export const getPaste = async pasteId => {
    try {
        const { data } = await axios({
            url: `${API_REST}/pastes/${pasteId}?user=${userData?.id_paste}&api_key=${API_KEY}`,
            method: 'GET',
            headers: headers
        });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

// delete pastes
export const deletePastes = async pasteId => {
    try {
        const { data } = await axios({
            url: `${API_REST}/pastes/${pasteId}?user=${userData?.id_paste}&api_key=${API_KEY}`,
            method: 'DELETE',
            headers: headers
        });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

// update pastes
export const updatePastes = async (obj, pasteId) => {
    try {
        const { data } = await axios({
            url: `${API_REST}/pastes/${pasteId}?user=${userData?.id_paste}&api_key=${API_KEY}`,
            method: 'PUT',
            headers: headers,
            data: { ...obj, user_id: userData?.id_paste }
        });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};
