import React, { useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useDispatch, useStore } from '../../store/StoreProvider';
import { types } from '../../store/storeReducer';
import './step-header.css';

const StepsHeader = () => {
    const { view } = useStore();
    const dispatch = useDispatch();
    const [steps, setSteps] = useState(1);

    const handleViewStatic = value => {
        dispatch({ type: types.VIEW, payload: value });
    };
    if (view > steps) {
        setSteps(view);
    }

    return (
        <ListGroup horizontal>
            <ListGroup.Item
                disabled={steps < 1 ? true : false}
                onClick={() => handleViewStatic(1)}
                className={`rounded-3 border border-secondary m-2 cursor_pointer ${
                    view === 1 ? 'bg_color fw-bold' : 'bg_color_selected'
                } ${steps > 1 && view !== 1 && 'bg_color_selected'}`}>
                <b>1.</b>
                <small>Información Multimedia</small>
            </ListGroup.Item>
            <ListGroup.Item
                disabled={steps < 2 ? true : false}
                onClick={() => handleViewStatic(2)}
                className={`rounded-3 border border-secondary m-2 cursor_pointer ${
                    view === 2 ? 'bg_color fw-bold' : 'bg_color_selected'
                } ${steps > 2 && view !== 2 && 'bg_color_selected'}`}>
                <b>2.</b>
                <small>Info - Imagnes</small>
            </ListGroup.Item>
            <ListGroup.Item
                disabled={steps < 3 ? true : false}
                onClick={() => handleViewStatic(3)}
                className={`rounded-3 border border-secondary m-2 cursor_pointer ${
                    view === 3 ? 'bg_color fw-bold' : 'bg_color_selected'
                } ${steps >= 3 && view !== 3 && 'bg_color_selected'}`}>
                <b>3.</b>
                <small>Info - Descargas</small>
            </ListGroup.Item>
            <ListGroup.Item
                disabled={steps < 4 ? true : false}
                onClick={() => handleViewStatic(4)}
                className={`rounded-3 border border-secondary m-2 cursor_pointer ${
                    view === 4 ? 'bg_color fw-bold' : 'bg_color_selected'
                } ${steps >= 4 && view !== 4 && 'bg_color_selected'}`}>
                <b>4.</b>
                <small>info Post</small>
            </ListGroup.Item>
        </ListGroup>
    );
};

export default StepsHeader;
