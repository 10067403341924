import { useState } from 'react';
import styled from 'styled-components';
import { subirPosterImgur } from '../../utils/subirimg';

const arrayCapturas = [
    'captura1',
    'captura2',
    'captura3',
    'captura4',
    'captura5'
];

function DragArea({
    // name,
    subirPoster,
    handleChangePoster,
    setLoading,
    values
}) {
    const [ImageSelectedPrevious, setImageSelectedPrevious] = useState(null);
    const changeImage = e => {
        if (e.target.files[0] !== undefined) {
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = e => {
                e.preventDefault();
                setImageSelectedPrevious(e.target.result); // le damos el binario de la imagen para mostrarla en pantalla
            };

            for (const name of arrayCapturas) {
                if (!values[name]) {
                    (async () => {
                        let loading = {};
                        let dataImg;
                        loading[name] = true;
                        try {
                            setLoading(loading);
                            const fileUploaded = e.target.files[0];
                            const result = await subirPoster(fileUploaded);

                            if (result === 'Error') {
                                const result2 = await subirPosterImgur(
                                    fileUploaded
                                );

                                if (!result2) {
                                    loading[name] = false;
                                    setLoading(loading);
                                    alert(
                                        'Ocurrio un error al subir la imagen en los dos servidores'
                                    );
                                    return;
                                }
                                dataImg = result2?.data;
                            } else {
                                dataImg = result?.data;
                            }

                            handleChangePoster(dataImg?.url, name);
                            loading[name] = false;
                            setLoading(loading);
                        } catch (error) {
                            loading[name] = false;
                            setLoading(loading);
                        }
                    })();
                    break;
                }
            }
        }
    };
    return (
        <div>
            <StyleDragArea>
                <br />
                <div className="image-upload-wrap">
                    <input
                        className="file-upload-input"
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={e => {
                            changeImage(e);
                        }}
                    />
                    <div className="text-information">
                        <h3>Arrastrar y soltar una imagen</h3>
                    </div>
                </div>

                {/* <div className="center">
                    <img
                        src={ImageSelectedPrevious}
                        alt=""
                        height="150px"
                        width="250px"
                    />
                </div> */}
            </StyleDragArea>
        </div>
    );
}

export default DragArea;

const StyleDragArea = styled.div`
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .file-upload-content {
        display: none;
        text-align: center;
    }

    .file-upload-input {
        position: absolute;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        outline: none;
        opacity: 0;
        cursor: pointer;
    }

    .image-upload-wrap {
        position: relative;
        height: 100px;
        border: 4px solid #d0d7de;
        margin-left: 10px;
        margin-right: 10px;
    }

    .image-upload-wrap:hover {
        background-color: transparent;
        border: 4px dashed #d0d7de;
    }
    .text-information {
        margin-top: 30px;
        text-align: center;
    }
`;
