import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from './components/CustomTextField';
import { Box, Stack } from '@mui/material';
import movieDB from '../../utils/class/tmdb';
import { useSearchFicharPost } from './store/store';
import RowRadioButtonsGroup from './components/RowRadioButtonsGroup';

const func = {
    movie: movieDB.searchMovie.bind(movieDB),
    tv: movieDB.searchTVSeries.bind(movieDB),
    collection: movieDB.searchCollection.bind(movieDB)
};

const FicharSearch = () => {
    const { setSearch, setLoadingSearch, type } = useSearchFicharPost();
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (searchTerm.length > 0) {
                setLoadingSearch(true);
                const response = await func[type](searchTerm).catch(error => {
                    console.error('Error searching:', error);
                    setLoadingSearch(false);
                });
                setSearch(response);
                setLoadingSearch(false);
            } else {
                setSearch([]);
                setLoadingSearch(false);
            }
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, type]);

    const handleSearchChange = event => {
        setSearchTerm(event.target.value);
    };
    return (
        <Stack
            direction="row"
            alignItems={'center'}
            justifyContent={'center'}
            gap={2}
            mt={1}
            mb={1}>
            <div>
                <CustomTextField
                    placeholder="Search..."
                    InputProps={{
                        startAdornment: <SearchIcon />
                    }}
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            <div>
                <RowRadioButtonsGroup />
            </div>
        </Stack>
    );
};

export default FicharSearch;
