import axios from 'axios';
import {
    REACT_APP_APIKEY_THEMOVIES,
    REACT_APP_DOMINIO_APITHEMOVIES
} from '../env';

const API = REACT_APP_DOMINIO_APITHEMOVIES;
const APIKEY = REACT_APP_APIKEY_THEMOVIES;
const TYPE_SEARCH = {
    1: 'movie',
    0: 'tv',
    2: 'collection'
};

// https://api.themoviedb.org/3/movie/718930?api_key=be58b29465062a3b093bc17dacef8bf3&language=es-ES&append_to_response=images&include_image_language=en,null

export const getMovieImdb = async imdb => {
    try {
        const { data } = await axios.get(
            `http://api.themoviedb.org/3/find/${imdb}?api_key=${APIKEY}&external_source=imdb_id`
        );

        if (data?.movie_results.length > 0) {
            return data.movie_results;
        }
        return data?.tv_results;
    } catch (error) {}
};
export const getMovies = async datosbusqueda => {
    // https://api.themoviedb.org/3/movie/78?api_key=###&append_to_response=keywords,alternative_titles,changes,credits,images,keywords,lists,releases,reviews,similar,translations,videos
    // http://api.themoviedb.org/3/find/tt0108977?api_key=###&external_source=imdb_id
    // http://api.themoviedb.org/3/tv/1418/season/1/episode/1?api_key=###&append_to_response=credits
    const { tmdb, movie, typeSearch, idioma } = datosbusqueda;
    let url;
    const type = TYPE_SEARCH[typeSearch] || 'movie';
    if (tmdb) {
        url = `${API}${type}/${tmdb}?api_key=${APIKEY}&language=${idioma}&append_to_response=credits,videos,external_ids,images&include_image_language=en,null`;
    } else {
        url = `${API}search/${type}?api_key=${APIKEY}&language=${idioma}&query=${movie}`;
    }

    let resultEpisode;

    try {
        const { data } = await axios.get(url);

        if (typeSearch === '0' && tmdb) {
            const result = await axios.get(
                `http://api.themoviedb.org/3/tv/${tmdb}/season/1/episode/1?api_key=${APIKEY}&append_to_response=credits`
            );
            resultEpisode = result.data;
            console.log('MI API', { ...data, credits: { ...resultEpisode } });
            return { ...data, credits: { ...resultEpisode } };
        }

        return data;
    } catch (error) {
        return error;
    }
};

export const getDirectors = async datosbusqueda => {
    const { tmdb, typeSearch } = datosbusqueda;
    let url;
    const type = typeSearch === '1' ? 'movie' : 'tv';

    if (tmdb) {
        url = `${API}${type}/${tmdb}?api_key=${APIKEY}&append_to_response=credits`;
    }

    try {
        const { data } = await axios.get(url);
        return data;
    } catch (error) {
        return error;
    }
};

// https://api.themoviedb.org/3/movie/718930?api_key=be58b29465062a3b093bc17dacef8bf3&language=es-ES&append_to_response=images&include_image_language=en,null
export const getCapturas = async (imdb, typeSearch) => {
    const isMoviOrSerie = typeSearch === '1' ? 'movie' : 'tv';
    try {
        const result = await axios.get(
            `${API}${isMoviOrSerie}/${imdb}?api_key=${APIKEY}&language=es-ES&append_to_response=images&include_image_language=en,null`
        );
        return result;
    } catch (error) {
        return error.response;
    }
};
