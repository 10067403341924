import axios from 'axios';
const api = 'https://apipost1.hdlatino.us/api/v1/filmaffinity';

export const getSearchFilmaffinityOficial = async q => {
    try {
        const { data } = await axios.get(`${api}/search/${q}`);
        return data;
    } catch (error) {
        console.log('error', error);
    }
};

export const getMovieFilmaffinityOficial = async url => {
    try {
        const { data } = await axios.post(`${api}/`, {
            url
        });
        return data;
    } catch (error) {
        console.log('error', error);
    }
};
