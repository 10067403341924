import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import styles from '../../assets/css/step2.module.css';
import Button from '../../components/button/Button';
import { FaUpload, FaRegImage, FaSave } from 'react-icons/fa';
import { Formik, Form as MyForm } from 'formik';
import FileUploader from '../../components/upload/FileUploader';
import { subirPoster } from '../../utils/subirimg';
import { useDispatch, useStore } from '../../store/StoreProvider';
import { types } from '../../store/storeReducer';
import { validationSchema2 } from '../../config/validation-squema';
import { generarPoster, uploadUrlImagenImbb } from '../../utils/api';
import FileUploaderUrl from '../../components/upload/FileUploaderUrl';
import DragArea from '../../components/DragArea';
import { paintUrl } from '../../utils/functions';
import BasicModal from '../../components/MyModal/BasicModal';
import { styleModal, sxButton } from '../../constants';
import SubirPortadas from '../../components/subirPortadas';

/*------------------------------------------------------------------- */

const Step1 = () => {
    const { movie, view, dataAEnviar, search, metadataExtras } = useStore();
    const { poster_path, poster_path_respaldo } = movie;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState({
        urlposter: false,
        posterGenerador: false,
        subir: false,
        captura1: false,
        captura2: false,
        captura3: false,
        captura4: false,
        captura5: false
    });
    const [loadingSubir, setLoadingSubir] = useState(false);
    const [loadingSubirImgur, setLoadingSubirImgur] = useState(false);
    const [loadingSubirPixhost, setLoadingSubirPixhost] = useState(false);
    const [urlposter, setUrlPoster] = useState(poster_path);
    const [miscapturas, setMisCapturas] = useState({
        urlpostergenerado: '',
        captura1: '',
        captura2: '',
        captura3: '',
        captura4: '',
        captura5: ''
    });

    const handleChangePoster = (posterUrl, name) => {
        switch (name) {
            case 'urlposter':
                dispatch({
                    type: types.MOVIE,
                    payload: { ...movie, poster_path: posterUrl }
                });
                dispatch({
                    type: types.MOVIE,
                    payload: { ...movie, poster_path_respaldo: posterUrl }
                });
                setUrlPoster(posterUrl);
                break;
            case 'captura1':
                dispatch({
                    type: types.DATAAENVIAR,
                    payload: { ...dataAEnviar, captura1: posterUrl }
                });
                break;
            case 'urlpostergenerado':
                dispatch({
                    type: types.DATAAENVIAR,
                    payload: { ...dataAEnviar, urlpostergenerado: posterUrl }
                });
                dispatch({
                    type: types.MOVIE,
                    payload: { ...movie, poster_path: posterUrl }
                });
                break;
            case 'captura2':
                dispatch({
                    type: types.DATAAENVIAR,
                    payload: { ...dataAEnviar, captura2: posterUrl }
                });
                break;
            case 'captura3':
                dispatch({
                    type: types.DATAAENVIAR,
                    payload: { ...dataAEnviar, captura3: posterUrl }
                });
                break;
            case 'captura4':
                dispatch({
                    type: types.DATAAENVIAR,
                    payload: { ...dataAEnviar, captura4: posterUrl }
                });
                break;
            case 'captura5':
                dispatch({
                    type: types.DATAAENVIAR,
                    payload: { ...dataAEnviar, captura5: posterUrl }
                });
                break;

            default:
                break;
        }
    };
    const capturas = [
        {
            id: 'captura2',
            cap: 'URL Captura 2'
        },
        {
            id: 'captura3',
            cap: 'URL Captura 3'
        },
        {
            id: 'captura4',
            cap: 'URL Captura 4 (Opcional)'
        },
        {
            id: 'captura5',
            cap: 'URL Captura 5 (Opcional)'
        }
    ];

    const handleSubmitStep2 = values => {
        dispatch({
            type: types.DATAAENVIAR,
            payload: { ...dataAEnviar, values }
        });
        const sumar = view + 1;
        dispatch({ type: types.VIEW, payload: sumar });
    };

    const {
        urlpostergenerado,
        captura1,
        captura2,
        captura3,
        captura4,
        captura5,
        Calidad,
        Idioma,
        Resolucion,
        Extra,
        Plus
    } = dataAEnviar || {};

    const handlePoster = async () => {
        setLoading({ posterGenerador: true });
        const { poster } = await generarPoster({
            poster: poster_path_respaldo,
            calidad: paintUrl(Calidad, 'Calidad', metadataExtras), // obteneos la url del json
            idioma: paintUrl(Idioma, 'Idioma', metadataExtras),
            resolucion: paintUrl(Resolucion, 'Resolucion', metadataExtras),
            extra: paintUrl(Extra, 'Extra', metadataExtras) ?? 'ninguna',
            plus: paintUrl(Plus, 'Plus', metadataExtras) ?? 'ninguna'
        });
        // const { poster } = await generarPoster({
        //     poster: poster_path_respaldo,
        //     calidad: search.typeSearch === '0' ? 'SERIES' : Calidad,
        //     idioma: Idioma,
        //     resolucion: Resolucion,
        //     extra: Extra
        // });
        // setUrlPoster(poster);
        handleChangePoster(poster, 'urlpostergenerado');
        dispatch({
            type: types.MOVIE,
            payload: { ...movie, poster_path: poster }
        });
        setLoading({ posterGenerador: false });
    };

    return (
        <div className={`${styles.container__step2}`}>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    urlposter: poster_path_respaldo,
                    urlpostergenerado: urlpostergenerado,
                    captura1: captura1,
                    captura2: captura2,
                    captura3: captura3,
                    captura4: captura4,
                    captura5: captura5
                }}
                validationSchema={validationSchema2}
                onSubmit={values => handleSubmitStep2(values)}>
                {({
                    values,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    touched,
                    errors
                }) => (
                    <MyForm id={`CreateForm${view}`}>
                        <Row>
                            <Col md={2}>
                                <p>URL Poster (original)</p>
                            </Col>
                            <Col lg={8} md={8}>
                                <Form.Group
                                    className="mb-3"
                                    controlId="imgOriginal">
                                    <Form.Control
                                        type="text"
                                        placeholder={
                                            touched.urlposter &&
                                            errors.urlposter
                                                ? 'El campo es obligatorio'
                                                : 'URL Poster'
                                        }
                                        size="sm"
                                        name="urlposter"
                                        onChange={handleChange}
                                        onBlur={() =>
                                            values.urlposter &&
                                            handleChangePoster(
                                                values.urlposter,
                                                'urlposter'
                                            )
                                        }
                                        value={values.urlposter}
                                        className={
                                            touched.urlposter &&
                                            errors.urlposter &&
                                            'error'
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={2} md={2}>
                                <FileUploader
                                    name="urlposter"
                                    subirPoster={subirPoster}
                                    setLoading={setLoading}
                                    handleChangePoster={handleChangePoster}>
                                    <div className="d-flex gap-2 align-items-center">
                                        {loading.urlposter ? (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <FaUpload />
                                        )}
                                        Subir
                                    </div>
                                </FileUploader>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2}>
                                <p>URL Poster (Generado)</p>
                            </Col>
                            <Col lg={6} md={6}>
                                <Form.Group
                                    className="mb-3"
                                    controlId="imgPoster">
                                    <Form.Control
                                        type="text"
                                        placeholder={
                                            touched.urlpostergenerado &&
                                            errors.urlpostergenerado
                                                ? 'El campo es obligatorio'
                                                : 'Generar imagen'
                                        }
                                        size="sm"
                                        name="urlpostergenerado"
                                        onChange={handleChange}
                                        onBlur={() =>
                                            values.urlpostergenerado &&
                                            handleChangePoster(
                                                values.urlpostergenerado,
                                                'urlpostergenerado'
                                            )
                                        }
                                        className={
                                            touched.urlpostergenerado &&
                                            errors.urlpostergenerado &&
                                            'error'
                                        }
                                        value={values.urlpostergenerado}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4} md={4}>
                                <div className="d-flex gap-2">
                                    <Button
                                        props={{
                                            size: 'sm',
                                            variant: 'flat',
                                            type: 'button',
                                            disabled:
                                                loading.posterGenerador && true
                                        }}
                                        onClick={handlePoster}>
                                        <div className="d-flex gap-2 align-items-center">
                                            {loading.posterGenerador ? (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <FaRegImage />
                                            )}
                                            Generar
                                        </div>
                                    </Button>
                                    <FileUploaderUrl
                                        url={values.urlpostergenerado}
                                        name="urlpostergenerado"
                                        subirPoster={subirPoster}
                                        handleChangePoster={handleChangePoster}
                                        setLoading={setLoadingSubir}>
                                        <div className="d-flex gap-2 align-items-center">
                                            {loadingSubir ? (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                // <FaUpload />
                                                <img
                                                    src="http://www.google.com/s2/favicons?domain=www.imgbb.com"
                                                    alt="subir imagen"
                                                    title="Imgbb"
                                                />
                                            )}
                                        </div>
                                    </FileUploaderUrl>
                                    <FileUploaderUrl
                                        url={values.urlpostergenerado}
                                        name="urlpostergenerado"
                                        subirPoster={subirPoster}
                                        handleChangePoster={handleChangePoster}
                                        setLoading={setLoadingSubirImgur}
                                        imgur>
                                        <div className="d-flex gap-2 align-items-center">
                                            {loadingSubirImgur ? (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                // <FaUpload />
                                                <img
                                                    src="http://www.google.com/s2/favicons?domain=www.imgur.com"
                                                    alt="Subir imagen"
                                                    title="Imgur"
                                                />
                                            )}
                                        </div>
                                    </FileUploaderUrl>
                                    <FileUploaderUrl
                                        url={values.urlpostergenerado}
                                        name="urlpostergenerado"
                                        subirPoster={subirPoster}
                                        handleChangePoster={handleChangePoster}
                                        setLoading={setLoadingSubirPixhost}
                                        pixhost>
                                        <div className="d-flex gap-2 align-items-center">
                                            {loadingSubirPixhost ? (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                // <FaUpload />
                                                <img
                                                    src="http://www.google.com/s2/favicons?domain=www.pixhost.to"
                                                    alt="subir imagen"
                                                    title="Pixhost"
                                                />
                                            )}
                                        </div>
                                    </FileUploaderUrl>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2}>
                                <p>Capturas</p>
                            </Col>
                            <Col lg={8} md={8}>
                                <Form.Group
                                    className="mb-3"
                                    controlId="captura1">
                                    <Form.Control
                                        type="text"
                                        placeholder={
                                            touched.captura1 && errors.captura1
                                                ? 'El campo es obligatorio'
                                                : 'URL Captura 1'
                                        }
                                        className={
                                            touched.captura1 &&
                                            errors.captura1 &&
                                            'error'
                                        }
                                        size="sm"
                                        name="captura1"
                                        onChange={handleChange}
                                        onBlur={() =>
                                            handleChangePoster(
                                                values.captura1,
                                                'captura1'
                                            )
                                        }
                                        value={values.captura1}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={2} md={2}>
                                <FileUploader
                                    name="captura1"
                                    subirPoster={subirPoster}
                                    handleChangePoster={handleChangePoster}
                                    setLoading={setLoading}>
                                    <div className="d-flex gap-2 align-items-center">
                                        {loading.captura1 ? (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <FaUpload />
                                        )}
                                        Subir
                                    </div>
                                </FileUploader>
                            </Col>
                        </Row>

                        {capturas.map(cap => (
                            <Row key={cap.id}>
                                <Col md={2}></Col>
                                <Col lg={8} md={8}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId={cap.id}>
                                        <Form.Control
                                            type="text"
                                            placeholder={cap.cap}
                                            size="sm"
                                            name={cap.id}
                                            onChange={handleChange}
                                            onBlur={() =>
                                                handleChangePoster(
                                                    values[cap.id],
                                                    cap.id
                                                )
                                            }
                                            value={values[cap.id]}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={2} md={2}>
                                    <FileUploader
                                        name={cap.id}
                                        subirPoster={subirPoster}
                                        handleChangePoster={handleChangePoster}
                                        setLoading={setLoading}>
                                        <div className="d-flex gap-2 align-items-center">
                                            {loading[cap.id] ? (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <FaUpload />
                                            )}
                                            Subir
                                        </div>
                                    </FileUploader>
                                </Col>
                            </Row>
                        ))}

                        <Row>
                            <Col md={2}></Col>
                            <Col md={8}>
                                <BasicModal
                                    buttonTitle={'Subir Capturas'}
                                    sxButton={sxButton}
                                    styles={styleModal}>
                                    <SubirPortadas />
                                </BasicModal>
                            </Col>
                            <Col md={2}></Col>
                        </Row>
                        <DragArea
                            name="captura1"
                            subirPoster={subirPoster}
                            handleChangePoster={handleChangePoster}
                            setLoading={setLoading}
                            values={values}
                        />
                    </MyForm>
                )}
            </Formik>
        </div>
    );
};

export default Step1;
