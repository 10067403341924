import { Chip, Icon } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import CustomMaterialMenu from '../CustomMaterialMenu/CustomMaterialMenu';
import styled from 'styled-components';
import ButtonStyle from '../button/ButtonStyle';
import moment from 'moment';
import { deletePostWordpress } from '../../utils/apiwp';
import { DataTableConfig } from '../../utils/DataTableConfig';
import { deletePastes } from '../../utils/apipaste';
import images from '../../assets/images';
/**
 *
 * BUSCAR POR IMDB
 * https://api.themoviedb.org/3/find/tt14640242?api_key=be58b29465062a3b093bc17dacef8bf3&external_source=imdb_id
 */

const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
        cursor: pointer;
    }
`;

const ClearButton = styled(ButtonStyle)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Filtrar por Titulo"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);

const DataTablePastes = ({
    arrayPost: arraySinEditar,
    loading,
    multipasteCreado
}) => {
    const arrayPost = arraySinEditar.sort((a, b) => b.pasteID - a.pasteID);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [data, setData] = useState([]);

    const filteredItems = data?.filter(
        item =>
            item.Titulo &&
            item.Titulo.toLowerCase().includes(filterText.toLowerCase())
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const handleDeletePost = async (postId, handleShow) => {
        const dataBorrada = await deletePastes(postId);

        if (dataBorrada?.status === 200 || dataBorrada?.status === 'success') {
            const dataUpdate = data?.filter(post => post.pasteID !== postId);
            setData(dataUpdate);
            handleShow();
        }
    };

    const columns = DataTableConfig('multipaste', handleDeletePost);

    useEffect(() => {
        setData(arrayPost);
    }, [arrayPost, multipasteCreado]);

    return (
        <DataTable
            title={
                <div className="d-flex justify-content-start gap-2 align-items-center fw-bold">
                    <div>
                        <img
                            src={images.mis_pastes_todos}
                            alt=""
                            width="45"
                            height="45"
                        />
                    </div>
                    <div>MIS PASTES</div>
                </div>
            }
            columns={columns}
            data={filteredItems}
            progressPending={loading}
            // defaultSortFieldId={1}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            // selectableRows
            persistTableHead
            highlightOnHover
            pointerOnHover
        />
    );
};

export default DataTablePastes;
