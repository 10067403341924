import React from 'react';
import { Stack, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import BasicDateTimePicker from '../../../components/BasicDateTimePicker/BasicDateTimePicker';
import { postUpdateFecha } from '../../../utils/apiwp';

const EditarFechaPost = ({ postData }) => {
    const { id: postID } = postData ?? {};

    const [currentDate, setCurrentDate] = React.useState(dayjs());
    const [loading, setLoading] = React.useState(false);

    const handleDateChange = date => {
        setCurrentDate(() => date);
    };

    const handleSubmitFecha = async () => {
        setLoading(true);
        await postUpdateFecha(postID, currentDate.format());
        setLoading(false);
    };
    return (
        <Stack alignItems={'center'} mt={3}>
            <Stack gap={1}>
                <Typography variant="h4">
                    Editar Fecha de Publicación
                </Typography>
                <BasicDateTimePicker
                    handleDateChange={handleDateChange}
                    currentDate={currentDate}
                />
                <LoadingButton
                    disableElevation
                    size="small"
                    onClick={handleSubmitFecha}
                    endIcon={<SaveIcon />}
                    loading={loading}
                    variant="contained">
                    <span>Guardar</span>
                </LoadingButton>
            </Stack>
        </Stack>
    );
};

export default EditarFechaPost;
