import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNotasStore } from '../store/store';

export default function CardNota({ text, id, handleDelete, isEdit = true }) {
    const [showIcons, setShowIcons] = React.useState(false);
    const { setNotaEdit } = useNotasStore();
    return (
        <Card
            onMouseEnter={() => setShowIcons(true)}
            onMouseLeave={() => setShowIcons(false)}
            sx={{
                width: 250,
                minHeight: 50,
                height: 'auto',
                position: 'relative'
            }}>
            {showIcons && isEdit && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0
                    }}>
                    <IconButton size="small">
                        <DeleteIcon
                            onClick={() => handleDelete(id)}
                            sx={{
                                width: 15,
                                height: 15
                            }}
                        />
                    </IconButton>
                    <IconButton size="small">
                        <EditIcon
                            onClick={() => setNotaEdit({ id, text })}
                            sx={{
                                width: 15,
                                height: 15
                            }}
                        />
                    </IconButton>
                </div>
            )}
            <CardContent>
                <Typography
                    variant="body1"
                    sx={{ mb: 1.5, whiteSpace: 'pre-line' }}
                    color="text.secondary"
                    paragraph>
                    {text}
                </Typography>
            </CardContent>
        </Card>
    );
}
