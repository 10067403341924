import { useState } from 'react';
import { useDispatch, useStore } from '../../store/StoreProvider';
import { types } from '../../store/storeReducer';
import { useEffect } from 'react';
import {
    bytesToGB,
    obtenerEpisodeNumberString,
    obtenerStringSeasonNumber,
    obtenerTamanoAproximado
} from '../../utils/functions';

const useSeason = () => {
    const { movie, dataAEnviar } = useStore();
    const dispatch = useDispatch();
    const [seasons, setSeasons] = useState(movie?.seasons || []);
    const [sizeAll, setSizeAll] = useState(0);
    const [bitesAll, setBitesAll] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [totalSize, setTotalSize] = useState('');
    const [temporada, setTemporada] = useState('');
    const [capitulos, setCapitulos] = useState('');

    const handleChange = (index, newValue) => {
        // Crear una copia del estado actual de movie para no mutar el estado directamente
        const updatedMovie = { ...movie };

        // Actualizar el valor del input específico en el array de parts
        updatedMovie.seasons[index].title_episodios = newValue;

        // Actualizar el estado utilizando la función setMovie
        dispatch({
            type: types.MOVIE,
            payload: {
                ...updatedMovie
            }
        });
    };

    const handleChangeDataAEnviar = (newValue, newKey) => {
        newKey === 'temporada' && setTemporada(() => newValue);
        newKey === 'capitulo' && setCapitulos(() => newValue);
        newKey === 'PesoArchivo' && setTotalSize(() => newValue);
        dispatch({
            type: types.DATAAENVIAR,
            payload: { [newKey]: newValue }
        });
    };

    const handleChangePoster = newValue => {
        // Crear una copia del estado actual de movie para no mutar el estado directamente

        const poster_path = `https://www.themoviedb.org/t/p/original${newValue}`;
        const poster_path_respaldo = `https://www.themoviedb.org/t/p/original${newValue}`;

        // Actualizar el estado utilizando la función setMovie
        dispatch({
            type: types.MOVIE,
            payload: {
                ...movie,
                poster_path,
                poster_path_respaldo
            }
        });
    };

    // const handleCheckboxChange = index => {
    //     const updatedSeasons = [...seasons];

    //     // Si el checkbox está marcado, eliminar la temporada; de lo contrario, agregar una nueva temporada
    //     if (updatedSeasons[index]) {
    //         updatedSeasons.splice(index, 1);
    //     } else {
    //         const newSeason = {
    //             name: `Season ${index + 1}`,
    //             title_episodios: ''
    //         };
    //         updatedSeasons.push(newSeason);
    //     }
    //     dispatch({
    //         type: types.MOVIE,
    //         payload: {
    //             ...movie,
    //         }
    //     });
    //     setSeasons(updatedSeasons);
    // };
    const handleCheckboxChange = (index, serie) => {
        const updatedSeasonsInfo =
            movie?.seasons_info?.length > 0
                ? [...movie?.seasons_info]
                : [...movie?.seasons];

        const updatedSeasonsSerie = [...seasons];
        // Busca la serie correspondiente en updatedSeasonsInfo por nombre
        const existingSeasonIndex = updatedSeasonsInfo.findIndex(
            season => season.name === serie.name
        );

        // Actualiza el estado 'checked' de la serie en seasons
        const updatedSeasons = updatedSeasonsInfo.map((season, i) => {
            if (i === existingSeasonIndex) {
                return {
                    ...season,
                    checked: !season.checked
                };
            }
            return season;
        });

        // Filtra las series marcadas como 'checked' y actualiza seasons_info
        const updatedSeasonsInfoFiltered = updatedSeasons.filter(
            season => season.checked
        );

        console.log('existingSeasonIndex', existingSeasonIndex);
        console.log('serie', serie);

        // Si la serie no está en seasons_info, agrégala en la posición original
        if (existingSeasonIndex === -1) {
            updatedSeasonsInfoFiltered.splice(index, 0, serie);
        }

        updatedSeasonsSerie[index].checked =
            !updatedSeasonsSerie[index].checked;

        setSeasons(updatedSeasonsSerie);
        dispatch({
            type: types.MOVIE,
            payload: {
                ...movie,
                seasons_info: updatedSeasonsInfoFiltered
            }
        });
    };

    //capturar pesos de temporadas
    const handleChangeSize = (index, e) => {
        const files = e.target.files;
        let sizeBites = 0;
        for (let i = 0; i < files?.length; i++) {
            // const size = bytesToGB(files[i].size);
            sizeBites += files[i].size;
        }
        setTotalFiles(prev => prev + files?.length);
        setBitesAll(prev => prev + sizeBites);
        const size1 = bytesToGB(sizeBites);
        const size = `${size1} GB`;
        const sizeBytesToGB = bytesToGB(bitesAll + sizeBites, 0);
        setSizeAll(prev => sizeBytesToGB);
        const sizeAproximadoPorCapitulo = obtenerTamanoAproximado(
            sizeBytesToGB,
            totalFiles + files?.length
        );
        setTotalSize(
            prev =>
                `${sizeAproximadoPorCapitulo} (Total: ${bytesToGB(
                    bitesAll + sizeBites
                )} GB)`
        );

        // Crear una copia del estado actual de movie para no mutar el estado directamente
        const updatedMovie = { ...movie };

        // Actualizar el valor del input específico en el array de parts
        const newValue = `${updatedMovie.seasons[index].title_episodios} Peso: ${size}`;

        handleChange(index, newValue);
    };

    useEffect(() => {
        dispatch({
            type: types.MOVIE,
            payload: {
                ...movie,
                seasons_info: seasons
            }
        });
        setTemporada(obtenerStringSeasonNumber(seasons));
        setCapitulos(obtenerEpisodeNumberString(seasons));
    }, []);

    useEffect(() => {
        dispatch({
            type: types.DATAAENVIAR,
            payload: {
                ...dataAEnviar,
                PesoArchivo: totalSize
            }
        });
    }, [totalSize]);

    return {
        handleChange,
        handleChangePoster,
        handleCheckboxChange,
        handleChangeSize,
        handleChangeDataAEnviar,
        seasons,
        movie,
        sizeAll,
        totalSize,
        temporada,
        capitulos
    };
};

export default useSeason;
