import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Telegram from './Telegram';
import TelegramLibre from './TelegramLibre';

const LayoutMainTelegram = () => {
    return (
        <Tabs defaultActiveKey="principal" id="justify-tab-example" size="sm">
            <Tab
                eventKey="principal"
                title="Principal"
                className="p-3"
                style={{
                    border: '1px solid #dee2e6',
                    borderTop: 0
                }}>
                <Telegram />
            </Tab>
            <Tab
                eventKey="libre"
                title="Libre"
                className="p-3"
                style={{
                    border: '1px solid #dee2e6',
                    borderTop: 0
                }}>
                <TelegramLibre />
            </Tab>
        </Tabs>
    );
};

export default LayoutMainTelegram;
