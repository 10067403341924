import React from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/system';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';

// Estilo personalizado para el TextField
const CustomTextFieldd = styled(TextField)(({ theme }) => ({
    '& .MuiInputLabel-root': {
        color: '#8E7AB5', // Color del label
        fontSize: '15px', // Tamaño de fuente del label
        fontWeight: 'bold' // Peso de fuente del label
        // marginBottom: '5px', // Margen inferior del label
        // transform: 'none' // Restaurar la transformación a su valor predeterminado
    },
    '& .MuiInputBase-root': {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#f2f2f2', // Color de fondo personalizado
        '& input': {
            padding: '10px' // Espaciado interno personalizado
        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none' // Sin borde exterior
    },
    '& .MuiSvgIcon-root': {
        color: '#666' // Color del icono de búsqueda
    }
}));

const CustomTextField = ({ type, ...props }) => {
    if (type === 'textarea') {
        return <Textarea {...props} />;
    } else {
        return <CustomTextFieldd {...props} />;
    }
};

export default CustomTextField;

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75'
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025'
};

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
        theme.palette.mode === 'dark' ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
          theme.palette.mode === 'dark' ? blue[600] : blue[200]
      };
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);
