import React from 'react';
import MyButton from 'react-bootstrap/Button';
import './button.css';

const Button = ({ children, props, onClick }) => {
    return (
        <>
            <style type="text/css">
                {`
                    .btn-flat {
                    background-color: #260505;
                    color: white;
                    }
                    .btn-flat:hover {
                    color: #ffc107;
                    background-color: #260505;
                    }

                    .btn-xxl {
                    padding: 1rem 1.5rem;
                    font-size: 1.5rem;
                    }
                `}
            </style>
            <MyButton {...props} onClick={onClick}>
                {children}
            </MyButton>
        </>
    );
};

export default Button;
