import React from 'react';
import { Box, Checkbox, Stack } from '@mui/material';
import { Form, InputGroup } from 'react-bootstrap';
import useSeason from './useSeason';
import DragAndDrop from '../DragArea/DragAndDrop';

const LayoutSeasons = () => {
    const {
        handleChange,
        handleChangePoster,
        handleCheckboxChange,
        handleChangeSize,
        handleChangeDataAEnviar,
        seasons,
        movie,
        sizeAll,
        totalSize,
        temporada,
        capitulos
    } = useSeason();

    return (
        <Stack className="color_fondo" m={1} justifyContent={'center'}>
            {seasons?.map((serie, i) => (
                <Stack
                    width={'100%'}
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'start'}>
                    <Stack alignItems={'flex-start'}>
                        <Checkbox
                            // defaultChecked={serie.checked}
                            checked={serie?.checked}
                            onChange={() => handleCheckboxChange(i, serie)}
                        />
                    </Stack>
                    <Box width={'100%'}>
                        <InputGroup size="sm" key={i}>
                            <InputGroup.Text
                                id="basic-addon1"
                                style={
                                    movie.number_of_seasons ===
                                        serie?.season_number &&
                                    serie?.episode_count >
                                        movie?.last_episode_to_air
                                            ?.episode_number
                                        ? { backgroundColor: '#ff8e8e' }
                                        : null
                                }>
                                {serie?.name}
                            </InputGroup.Text>
                            <Form.Control
                                style={
                                    movie.number_of_seasons ===
                                        serie?.season_number &&
                                    serie?.episode_count >
                                        movie?.last_episode_to_air
                                            ?.episode_number
                                        ? { backgroundColor: '#ff8e8e' }
                                        : null
                                }
                                placeholder="Numero de episodios"
                                aria-label="Numero de episodios"
                                aria-describedby="basic-addon1"
                                // value={`${peli.title} ${peli?.release_date.slice(
                                //     0,
                                //     4
                                // )}`}
                                value={serie?.title_episodios}
                                onChange={e => handleChange(i, e.target.value)}
                            />
                        </InputGroup>
                    </Box>
                    <Box sx={StyleForDragAndDrop}>
                        <DragAndDrop
                            handleChange={e => handleChangeSize(i, e)}
                            title="Carpeta"
                            isFolder
                        />
                    </Box>
                    <Box ml={2}>
                        <Box
                            mb={1}
                            sx={{ cursor: 'pointer' }}
                            onClick={() =>
                                handleChangePoster(serie?.poster_path)
                            }>
                            <img
                                src={`https://www.themoviedb.org/t/p/w600_and_h900_bestv2${serie?.poster_path}`}
                                alt=""
                                style={{ width: '300px' }}
                            />
                        </Box>
                    </Box>
                </Stack>
            ))}
            {/* <DragAndDrop
                handleChange={handleChangeSize}
                title="Arrastra y suelta una carpeta"
                isFolder
            /> */}
            <Box width={'100%'} mt={2}>
                <InputGroup size="sm">
                    <InputGroup.Text id="basic-addon1">Peso</InputGroup.Text>
                    <Form.Control
                        placeholder="Peso"
                        aria-label="Peso"
                        aria-describedby="basic-addon2"
                        // value={`${peli.title} ${peli?.release_date.slice(
                        //     0,
                        //     4
                        // )}`}
                        value={totalSize}
                        onChange={e =>
                            handleChangeDataAEnviar(
                                e.target.value,
                                'PesoArchivo'
                            )
                        }
                    />
                </InputGroup>
            </Box>
            <Box width={'100%'} mt={2}>
                <InputGroup size="sm">
                    <InputGroup.Text id="basic-addon1">
                        Temporada
                    </InputGroup.Text>
                    <Form.Control
                        placeholder="Temporada"
                        aria-label="Temporada"
                        aria-describedby="basic-addon2"
                        // value={`${peli.title} ${peli?.release_date.slice(
                        //     0,
                        //     4
                        // )}`}
                        value={temporada}
                        onChange={e =>
                            handleChangeDataAEnviar(e.target.value, 'temporada')
                        }
                    />
                </InputGroup>
            </Box>
            <Box width={'100%'} mt={2}>
                <InputGroup size="sm">
                    <InputGroup.Text id="basic-addon1">
                        Capitulos
                    </InputGroup.Text>
                    <Form.Control
                        placeholder="Capitulos"
                        aria-label="Capitulos"
                        aria-describedby="basic-addon2"
                        // value={`${peli.title} ${peli?.release_date.slice(
                        //     0,
                        //     4
                        // )}`}
                        value={capitulos}
                        onChange={e =>
                            handleChangeDataAEnviar(e.target.value, 'capitulo')
                        }
                    />
                </InputGroup>
            </Box>
        </Stack>
    );
};

export default LayoutSeasons;

const StyleForDragAndDrop = {
    width: '50%',
    height: '10%'
};
