import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { getPostApartados } from '../service/services';
import ShowButtons from './ShowButtons';
import { useSearchFicharPost } from '../store/store';
import CuentaRegresiva from './CuentaRegresiva';
import FicharImg from './FicharImg';
import {
    getLocalStorage,
    obtenerLabelResolucion
} from '../../../utils/functions';
import FicharTooltip from './FicharTooltip';

const columns = [
    {
        name: 'Poster',
        selector: row => <FicharImg row={row} />,
        sortable: true,
        width: '150px'
    },
    {
        name: 'TMDB',
        selector: row => row.apar_tmdb,
        // sortable: true
        width: '100px'
    },
    {
        name: 'Titulo',
        selector: row => row.apar_title,
        sortable: true,
        wrap: true
    },
    {
        name: 'Calidad',
        selector: row => row.apar_calidad,
        sortable: true
    },
    {
        name: 'Resolución',
        selector: row => obtenerLabelResolucion(row.apar_resolucion),
        sortable: true
    },
    {
        name: 'Idioma',
        selector: row => row.apar_idioma,
        sortable: true
    },
    {
        name: 'Uploader',
        selector: row => row.apar_uploader,
        sortable: true
    },
    {
        name: 'Time',
        selector: row => <CuentaRegresiva row={row} />,
        sortable: true
    },
    {
        name: 'Mensaje',
        selector: row => <FicharTooltip mensaje={row.apar_mensaje} />,
        sortable: true
    },
    {
        cell: row => <ShowButtons row={row} />,
        wrap: true
    }
];

const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página:',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
};

const FicharDataTable = () => {
    const userData = getLocalStorage('user');
    const { posts, setPosts } = useSearchFicharPost();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const conditionalRowStyles = [
        {
            when: row => row.apar_uploader !== userData.alias,
            style: {
                cursor: 'default'
            }
        },
        {
            when: row => row.apar_completed,
            style: {
                borderRadius: '15px',
                // outline: '1px solid #FFFFFF',
                backgroundColor: '#2D9596',
                color: 'white'
                // '&:hover': {
                //     cursor: 'pointer'
                // }
            }
        }
    ];

    const fetchPosts = async page => {
        setLoading(true);

        const response = await getPostApartados();
        setPosts(response);
        setData(response);
        setTotalRows(response.length);
        setLoading(false);
    };

    useEffect(() => {
        fetchPosts(); // fetch page 1 of users
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchPosts();
        }, 8000);

        return () => clearInterval(intervalId);
    }, [posts]);

    return (
        <DataTable
            fixedHeader
            fixedHeaderScrollHeight="650px"
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationTop
            title="Posts Apartados"
            columns={columns}
            data={posts}
            customStyles={customStyles}
            conditionalRowStyles={conditionalRowStyles}
            highlightOnHover
            pointerOnHover
            // paginationRowsPerPageOptions={[100, 10, 20, 50]}
            // paginationDefaultPage={1}
        />
    );
};

export default FicharDataTable;

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#F0F3FF',
            borderBottomColor: '#FFFFFF',
            borderRadius: '15px',
            outline: '1px solid #FFFFFF'
        }
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '14px'
        }
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '15px',
            outline: '1px solid #FFFFFF'
        }
    },
    pagination: {
        style: {
            border: 'none'
        }
    }
};
