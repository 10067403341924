import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useSearchFicharPost } from '../store/store';

export default function RowRadioButtonsGroup() {
    const { type, setType } = useSearchFicharPost();
    return (
        <FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel
                    checked={type === 'movie'}
                    value="movie"
                    control={<Radio size="small" />}
                    label="Movie"
                    onChange={e => setType(e.target.value)}
                />
                <FormControlLabel
                    checked={type === 'tv'}
                    value="tv"
                    control={<Radio size="small" />}
                    label="TV Series"
                    onChange={e => setType(e.target.value)}
                />
                <FormControlLabel
                    checked={type === 'collection'}
                    value="collection"
                    control={<Radio size="small" />}
                    label="Colecciones"
                    onChange={e => setType(e.target.value)}
                />
            </RadioGroup>
        </FormControl>
    );
}
