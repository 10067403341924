import React from 'react';
import { Card } from 'react-bootstrap';

function esNumerica(valor) {
    return /^\d+$/.test(valor);
}

const CardMovie = ({ title, tmdb, img, year }) => {
    return (
        <li>
            <Card style={{ width: '100%' }}>
                <Card.Img variant="top" src={img} />
                <Card.Body>
                    <Card.Subtitle className="mb-2 text-muted">
                        {esNumerica(tmdb) ? (
                            tmdb
                        ) : (
                            <div style={{ width: '100%' }}>
                                <img src={tmdb} style={{ width: '20%' }} />
                            </div>
                        )}
                    </Card.Subtitle>
                    <Card.Title>{title}</Card.Title>
                    {/* <Card.Text>{title}</Card.Text> */}
                    <Card.Text>{year}</Card.Text>
                </Card.Body>
            </Card>
        </li>
    );
};

export default CardMovie;
