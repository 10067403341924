import React from 'react';
import {
    bytesToGB,
    obtenerTamanoAproximado,
    sumarPeso
} from '../../../utils/functions';

const useEditPostView = () => {
    const getToBytes = files => {
        let sizeBites = 0;
        for (let i = 0; i < files?.length; i++) {
            // const size = bytesToGB(files[i].size);
            sizeBites += files[i].size;
        }
        return sizeBites;
    };

    const convertToBytes = files => {
        const sizeBites = getToBytes(files);
        const size1 = bytesToGB(sizeBites);
        return size1;
    };

    //capturar pesos de temporadas
    const handleChangeSizePost = (e, setValues, values, handleChangeData) => {
        const files = e.target.files;
        const size1 = convertToBytes(files);
        const pesoSumado = sumarPeso(values.peso, size1);
        setValues(prev => ({ ...prev, peso: pesoSumado }));
        handleChangeData(pesoSumado, 4, setValues);
    };

    return { handleChangeSizePost };
};

export default useEditPostView;
