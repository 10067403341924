/* eslint-disable react/prop-types */
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getLocalStorage } from '../utils/functions';

const PrivateRoute = ({ permiso, children }) => {
    // const [isAuthenticated, setIsAuthenticated] = useLocalStorage(
    //     'isAuthenticated',
    //     false
    // );

    // const user = JSON.parse(localStorage.getItem('user'));
    const user = getLocalStorage('user');

    if (!user) {
        localStorage.clear();
    }

    // const isAuthenticated = JSON.parse(localStorage.getItem('isAuthenticated'));
    const isAuthenticated = getLocalStorage('isAuthenticated');

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }
    if (isAuthenticated && !permiso.includes(user?.roles)) {
        return <Navigate to="/" />;
    }

    return children;
};

export default PrivateRoute;
