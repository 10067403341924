import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';

const ImageWithDeleteButton = ({ imageUrl, onClick }) => {
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    return (
        <div
            style={{ position: 'relative', display: 'inline-block' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <img src={imageUrl} alt="Image" />

            {hovered && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        padding: '8px'
                    }}>
                    <IconButton aria-label="save" onClick={onClick}>
                        <DeleteIcon fontSize="inherit" />
                    </IconButton>
                </div>
            )}
        </div>
    );
};

export default ImageWithDeleteButton;
