import React from 'react';
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Box, Stack } from '@mui/material';
import { useDispatch, useStore } from '../../store/StoreProvider';
import { types } from '../../store/storeReducer';
import DragAndDrop from '../DragArea/DragAndDrop';
import useColecciones from './hooks/useColecciones';

const LayoutColecciones = () => {
    const {
        handleInputChange,
        handleChangeSize,
        setTotalSize,
        movie,
        sizeAll,
        totalSize
    } = useColecciones();

    return (
        <Stack className="color_fondo" m={1}>
            {movie?.parts?.map((peli, i) => (
                <Stack direction={'row'} alignItems={'center'}>
                    <Box sx={{ width: '100%' }}>
                        <InputGroup className="mb-2" size="sm" key={i}>
                            <InputGroup.Text id="basic-addon1">
                                {`Película ${i + 1}`}{' '}
                            </InputGroup.Text>
                            <Form.Control
                                placeholder="Nombre"
                                aria-label="Nombre"
                                aria-describedby="basic-addon1"
                                // value={`${peli.title} ${peli?.release_date.slice(
                                //     0,
                                //     4
                                // )}`}
                                value={peli.title_fecha}
                                onChange={e =>
                                    handleInputChange(i, e.target.value)
                                }
                            />
                        </InputGroup>
                    </Box>
                    <Box sx={StyleForDragAndDrop}>
                        <DragAndDrop
                            handleChange={e => handleChangeSize(i, e)}
                            title="Carpeta"
                            isFolder
                        />
                    </Box>
                </Stack>
            ))}

            <Box width={'100%'} mt={2}>
                <InputGroup size="sm">
                    <InputGroup.Text id="basic-addon1">Peso</InputGroup.Text>
                    <Form.Control
                        placeholder="Peso"
                        aria-label="Peso"
                        aria-describedby="basic-addon2"
                        // value={`${peli.title} ${peli?.release_date.slice(
                        //     0,
                        //     4
                        // )}`}
                        value={totalSize}
                        onChange={e => setTotalSize(() => e.target.value)}
                    />
                </InputGroup>
            </Box>
        </Stack>
    );
};

const StyleForDragAndDrop = {
    width: '50%',
    height: '10%'
};

export default LayoutColecciones;
