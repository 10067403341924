import React, { useState } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import { API_KEY_PASTE, API_REST_PASTE } from '../../../env';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';

const usePasteVoucher = () => {
    const [loadingDelete, setLoadingDelete] = useState({});
    const [loadingCrear, setLoadingCrear] = useState(false);
    const [repetir, setRepetir] = useState(0);
    const { data, loading, error } = useFetch(
        `${API_REST_PASTE}/vouchers/?api_key=${API_KEY_PASTE}`,
        { method: 'GET', repetir: repetir }
    );

    const columns = [
        {
            name: 'VOUCHER',
            selector: row => row.voucher,
            sortable: true,
            grow: 3,
            style: {
                // color: '#202124',
                fontSize: '14px',
                fontWeight: 500
            }
        },
        {
            name: 'DIAS',
            selector: row => row.days,
            sortable: true,
            grow: 1,
            style: {
                // color: '#202124',
                fontSize: '14px',
                fontWeight: 500
            }
        },
        {
            cell: row => (
                <LoadingButton
                    // loading={loadingDelete}
                    loading={loadingDelete[row.voucherID] || false}
                    variant="contained"
                    color="error"
                    onClick={() => hendleDeleteVoucher(row?.voucherID)}>
                    Borrar
                </LoadingButton>
            )
        }
    ];

    const handleCrearVoucher = async value => {
        try {
            setLoadingCrear(true);
            const response = await axios(
                `${API_REST_PASTE}/vouchers/?api_key=${API_KEY_PASTE}`,
                { method: 'POST', data: value }
            );
            setRepetir(pre => pre + 1);
            setLoadingCrear(false);
        } catch (error) {
            setLoadingCrear(false);
            console.log('error al crear el voucher', error);
        }
    };

    const hendleDeleteVoucher = async voucherID => {
        try {
            // setLoadingDelete(true);
            setLoadingDelete(prevState => ({
                ...prevState,
                [voucherID]: true
            }));
            const response = await axios(
                `${API_REST_PASTE}/vouchers/${voucherID}?api_key=${API_KEY_PASTE}`,
                { method: 'DELETE' }
            );
            setRepetir(pre => pre + 1);
            setLoadingDelete(prevState => ({
                ...prevState,
                [voucherID]: true
            }));
            // setLoadingDelete(false);
        } catch (error) {
            // setLoadingDelete(false);
            setLoadingDelete(prevState => ({
                ...prevState,
                [voucherID]: true
            }));
            console.log('Ocurrio un error al eliminar el voucher', error);
        }
    };
    return {
        data,
        loading,
        error,
        columns,
        loadingCrear,
        handleCrearVoucher,
        hendleDeleteVoucher
    };
};

export default usePasteVoucher;
