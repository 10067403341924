import React, { useEffect, useState } from 'react';
import { useDispatch, useStore } from '../../../store/StoreProvider';
import { types } from '../../../store/storeReducer';
import { subirPoster, subirPosterImgur } from '../../../utils/subirimg';
import { getCapturas } from '../../../utils/themvoies';

const useGetCapturas = () => {
    const { dataAEnviar, movie, search } = useStore();
    const dispatch = useDispatch();
    const [capturas, setCapturas] = useState([]);
    const [capturasSubidas, setCapturasSubidas] = useState({});
    const [selectedRespaldo, setSelectedRespaldo] = useState({});
    const [loadingCapturas, setLoadingCapturas] = useState(false);
    const [loadingUploadCapturas, setLoadingUploadCapturas] = useState(false);

    const objValues = Object.values(capturasSubidas);

    const handleChangePoster = (posterUrl, name) => {
        switch (name) {
            case 'captura1':
                dispatch({
                    type: types.DATAAENVIAR,
                    payload: { ...dataAEnviar, captura1: posterUrl }
                });
                break;
            case 'urlpostergenerado':
                dispatch({
                    type: types.DATAAENVIAR,
                    payload: { ...dataAEnviar, urlpostergenerado: posterUrl }
                });
                dispatch({
                    type: types.MOVIE,
                    payload: { ...movie, poster_path: posterUrl }
                });
                break;
            case 'captura2':
                dispatch({
                    type: types.DATAAENVIAR,
                    payload: { ...dataAEnviar, captura2: posterUrl }
                });
                break;
            case 'captura3':
                dispatch({
                    type: types.DATAAENVIAR,
                    payload: { ...dataAEnviar, captura3: posterUrl }
                });
                break;
            case 'captura4':
                dispatch({
                    type: types.DATAAENVIAR,
                    payload: { ...dataAEnviar, captura4: posterUrl }
                });
                break;
            case 'captura5':
                dispatch({
                    type: types.DATAAENVIAR,
                    payload: { ...dataAEnviar, captura5: posterUrl }
                });
                break;

            default:
                break;
        }
    };

    async function fetchData(tmdb) {
        setLoadingCapturas(true);
        const result = await getCapturas(tmdb, movie?.typeSearch);
        setCapturas(result?.data);
        setLoadingCapturas(false);

        // console.log('Capturas', result);
        // console.log('Movies', movie);
    }

    async function handleSubirCaptura(url, name) {
        setLoadingUploadCapturas(true);
        if (selectedRespaldo[name] === url) {
            setLoadingUploadCapturas(false);
            return;
        }
        let result;
        let urlImg;

        result = await subirPosterImgur(url);
        if (result.status !== 200) {
            const res = await subirPoster(url);
            urlImg = res?.data?.url;
        } else {
            urlImg = result?.data?.link;
        }

        // if (result === 'Error') {
        //     const res = await subirPoster(url);
        //     urlImg = res?.data?.url;
        // } else {
        //     urlImg = result?.data?.link;
        // }

        // handleChangePoster(result?.data?.url, name);
        handleChangePoster(urlImg, name);
        // setCapturasSubidas(pre => ({ ...pre, [name]: result?.data?.url }));
        setCapturasSubidas(pre => ({ ...pre, [name]: urlImg }));
        setSelectedRespaldo(pre => ({ ...pre, [name]: url }));
        // setSelectedRespaldo(pre => [...pre, url]);
        // setObjValues(pre => [...pre, ...Object.values(capturasSubidas)]);
        setLoadingUploadCapturas(false);
        // console.log('selectedRespaldo', selectedRespaldo);
        // console.log('capturasSubidas', capturasSubidas);
    }

    // console.log('capturasSubidas', capturasSubidas);

    useEffect(() => {
        fetchData(movie?.id);
    }, [movie]);

    // console.log('objValues', objValues);

    return {
        capturas,
        loadingCapturas,
        loadingUploadCapturas,
        capturasSubidas,
        objValues,
        handleSubirCaptura
    };
};

export default useGetCapturas;
