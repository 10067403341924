import React, { useState } from 'react';
import CollapsibleTable from '../Table/CollapsibleTable';
import { FormControlLabel, Stack, Switch } from '@mui/material';

const MediaInfoView = ({
    mediaInfo,
    file,
    handleChangeSendMediainfo,
    handleChangeSendMediainfoPer,
    sendMediainfo,
    sendMediainfoPer
}) => {
    return (
        <>
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                gap={1}>
                <h5>Info Archivo: {file?.name}</h5>
                {/* <FormControlLabel
                    control={
                        <Switch
                            checked={sendMediainfo}
                            onChange={handleChangeSendMediainfo}
                        />
                    }
                    label="Enviar Mediainfo"
                /> */}
                <FormControlLabel
                    control={
                        <Switch
                            checked={sendMediainfoPer}
                            onChange={handleChangeSendMediainfoPer}
                        />
                    }
                    label="Enviar Mediainfo"
                />
            </Stack>
            <CollapsibleTable mediaInfo={mediaInfo?.media?.track} />
        </>
    );
};

export default MediaInfoView;
