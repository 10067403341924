import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import Zoom from '@mui/material/Zoom';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14
    }
}));

const FicharTooltip = ({ mensaje }) => {
    return mensaje ? (
        <LightTooltip TransitionComponent={Zoom} title={mensaje}>
            <Button>
                <MarkUnreadChatAltIcon sx={{ color: '#387ADF' }} />
            </Button>
        </LightTooltip>
    ) : null;
};

export default FicharTooltip;
