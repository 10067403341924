import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import UsersPaste from './UsersPaste';
import Vouchers from './Vouchers';

export default function UserPasteMain() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <TabContext value={value}>
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider'
                    }}>
                    <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example">
                        <Tab label="Usuarios" value="1" />
                        <Tab label="Vouchers" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <UsersPaste />
                </TabPanel>
                <TabPanel value="2">
                    <Vouchers />
                </TabPanel>
            </TabContext>
        </Box>
    );
}
