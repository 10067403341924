import React, { useEffect, useState } from 'react';
import { fetchAllPosts, fetchUsersWP } from './service/services';
import { Grid } from '@mui/material';
import { getLocalStorage } from '../../utils/functions';
import Loading from '../Loading/Loading';
import WordpressPostsChart from './GraficaYear';

export default function BardDataGrafica() {
    const userData = getLocalStorage('user');
    const [usersWp, setUsersWp] = useState([]);
    const [loading, setLoading] = useState(true);
    const [textLoading, setTextLoading] = useState('Cargando datos...');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const users = await fetchUsersWP();
                // Iterar sobre los usuarios y cargar los posts para cada uno
                for (let i = 0; i < users.length; i++) {
                    setTextLoading(`Cargando datos de ${users[i].name}...`);
                    const posts = await fetchAllPosts(users[i].id);
                    users[i].posts = posts;
                }
                setUsersWp(users);
                setLoading(false);
            } catch (error) {
                console.error('Hubo un error al obtener datos:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);
    if (loading) {
        return <Loading text={textLoading} />;
    }

    return (
        <>
            <Grid container spacing={1}>
                {usersWp?.map((user, index) => (
                    <Grid item xs={6}>
                        <WordpressPostsChart
                            key={index}
                            wordpressPosts={user.posts}
                            userDataAlias={user.name}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
