import React from 'react';
import { styled } from '@mui/material/styles';

const CitaBloqueRoot = styled('div')({
    borderLeft: '5px solid #ddd',
    padding: '5px',
    margin: '5px 0',
    borderRadius: '5px',
    backgroundColor: '#f7f7f7'
});

const CitaBloqueContent = styled('div')({
    margin: '0',
    padding: '0',
    color: '#333'
});

const CitaBloqueP = styled('p')({
    marginBottom: '0'
});

const CitaBloque = ({ cita }) => {
    return (
        <CitaBloqueRoot>
            <CitaBloqueContent>
                <CitaBloqueP>{cita}</CitaBloqueP>
            </CitaBloqueContent>
        </CitaBloqueRoot>
    );
};

export default CitaBloque;
