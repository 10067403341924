import axios from 'axios';
import {
    API_PLANTILLAS,
    API_PLANTILLAS_PLUS,
    API_REST,
    REACT_APP_IMGUNR_CLIENT_ID
} from '../env';
import { API_REST_HD } from '../env';
import { getLocalStorage } from './functions';
const URL_API_STORAGE = 'https://api.imgbb.com/1/upload?key=';
const URL_API_IMGUR = 'https://api.imgur.com/3';
const URL_API_PIXHOST = 'https://api.pixhost.to';
// const API_REST = 'http://localhost:4000/api/v1/users';
// const API_REST = 'http://api2.hdlatino.us/api/v1/users';

// const userData = JSON.parse(localStorage.getItem('user'));
const userData = getLocalStorage('user');
const { keywp } = userData || {};

// const API_PRUEBA = 'http://localhost:4000/api/v1';

export const uploadimg = async (key, file) => {
    try {
        const data = await axios.post(`${URL_API_STORAGE}${key}`, file, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return data;
    } catch (error) {
        return error.response;
    }
};
export const uploadImgImgur = async file => {
    try {
        const data = await axios.post(`${URL_API_IMGUR}/image`, file, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Client-ID ${REACT_APP_IMGUNR_CLIENT_ID}`
                // Authorization: `Bearer 6ffb2f788b8c511b60f482f67950b7b3bb5f4bb7`
            }
        });
        return data;
    } catch (error) {
        console.log('error.response', error.response);
        return error.response;
    }
};

export const uploadImgPixhost = async url => {
    try {
        const data = await axios.post(
            `${API_REST}/pixhost`,
            { url }
            // {
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //         Authorization: `Client-ID ${REACT_APP_IMGUNR_CLIENT_ID}`
            //         // Authorization: `Bearer 6ffb2f788b8c511b60f482f67950b7b3bb5f4bb7`
            //     }
            // }
        );
        return data;
    } catch (error) {
        console.log('error.response', error);
        return error.response;
    }
};
export const uploadImgPixhostFile = async file => {
    try {
        const data = await axios.post(`${API_REST}/pixhost/upload`, file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    } catch (error) {
        console.log('error.response', error);
        return error.response;
    }
};

/**
 *
 * @param {file} imagen desde un formulario
 * @returns
 */
// export const uploadImgImgur = async file => {
//     try {
//         const data = await axios(`${URL_API_IMGUR}/image`, {
//             method: 'POST',
//             headers: {
//                 Authorization: `Bearer 6ffb2f788b8c511b60f482f67950b7b3bb5f4bb7`
//             },
//             body: file
//         });

//         console.log('respuesta cruda', data);
//         return data;
//     } catch (error) {
//         console.log('el error', error);
//         return error.repsonse;
//     }
// };

export const generarPoster = async values => {
    const { data } = await axios.post(`${API_REST}/poster/image`, values);

    return data;
};
export const generarPost = async values => {
    return await axios.post(`${API_REST}/post`, { ...values, apiwp: keywp });
};

export const uploadUrlImagenImbb = async (key, url) => {
    const formData = new FormData();
    formData.append('image', url);
    const { data } = await axios.post(`${URL_API_STORAGE}${key}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
    return data;
};

/**
 * IMAGENES
 */

export const getImages = async () => {
    try {
        return await axios.get(`${API_REST_HD}images`);
    } catch (error) {
        return error.response;
    }
};

export const subirImagen = async values => {
    try {
        return await axios.post(`${API_REST_HD}images`, values);
    } catch (error) {
        return error.response;
    }
};
export const deleteImagen = async id => {
    try {
        return await axios.delete(`${API_REST_HD}images`, {
            data: { id }
        });
    } catch (error) {
        return error.response;
    }
};
export const crearPlantillaPoster = async data => {
    try {
        return await axios.post(`${API_REST}/poster/image`, data);
    } catch (error) {
        return error.response;
    }
};

/**
 * AUDIO
 */

/**
 *
 * @param {nombre_audio} data
 * @returns object
 * nombre_audio: "nombreaudio"
 */
export const crearAudio = async data => {
    try {
        return await axios.post(`${API_REST_HD}audios`, data);
    } catch (error) {
        return error.response;
    }
};

export const obtenerAudios = async data => {
    try {
        return await axios.get(`${API_REST_HD}audios`, data);
    } catch (error) {
        return error.response;
    }
};

/**
 *
 * @param {audioId} audioId
 * @returns objet
 * id: "nunmero id de Audio"
 */
export const deleteAudio = async audioId => {
    try {
        return await axios.delete(`${API_REST_HD}audios`, { data: audioId });
    } catch (error) {
        return error.response;
    }
};

/**
 * PLANTILLAS
 */

export const getPlantillas = async () => {
    try {
        return await axios.get(`${API_PLANTILLAS}`);
    } catch (error) {
        return error.response;
    }
};

export const subirPlantilla = async values => {
    try {
        return await axios.post(`${API_PLANTILLAS}`, values);
    } catch (error) {
        return error.response;
    }
};
export const deletePlantilla = async id => {
    try {
        return await axios.delete(`${API_PLANTILLAS}`, {
            data: { id }
        });
    } catch (error) {
        return error.response;
    }
};

/**
 *
 * @returns PLANTILLAS EXTRAS PLUS (DIFERENTE A LAS EXTRAS)
 */
export const getPlantillasPlus = async () => {
    try {
        return await axios.get(`${API_PLANTILLAS_PLUS}`);
    } catch (error) {
        return error.response;
    }
};

export const subirPlantillaPlus = async values => {
    try {
        return await axios.post(`${API_PLANTILLAS_PLUS}`, values);
    } catch (error) {
        return error.response;
    }
};
export const deletePlantillaPlus = async id => {
    try {
        return await axios.delete(`${API_PLANTILLAS_PLUS}`, {
            data: { id }
        });
    } catch (error) {
        return error.response;
    }
};
