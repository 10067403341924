import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { fijarEntradaComoPegajosa } from '../../utils/apiwp';
import { Stack, Switch } from '@mui/material';
import Loading from '../Loading/Loading';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const ButtonsColeections = ({ row, handleDeletePost, multipaste = false }) => {
    const [show, setShow] = useState(false);
    const [sticky, setSticky] = useState(row?.sticky);
    const [stickyLoading, setStickyLoading] = useState(false);

    const handleSticky = async () => {
        setStickyLoading(true);
        const data = await fijarEntradaComoPegajosa(row?.id, sticky);
        setSticky(() => data?.data?.sticky);
        setStickyLoading(false);
    };

    const handleShow = () => setShow(!show);

    const link = multipaste
        ? `https://vip.hdlatino.us/pastes/?v=${row.url}`
        : row?.link;

    const PostId = multipaste ? row.pasteID : row?.id;

    const editPasteLink = multipaste
        ? `/edit-paste/${row.pasteID}`
        : `/list-post/${row?.id}`;

    const showButton = row?.status !== 'trash';

    const textBorrarPost = multipaste ? 'paste' : 'post';
    const label = {
        inputProps: { 'aria-label': 'Color switch demo', title: 'Anclar post' }
    };

    if (stickyLoading) return <Loading />;

    // handleDeletePost(PostId)
    return (
        <>
            <div className="d-flex gap-2">
                <Link
                    className="btn btn-outline-info"
                    to={editPasteLink}
                    title={`Editar ${textBorrarPost}`}>
                    <EditIcon />
                </Link>
                {showButton && (
                    <a
                        title={`Ver ${textBorrarPost}`}
                        className="btn btn-success"
                        href={link}
                        rel="noopener noreferrer">
                        <VisibilityIcon />
                    </a>
                )}
                {showButton && (
                    <button
                        title={`Eliminar ${textBorrarPost}`}
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleShow()}>
                        <DeleteForeverIcon />
                    </button>
                )}
                {row?.status === 'publish' && !multipaste && (
                    <Switch
                        {...label}
                        color="warning"
                        checked={sticky}
                        onChange={handleSticky}
                    />
                )}
            </div>
            <Modal
                show={show}
                onHide={handleShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Esta seguro de eliminar este {textBorrarPost}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleShow}>
                        Cancelar
                    </Button>
                    <Button
                        variant="warning"
                        onClick={() => handleDeletePost(PostId, handleShow)}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ButtonsColeections;
