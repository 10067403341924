import React, { useRef } from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    Container,
    Grid,
    Icon,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import TelegramIcon from '@mui/icons-material/Telegram';
import CropIcon from '@mui/icons-material/Crop';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import GroupIcon from '@mui/icons-material/Group';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import SettingsIcon from '@mui/icons-material/Settings';
import MovieIcon from '@mui/icons-material/Movie';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import AddHomeIcon from '@mui/icons-material/AddHome';
import CameraIndoorIcon from '@mui/icons-material/CameraIndoor';
import AddLinkIcon from '@mui/icons-material/AddLink';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import FlagIcon from '@mui/icons-material/Flag';
import images from '../../assets/images';
import { Link } from 'react-router-dom';
import { bajarTonalidad, getLocalStorage } from '../../utils/functions';

const ADD_FILTERS_TO = ['Crear Paste', 'Recortar Imagen', 'Mis Pastes'];

const TEXT_PROPERTY = {
    fontSize: '22 px',
    fontWeight: 'bold',
    backgroundImage:
        'linear-gradient(90deg, rgb(253 253 253) 0%, rgb(220 220 229) 100%, rgb(171 10 10) 100%)',
    backgroundClip: 'text', // Standard backgroundClip property
    textFillColor: 'transparent' // Standard textFillColor property
    // mr: 2
};

const Dashboard = ({ resize }) => {
    const userData = getLocalStorage('user');

    const gridItem = (obj, index) => {
        return (
            <Grid
                item
                xs={12}
                sm={resize ? 12 : 6}
                md={resize ? 12 : 6}
                lg={resize ? 12 : 4}
                xl={resize ? 12 : obj.position}
                sx={{ width: '100%' }}>
                <Card sx={getGradient(obj.color)}>
                    <Link to={obj.path} style={{ textDecoration: 'none' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                            <Typography variant="h6" sx={TEXT_PROPERTY}>
                                {obj.name}
                            </Typography>
                            <IconButton
                                color="primary"
                                aria-label="add to queue">
                                <obj.Icon
                                    sx={{
                                        fontSize: '50px',
                                        color: getColorIcon(obj.color)
                                    }}
                                />
                            </IconButton>
                        </Box>
                    </Link>
                </Card>
            </Grid>
        );
    };

    return (
        <>
            <Container maxWidth="xl" component="body" sx={{ mt: 2 }}>
                <Grid container spacing={4}>
                    {ROUTES.map((obj, index) => (
                        <React.Fragment key={index}>
                            {obj.isAdmin.includes(userData.roles)
                                ? gridItem(obj)
                                : null}
                        </React.Fragment>
                    ))}
                </Grid>
            </Container>
        </>
    );
};

const getGradient = colorIndex => {
    const color = colorIndex;
    // const color = MY_COLORS[colorIndex % MY_COLORS.length];
    // const nextColorIndex = (colorIndex + 1) % colors.length;
    return {
        width: '100%',
        borderRadius: 3,
        boxShadow: 0,
        // backgroundColor: '#dee2e8',
        // background: `linear-gradient(150deg, #260505 0%, ${color} 35%, #260505 100%)`,
        // background: `linear-gradient(90deg, rgba(131,58,180,1) 0%, ${color} 100%, rgba(252,176,69,1) 100%);`,
        background: `linear-gradient(90deg, ${bajarTonalidad(
            color,
            0.7
        )} 0%, ${color} 100%, rgba(252,176,69,1) 100%);`,
        p: 1,
        pl: 2,
        transition: 'transform 0.3s ease-in-out', // Agregamos la animación aquí
        '&:hover': {
            transform: 'scale(1.05)' // Escalamos ligeramente el componente al pasar el mouse sobre él
        }
    };
};

const getColorIcon = colorIndex => {
    // const color = MY_COLORS[colorIndex % MY_COLORS.length];
    const color = colorIndex;
    return bajarTonalidad(color, 0.7);
};

const getTofilter = name => {
    return ADD_FILTERS_TO.includes(name)
        ? 'invert(100%) sepia(0%) saturate(7466%) hue-rotate(206deg) brightness(108%) contrast(95%)'
        : 'none';
};

const ROUTES = [
    {
        path: '/crear-pelicula',
        img: images.perfil,
        name: 'CREAR POST (PELÍCULA)',
        position: 4,
        Icon: MovieIcon,
        isAdmin: ['admin', 'autor'],
        color: '#496989'
    },
    {
        path: '/crear-serie',
        img: images.perfil,
        name: 'CREAR POST (SERIE)',
        position: 4,
        Icon: LiveTvIcon,
        isAdmin: ['admin', 'autor'],
        color: '#824D74'
    },
    {
        path: '/crear-coleccion',
        img: images.perfil,
        name: 'CREAR POST (COLECCIÓN)',
        position: 4,
        Icon: LocalMoviesIcon,
        isAdmin: ['admin', 'autor'],
        color: '#2D9596'
    },
    {
        path: '/list-post',
        img: images.posts_Creados,
        name: 'EDITAR MIS POSTS',
        position: 4,
        Icon: WysiwygIcon,
        isAdmin: ['admin', 'autor'],
        color: '#FF577A'
    },
    {
        path: '/pedidos',
        img: images.Usuarios,
        name: 'PETICIONES DE POSTS',
        position: 4,
        Icon: EditNoteIcon,
        isAdmin: ['admin', 'autor'],
        color: '#41C9E2'
    },
    {
        path: '/fichar-post',
        img: images.apartar_post,
        name: 'APARTAR POST',
        position: 4,
        Icon: MarkunreadMailboxIcon,
        isAdmin: ['admin', 'autor'],
        color: '#FF79E0'
    },
    {
        path: '/post-reportados',
        img: images.apartar_post,
        name: 'POST REPORTADOS',
        position: 12,
        Icon: FlagIcon,
        isAdmin: ['admin', 'autor'],
        color: '#496989'
    },
    {
        path: '/mis-pastes',
        img: images.mis_pastes,
        name: 'MIS PASTES',
        position: 4,
        Icon: ContentPasteSearchIcon,
        isAdmin: ['admin', 'autor'],
        color: '#41C9E2'
    },
    {
        path: '/crear-paste',
        img: images.crear_paste,
        name: 'CREAR PASTE',
        position: 4,
        Icon: ContentPasteIcon,
        isAdmin: ['admin', 'autor'],
        color: '#FFB20B'
    },
    {
        path: '/admin-acortadores',
        img: images.configuracion,
        name: '+ NUEVO SERVIDOR',
        position: 4,
        Icon: AddLinkIcon,
        isAdmin: ['admin'],
        color: '#824D74'
    },
    {
        path: '/telegram',
        img: images.Logo_Telegram1,
        name: 'TELEGRAM',
        position: 6,
        Icon: TelegramIcon,
        isAdmin: ['admin', 'autor'],
        color: '#FF577A'
    },
    {
        path: '/notas',
        img: images.note,
        name: 'NOTAS',
        position: 6,
        Icon: StickyNote2Icon,
        isAdmin: ['admin', 'autor'],
        color: '#41C9E2'
    },

    {
        path: '/perfil',
        img: images.perfil,
        name: 'MI PERFIL',
        position: 4,
        Icon: AccountCircleIcon,
        isAdmin: ['admin', 'autor'],
        color: '#DDDDDD'
    },
    {
        path: '/config-paste',
        img: images.configuracion,
        name: 'CONFIGURAR MULTIPASTE',
        position: 4,
        Icon: PermDataSettingIcon,
        isAdmin: ['admin', 'autor'],
        color: '#DDDDDD'
    },
    {
        path: '/acortadores',
        img: images.Usuarios,
        name: 'ACORTADORES',
        position: 4,
        Icon: SettingsIcon,
        isAdmin: ['admin', 'autor'],
        color: '#DDDDDD'
    },
    {
        path: '/users-paste',
        img: images.Usuarios,
        name: 'USUARIOS MULTIPASTE',
        position: 4,
        Icon: GroupIcon,
        isAdmin: ['admin'],
        color: '#FFB20B'
    },
    {
        path: '/admin-extras',
        img: images.Usuarios,
        name: 'AGREGAR EXTRA',
        position: 4,
        Icon: CameraIndoorIcon,
        isAdmin: ['admin'],
        color: '#FF79E0'
    },
    {
        path: '/admin-casas',
        img: images.Usuarios,
        name: 'AGREGAR PRODUCTORAS',
        position: 4,
        Icon: AddHomeIcon,
        isAdmin: ['admin'],
        color: '#2D9596'
    },
    {
        path: '#!',
        img: images.cropIcon,
        name: 'CREAR PRODUCTORAS + EXTRAS',
        position: 4,
        Icon: AccountBalanceIcon,
        isAdmin: ['admin'],
        color: '#FF577A'
    },
    {
        path: '/crop-image',
        img: images.cropIcon,
        name: 'RECORTAR IMAGEN',
        position: 4,
        Icon: CropIcon,
        isAdmin: ['admin', 'autor'],
        color: '#824D74'
    },
    {
        path: '/produccion-uploaders',
        img: images.cropIcon,
        name: 'PRODUCCIÓN UPLOADER',
        position: 4,
        Icon: LineAxisIcon,
        isAdmin: ['admin'],
        color: '#496989'
    }
];

export default Dashboard;
