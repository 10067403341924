import axios from 'axios';
import { API_REST_UNIVERSAL } from '../../../env';
const URL = API_REST_UNIVERSAL;

export const getNotifications = async userId => {
    try {
        const response = await axios.get(`${URL}/report-post/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error getting notifications', error);
        return [];
    }
};

export const updateNotifications = async (userId, reportedPostId) => {
    try {
        const response = await axios.put(`${URL}/report-post`, {
            userId,
            reportedPostId,
            read: true
        });
        return response.data;
    } catch (error) {
        console.error('Error getting notifications', error);
        return [];
    }
};
export const deleteReportPost = async id => {
    try {
        const response = await axios.delete(`${URL}/report-post`, {
            data: { id }
        });
        return response.data;
    } catch (error) {
        console.error('Error getting notifications', error);
        return [];
    }
};

export const createPostSeparetedtNotification = async (title, uploader) => {
    try {
        const response = await axios.post(`${URL}/report-post`, {
            typeNotify: 'post_separated',
            title: 'post_separated',
            text: title,
            url: '/fichar-post',
            description: '',
            uploader: uploader,
            motivo: ''
        });
        return response.data;
    } catch (error) {
        console.error('Error getting notifications', error);
        return [];
    }
};
