import { Store } from 'pullstate';

const SearchStore = new Store({
    result: [],
    type: 'movie',
    loading: false,
    details: {},
    posts: [],
    post: {},
    datosbusqueda: {
        tmdb: '',
        movie: '',
        typeSearch: '1',
        idioma: 'es-MX'
    },
    openModal: false,
    showButtonFilmaffinity: false
});

const setPost = value => {
    SearchStore.update(s => {
        s.post = value;
    });
};
const setShowButtonFilmaffinity = value => {
    SearchStore.update(s => {
        s.showButtonFilmaffinity = value;
    });
};
const setOpenModal = value => {
    SearchStore.update(s => {
        s.openModal = value;
    });
};
const setDatosBusqueda = values => {
    SearchStore.update(s => {
        s.datosbusqueda = values;
    });
};
const setPosts = posts => {
    SearchStore.update(s => {
        s.posts = posts;
    });
};

const setSearch = search => {
    SearchStore.update(s => {
        s.result = search;
    });
};
const setType = search => {
    SearchStore.update(s => {
        s.type = search;
    });
};
const setDetails = search => {
    SearchStore.update(s => {
        s.details = search;
    });
};
const setLoadingSearch = loading => {
    SearchStore.update(s => {
        s.loading = loading;
    });
};

function useSearchFicharPost() {
    const store = SearchStore.useState(s => s);
    const result = store.result;
    const loading = store.loading;
    const type = store.type;
    const details = store.details;
    const posts = store.posts;
    const post = store.post;
    const datosbusqueda = store.datosbusqueda;
    const openModal = store.openModal;
    const showButtonFilmaffinity = store.showButtonFilmaffinity;

    return {
        result,
        loading,
        type,
        details,
        posts,
        datosbusqueda,
        openModal,
        showButtonFilmaffinity,
        post,
        setPost,
        setShowButtonFilmaffinity,
        setOpenModal,
        setDatosBusqueda,
        setPosts,
        setSearch,
        setLoadingSearch,
        setDetails,
        setType
    };
}

export { useSearchFicharPost };
