import axios from 'axios';
import { useState, useEffect } from 'react';

let reqOptions = {
    url: '',
    method: 'GET',
    headers: {}
};

export function useFetch(
    url,
    options = reqOptions,
    setLoadingServer = () => {}
) {
    const { method = 'GET', headers = {}, body, repetir } = options;

    // ---------------------------------------------
    // With Abort Controller
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    let headersList = {
        Accept: '*/*',
        'User-Agent':
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36',
        'Content-Type': 'application/json'
    };

    let reqOptions = {
        url: url,
        method: 'GET',
        headers: headersList
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setLoadingServer(true);
                const response = await axios.request(reqOptions);
                // const response = await axios.get(
                //     url
                //     // {
                //     //     method: method,
                //     //     data: body
                //     // }
                // );
                setData(response.data);
                setLoading(false);
                setLoadingServer(false);
            } catch (error) {
                setLoading(false);
                setLoadingServer(false);
                setError(error);
                console.log('Error al obtener las pelis');
                // throw new Error("Error al obtener las pelis");
            }
        };

        fetchData();
    }, [url, repetir]);

    return { data, loading, error };
}
