import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LoadingButton } from '@mui/lab';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { getLocalStorage } from '../../../utils/functions';
import { deleteReportPost } from '../service/getNotifications';
import { Link } from 'react-router-dom';

const ShowButtonReportPost = ({
    owner,
    id,
    handleDeletePost,
    loading,
    postID,
    url
}) => {
    const [show, setShow] = useState(false);
    // const [loading, setLoading] = useState(false);

    const handleShow = () => setShow(!show);

    return owner ? (
        <>
            <div className="d-flex gap-2">
                {/* {userData.alias === row.apar_uploader && !row.apar_completed ? (
                    <LoadingButton {...sxButtonEdit} onClick={handleSubmitTmdb}>
                        <EditIcon />
                    </LoadingButton>
                ) : null} */}
                <Link
                    className="btn btn-warning"
                    to={`/list-post/${postID}`}
                    title={`Editar `}>
                    <EditIcon />
                </Link>

                <button
                    title={`Ver`}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => (window.location.href = url)}>
                    <RemoveRedEyeIcon />
                </button>
                <button
                    title={`Eliminar`}
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleShow()}>
                    <DeleteForeverIcon />
                </button>
            </div>
            <Modal
                show={show}
                onHide={handleShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ zIndex: 2000 }}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>¿Esta seguro de eliminar este reporte?</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleShow}>
                        Cancelar
                    </Button>
                    <LoadingButton
                        {...sxButton}
                        loading={loading}
                        onClick={() => handleDeletePost(id, handleShow)}>
                        Eliminar
                    </LoadingButton>
                </Modal.Footer>
            </Modal>
        </>
    ) : (
        <></>
    );
};

export default ShowButtonReportPost;

const sxButton = {
    sx: {
        backgroundColor: '#FFC206',
        color: 'black',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#d1a10a',
            color: '#fff',
            fontWeight: 'bold'
        }
    },
    variant: 'contained',
    disableElevation: true
};
const sxButtonEdit = {
    sx: {
        backgroundColor: '#211951',
        color: '#F0F3FF',
        '&:hover': {
            backgroundColor: '#836FFF',
            color: '#fff',
            fontWeight: 'bold'
        }
    },
    variant: 'contained',
    disableElevation: true
};
