import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FaRegImage, FaUpload } from 'react-icons/fa';
import FileUploaderPlantilla from '../../../../components/upload/FileUploaderPlantilla';
import { getLocalStorage } from '../../../../utils/functions';

const LayoutPlantillas = ({
    metadata,
    setMetadata,
    handledeGetImages,
    crearPlantillaPoster,
    deletePlantilla,
    subirPlantilla,
    isLoading,
    plus
}) => {
    const images = plus
        ? metadata?.filter(mdata => mdata.name === 'Plus')[0].data
        : metadata?.filter(mdata => mdata.name === 'Extra')[0].data;

    const userData = getLocalStorage('user');

    const [loadingPlantilla, setloadingPlantilla] = useState(false);
    const [loadingPlantilla2, setloadingPlantilla2] = useState(false);
    const [loadingPlantilla3, setloadingPlantilla3] = useState(false);

    /**
     *
     * @returns ELIMINAR UNA PLANTILA QUE ESTE EN EL MODAL
     */

    const handleDeleteImage = async id => {
        const { data, status } = await deletePlantilla(id);

        if (status !== 200) {
            return <div>Error</div>;
        }

        handledeGetImages();
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="d-flex justify-content-center mb-5 gap-3">
                {userData.roles === 'admin' ? (
                    <>
                        <FileUploaderPlantilla
                            handledeGetImages={handledeGetImages}
                            setLoading={setloadingPlantilla}
                            loading={loadingPlantilla}
                            handleSubirImagen={subirPlantilla}>
                            <div className="d-flex gap-2 align-items-center">
                                {loadingPlantilla ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    // <FaUpload />
                                    <img
                                        src="http://www.google.com/s2/favicons?domain=www.imgbb.com"
                                        alt="subir imagen"
                                        title="Imgbb"
                                    />
                                )}
                                Imgbb
                            </div>
                        </FileUploaderPlantilla>
                        <FileUploaderPlantilla
                            handledeGetImages={handledeGetImages}
                            setLoading={setloadingPlantilla2}
                            loading={loadingPlantilla2}
                            handleSubirImagen={subirPlantilla}
                            imgur>
                            <div className="d-flex gap-2 align-items-center">
                                {loadingPlantilla2 ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    // <FaUpload />
                                    <img
                                        src="http://www.google.com/s2/favicons?domain=www.imgur.com"
                                        alt="Subir imagen"
                                        title="Imgur"
                                    />
                                )}
                                Imgur
                            </div>
                        </FileUploaderPlantilla>
                        <FileUploaderPlantilla
                            handledeGetImages={handledeGetImages}
                            setLoading={setloadingPlantilla3}
                            loading={loadingPlantilla3}
                            handleSubirImagen={subirPlantilla}
                            pixhost>
                            <div className="d-flex gap-2 align-items-center">
                                {loadingPlantilla3 ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    // <FaUpload />
                                    <img
                                        src="http://www.google.com/s2/favicons?domain=www.pixhost.to"
                                        alt="subir imagen"
                                        title="Pixhost"
                                    />
                                )}
                                Pixhost
                            </div>
                        </FileUploaderPlantilla>
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="row row-cols-1 row-cols-md-3 g-4">
                {images?.map((image, i) => (
                    <div className="col" key={i}>
                        <div className="card">
                            <img
                                src={image.url}
                                className="card-img-top"
                                alt={image.label}
                                title={image.label}
                            />
                            {userData.roles === 'admin' ? (
                                <div className="card-body">
                                    <div className="d-flex justify-content-center">
                                        <button
                                            onClick={() =>
                                                handleDeleteImage(image.id)
                                            }
                                            type="button"
                                            className="btn btn-outline-dark btn-sm">
                                            Eliminar
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default LayoutPlantillas;
