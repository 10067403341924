import React from 'react';

const ShowPoster = ({ poster, title }) => {
    return (
        <img
            src={`https://image.tmdb.org/t/p/w600_and_h900_bestv2${poster}`}
            alt={title}
            style={{ width: '50px' }}
            title={title}
        />
    );
};

export default ShowPoster;
