import React, { useRef } from 'react';
import { useState } from 'react';
import { subirImagen, uploadImgPixhostFile } from '../../utils/api';
import {
    subirPoster,
    subirPosterImgur,
    subirPosterPixhostFile
} from '../../utils/subirimg';
import Button from '../button/Button';

const FileUploaderPlantilla = ({
    children,
    loading,
    setLoading,
    handledeGetImages,
    imgur = false,
    handleSubirImagen = null,
    pixhost = false
}) => {
    const hiddenFileInputPlantilla = useRef(null);
    const subirImagenes = handleSubirImagen ?? subirImagen;

    const handleClick = event => {
        hiddenFileInputPlantilla.current.click();
    };

    const handleChange = async event => {
        setLoading(true);
        try {
            console.log(event.target.files[0]);
            const fileUploaded = event.target.files[0];
            const nameImg = event.target.files[0].name
                .split('.')
                .slice(0, -1)
                .join('.');
            let mydata;

            if (imgur) {
                mydata = await subirPosterImgur(fileUploaded);
                mydata = {
                    ...mydata,
                    data: {
                        ...mydata.data,
                        url: mydata.data.link,
                        title: nameImg
                    }
                };
            } else if (pixhost) {
                mydata = await subirPosterPixhostFile(fileUploaded);
                mydata = {
                    ...mydata,
                    data: {
                        ...mydata.data,
                        url: mydata.data.url,
                        title: nameImg
                    }
                };
            } else {
                mydata = await subirPoster(fileUploaded);
            }

            // const { data } = await subirPoster(fileUploaded);

            const { data } = mydata;
            const dataAEnviar = handleSubirImagen
                ? { ptl_url: data?.url, ptl_name: data?.title }
                : { url: data?.url, name: data?.title };

            await subirImagenes(dataAEnviar);
            await handledeGetImages();

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <>
            <Button
                props={{
                    size: 'sm',
                    variant: 'flat',
                    type: 'button'
                }}
                onClick={handleClick}>
                {children}
            </Button>
            <input
                type="file"
                ref={hiddenFileInputPlantilla}
                onChange={handleChange}
                style={{ display: 'none' }}
                accept="image/*"
            />
        </>
    );
};

export default FileUploaderPlantilla;
