import React from 'react';
import useFichar from '../hooks/useFichar';
import { getLocalStorage } from '../../../utils/functions';

const FicharImg = ({ row }) => {
    const userData = getLocalStorage('user');
    const { handleSubmitTmdb } = useFichar(row);

    return (
        <img
            onClick={
                userData.alias === row.apar_uploader ? handleSubmitTmdb : null
            }
            src={`https://image.tmdb.org/t/p/w600_and_h900_bestv2${row.apar_poster}`}
            alt={row.apar_title}
            style={{ width: '50px' }}
            title="Editar Post esto es un texto muy largo que ademas puede ser que no este a la altura de lo que se espera de el, pero es lo que hay y no se puede hacer nada al respecto"
        />
    );
};

export default FicharImg;
