import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import IconButtons from '../IconButtons/IconButtons';
import {
    Stack,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Paper
} from '@mui/material';

const WordpressPostsChart = ({ wordpressPosts, userDataAlias }) => {
    const [chartData, setChartData] = useState({
        options: {
            xaxis: {
                categories: []
            }
        },
        series: [
            {
                name: 'Posts',
                data: []
            }
        ]
    });
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [actualMonth, setActualMonth] = useState();
    const [actualYear, setActualYear] = useState();
    const [chartType, setChartType] = useState('month'); // 'year' or 'month'

    const generateChartData = async () => {
        if (chartType === 'year') {
            const months = [];
            const data = [];

            for (let month = 0; month < 12; month++) {
                const startDate = new Date(
                    selectedDate.getFullYear(),
                    month,
                    1
                );
                const endDate = new Date(
                    selectedDate.getFullYear(),
                    month + 1,
                    0
                );
                const monthName = startDate.toLocaleDateString('es-ES', {
                    month: 'long'
                });

                const postsForMonth = wordpressPosts.filter(post => {
                    const postDate = new Date(post.date);
                    return postDate >= startDate && postDate <= endDate;
                });
                const count = postsForMonth.length;

                months.push(monthName);
                data.push(count);
            }

            setChartData({
                options: {
                    xaxis: {
                        categories: months
                    }
                },
                series: [
                    {
                        name: 'Posts',
                        data: data
                    }
                ]
            });
            setActualYear(selectedDate.getFullYear());
        } else if (chartType === 'month') {
            const daysInMonth = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth() + 1,
                0
            ).getDate();
            const categories = [];
            const data = [];

            for (let i = 1; i <= daysInMonth; i++) {
                categories.push(i);
                const date = new Date(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth(),
                    i
                );
                const dateString = date.toISOString().split('T')[0];

                const postsForDate = wordpressPosts.filter(
                    post => post.date.split('T')[0] === dateString
                );
                const count = postsForDate.length;
                data.push(count);
            }

            setChartData({
                options: {
                    xaxis: {
                        categories: categories
                    }
                },
                series: [
                    {
                        name: 'Posts',
                        data: data
                    }
                ]
            });
            setActualMonth(
                selectedDate
                    .toLocaleDateString('es-ES', {
                        month: 'long'
                    })
                    .toUpperCase()
            );
            setActualYear(selectedDate.getFullYear());
        }
    };

    const handlePrevious = () => {
        if (chartType === 'year') {
            const previousYear = new Date(selectedDate);
            previousYear.setFullYear(previousYear.getFullYear() - 1);
            setSelectedDate(previousYear);
        } else if (chartType === 'month') {
            const previousMonth = new Date(selectedDate);
            previousMonth.setMonth(previousMonth.getMonth() - 1);
            setSelectedDate(previousMonth);
        }
    };

    const handleNext = () => {
        if (chartType === 'year') {
            const nextYear = new Date(selectedDate);
            nextYear.setFullYear(nextYear.getFullYear() + 1);
            setSelectedDate(nextYear);
        } else if (chartType === 'month') {
            const nextMonth = new Date(selectedDate);
            nextMonth.setMonth(nextMonth.getMonth() + 1);
            setSelectedDate(nextMonth);
        }
    };

    const handleChartTypeChange = event => {
        setChartType(event.target.value);
    };

    useEffect(() => {
        generateChartData();
    }, [wordpressPosts, selectedDate, chartType]);

    return (
        <Paper sx={{ width: '100%', p: 1 }}>
            <Stack width={'100%'}>
                <IconButtons
                    handlePrevious={handlePrevious}
                    handleNext={handleNext}
                />
                <FormControl component="fieldset">
                    <RadioGroup
                        row
                        aria-label="chart-type"
                        name="chart-type"
                        value={chartType}
                        onChange={handleChartTypeChange}>
                        <FormControlLabel
                            value="year"
                            control={<Radio />}
                            label="Año"
                        />
                        <FormControlLabel
                            value="month"
                            control={<Radio />}
                            label="Mes"
                        />
                    </RadioGroup>
                </FormControl>
                <Stack
                    direction={'row'}
                    justifyContent={'space-evenly'}
                    alignItems={'center'}>
                    <Typography
                        variant="p"
                        sx={{ fontSize: '12px', fontWeight: 600 }}>
                        {userDataAlias}
                    </Typography>
                    <Typography
                        variant="span"
                        sx={{ fontSize: '12px', textAlign: 'center' }}>
                        {chartType === 'year'
                            ? actualYear
                            : `${actualMonth} - ${actualYear}`}
                    </Typography>
                </Stack>

                <Chart
                    options={chartData.options}
                    series={chartData.series}
                    type="bar"
                    height={350}
                />
            </Stack>
        </Paper>
    );
};

export default WordpressPostsChart;
