import React from 'react';
import './loading.css';

const Loading = ({ text }) => {
    return (
        <>
            <div className="loading_title">{text}</div>
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    );
};

export default Loading;
