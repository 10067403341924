import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import FicharPost from './FicharPost';
import FicharDataTable from './components/FicharDataTable';

const FicharTabs = () => {
    return (
        <Tabs
            defaultActiveKey="list_post_apartados"
            id="dddd"
            className="d-flex flex-wrap align-items-center justify-content-center ">
            <Tab
                eventKey="apartar_post"
                title="APARTAR POST"
                style={{
                    border: '1px solid #dee2e6',
                    borderTop: 0,
                    minHeight: '80px'
                }}>
                <FicharPost />
            </Tab>
            <Tab
                eventKey="list_post_apartados"
                title="POSTS APARTADOS"
                style={{
                    border: '1px solid #dee2e6',
                    borderTop: 0
                }}>
                <FicharDataTable />
            </Tab>
        </Tabs>
    );
};

export default FicharTabs;
