import React, { useRef } from 'react';
import Button from '../button/Button';

const FileUploader = ({
    subirPoster,
    handleChangePoster,
    name,
    setLoading,
    children
}) => {
    const hiddenFileInput = useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleChange = async event => {
        let loading = {};
        loading[name] = true;
        try {
            setLoading(loading);
            const fileUploaded = event.target.files[0];
            const { data } = await subirPoster(fileUploaded);
            handleChangePoster(data?.url, name);
            loading[name] = false;
            setLoading(loading);
        } catch (error) {
            loading[name] = false;
            setLoading(loading);
        }
    };

    return (
        <>
            <Button
                props={{
                    size: 'sm',
                    variant: 'flat',
                    type: 'button'
                }}
                onClick={handleClick}>
                {children}
            </Button>
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
                accept="image/*"
            />
        </>
    );
};

export default FileUploader;
