import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Paste from '../components/paste/Paste';

const LayoutPaste = ({ keyTabDefault }) => {
    return (
        <Container fluid="md" style={{ background: '#EBEBEB' }}>
            <Paste keyTabDefault={keyTabDefault} />
        </Container>
    );
};

export default LayoutPaste;
