import React, { useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import useLocalStorage from '../../hooks/useLocalStorage';
import { updateUser } from '../../utils/apihdlatino';
import { Form as MyForm, Formik } from 'formik';
import { getLocalStorage } from '../../utils/functions';
import AddAcortadores from './components/AddAcortadores';
import {
    Box,
    Button as ButtonBase,
    Grid,
    Stack,
    TextField
} from '@mui/material';

const ACORTADORES = [
    { name: 'ouo.io', key: 'ouo', value: '' },
    { name: 'cpmlink.net', key: 'cpmlink', value: '' },
    { name: 'shrinkme.io', key: 'shrinkme', value: '' },
    { name: 'exe.io', key: 'exe', value: '' },
    { name: 'fc.lc', key: 'fc', value: '' }
];

const FormAcortadores = () => {
    const [loading, setLoading] = useState(false);

    // const userData = JSON.parse(localStorage.getItem('user'));
    const userData = getLocalStorage('user');

    const [myuser, setMyUser] = useLocalStorage('user', userData);

    const handleSubmit = async values => {
        let obj = {};

        for (const key in values) {
            if (values[key].length > 0) {
                obj[key] = values[key];
            }
        }
        setLoading(true);

        const objAEnviar = {
            username: myuser?.username,
            password: '',
            ...obj
        };

        const updateUseData = await updateUser(myuser?.id, objAEnviar);

        if (updateUseData.status === 'error') {
            toast.error(updateUseData.message);
            setLoading(false);
            return;
        }

        toast.success('Apis agregadas!');
        setMyUser({
            ...myuser,
            ...obj
        });
        setLoading(false);
        // window.location.href = '/acortadores';
        return;
    };

    return (
        <Container style={{ height: '100%' }}>
            <Formik
                initialValues={{
                    ouo: '',
                    cpmlink: '',
                    shrinkme: '',
                    exe: '',
                    fc: ''
                }}
                enableReinitialize
                onSubmit={handleSubmit}>
                {({ values, handleChange }) => (
                    <MyForm>
                        {/* <Row className="justify-content-md-center pt-3">
                            <Col md={6}>
                                {ACORTADORES?.map(acortador => (
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail">
                                        <Form.Label>
                                            {acortador.name}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Api Key acortador"
                                            name={acortador.key}
                                            value={values[acortador?.key]}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                ))}
                                <Button
                                    className="bg-dark btn btn-outline-light me-2"
                                    type="submit">
                                    Guardar
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        hidden={!loading}
                                        className="mx-2"
                                    />
                                </Button>
                            </Col>
                        </Row> */}
                        <AddAcortadores />
                    </MyForm>
                )}
            </Formik>

            <Toaster position="top-center" reverseOrder={false} />
        </Container>
    );
};

export default FormAcortadores;
