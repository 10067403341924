import styled from '@emotion/styled';
import images from '../../../../assets/images';

const useStyles = () => {
    const ImgLogo = styled('img')({
        borderRadius: '50%',
        width: '42px',
        height: '42px'
    });
    const SpanTitle = styled('span')({
        fontSize: '18px'
    });
    const SpanSuscriptores = styled('span')({
        fontSize: '13px'
    });

    const SectionBody = styled('section')({
        backgroundImage: `url(${images.telegram_background})`,
        padding: '5px 0',
        minHeight: '529px',
        padding: '10px'
    });
    const TextBody = styled('p')({
        padding: '10px'
    });
    const ImgChat = styled('img')({
        position: 'relative',
        top: '-27px',
        bottom: 0,
        left: '-10px',
        height: 'auto',
        width: '10px'
    });
    const Imgposter = styled('img')({
        height: 'auto',
        width: '200px'
    });
    const TextContent = styled('p')({
        fontWeight: 'bolder'
    });

    return {
        ImgLogo,
        SpanTitle,
        SpanSuscriptores,
        SectionBody,
        TextBody,
        ImgChat,
        Imgposter,
        TextContent
    };
};

export default useStyles;
