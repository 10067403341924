import { Navigate } from 'react-router-dom';
import FillTabs from '../components/FillTabs/FillTabs';
import FormAcortadores from '../components/formAcortadores/FormAcortadores';
import FormPaste from '../components/FormPaste/FormPaste';
import EditPaste from '../components/paste/EditPaste';
import BasicTabs from '../components/Tabs';
import Telegram from '../components/Telegram/Telegram';
import Layout from '../layout/Layout';
import LayoutMain from '../layout/LayoutMain';
import Login from '../pages/login/Login';
import Perfil from '../pages/perfil';
import Post from '../pages/post';
import ListPost from '../pages/post/ListPost';
import MyPost from '../pages/post/MyPost';
import UsersPaste from '../pages/usersPaste/UsersPaste';
import UserPasteMain from '../pages/usersPaste/UserPasteMain';
import LayoutMainTelegram from '../components/Telegram/LayoutMainTelegram';
import NotasLayout from '../components/Notas/NotasLayout';
import Dashboard from '../pages/dashboard/Dashboard';
import FicharTabs from '../components/FicharPost/FicharTabs';
import PageCasas from '../pages/casas/PageCasas';
import PostReportados from '../components/Notification/components/PostReportados';
import LayoutGrafica from '../pages/grafica/LayoutGrafica';
import ResizeDivs from '../pages/dashboard/ResizeDivs';
import LayoutPedidos from '../components/pedidos/LayoutPedidos';

export const routes = [
    {
        path: '/',
        element: (
            <LayoutMain>
                <FillTabs />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/crear-pelicula',
        element: (
            <LayoutMain>
                <FillTabs rootTypeSearch="1" />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/crear-serie',
        element: (
            <LayoutMain>
                <FillTabs rootTypeSearch="0" />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/crear-coleccion',
        element: (
            <LayoutMain>
                <FillTabs rootTypeSearch="2" />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/admin-acortadores',
        element: (
            <LayoutMain>
                <FillTabs
                    defaultActiveKey="crear_paste"
                    keyTabDefaultMultipaste="config"
                />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/admin-casas',
        element: (
            <LayoutMain>
                <PageCasas />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/admin-extras',
        element: (
            <LayoutMain>
                <PageCasas plus />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/dashboard',
        element: (
            <LayoutMain>
                <ResizeDivs />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/mis-pastes',
        element: (
            <LayoutMain>
                <FillTabs defaultActiveKey="multipaste" />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/crear-paste',
        element: (
            <LayoutMain>
                <FillTabs defaultActiveKey="crear_paste" />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/crop-image',
        element: (
            <LayoutMain>
                <FillTabs defaultActiveKey="crop_image" />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/fichar-post',
        element: (
            <LayoutMain>
                <FicharTabs />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/post-reportados',
        element: (
            <LayoutMain>
                <PostReportados />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/mul',
        element: (
            <LayoutMain>
                <FillTabs />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/config-paste',
        element: (
            <LayoutMain>
                <FormPaste />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/acortadores',
        element: (
            <LayoutMain>
                <FormAcortadores />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/perfil',
        element: (
            <LayoutMain>
                <Perfil />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/login',
        element: <Login />,
        private: false,
        permiso: []
    },
    {
        path: '/list-post',
        element: (
            <LayoutMain>
                <BasicTabs
                    post={<Post />}
                    postBorradores={<Post borradores />}
                    postEliminados={<Post eliminados />}
                    postTrash={<Post trash />}
                />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/list-post/:id',
        element: (
            <LayoutMain>
                <MyPost />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/users-paste',
        element: (
            <LayoutMain>
                <UserPasteMain />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin']
    },
    {
        path: '/edit-paste/:id',
        element: (
            <LayoutMain>
                <EditPaste />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/telegram',
        element: (
            <LayoutMain>
                <LayoutMainTelegram />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/notas',
        element: (
            <LayoutMain>
                <NotasLayout />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin', 'autor']
    },
    {
        path: '/produccion-uploaders',
        element: (
            <LayoutMain>
                <LayoutGrafica />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin']
    },
    {
        path: '/pedidos',
        element: (
            <LayoutMain>
                <LayoutPedidos />
            </LayoutMain>
        ),
        private: true,
        permiso: ['admin']
    },
    {
        path: '*',
        element: <Navigate to="/" replace />,
        private: false,
        permiso: []
    }
];
