import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { crearArrayDeObjetos, getLocalStorage } from '../../utils/functions';
import MeGrafica from '../../components/Grafica/MeGrafica';
import { Box } from '@mui/material';

const Perfil = () => {
    // const userData = JSON.parse(localStorage.getItem('user')) || {};
    const userData = getLocalStorage('user') || {};

    console.log('userData', userData);

    // const acortadores = [
    //     { name: 'Ouo', api: userData.ouo },
    //     { name: 'Cpmlink', api: userData.cpmlink },
    //     { name: 'Shrinkme', api: userData.shrinkme },
    //     { name: 'Exe', api: userData.exe },
    //     { name: 'Fc', api: userData.fc }
    // ];

    const objInfo = crearArrayDeObjetos(userData);
    return (
        <>
            <div className="pb-5 pt-2">
                <h2>DATOS DEL PERFIL</h2>
            </div>
            <Box sx={{ mb: 2 }}>
                <MeGrafica />
            </Box>
            <Form>
                {objInfo?.map(acortador => (
                    <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPaste">
                        <Form.Label column sm="2">
                            {acortador.name}
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="text"
                                disabled
                                value={acortador.api}
                            />
                        </Col>
                    </Form.Group>
                ))}
            </Form>
        </>
    );
};

export default Perfil;
