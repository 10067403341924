import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';

const RowButtonsGroud = ({
    modalTitle,
    modalBody,
    handleSubmitTmdb,
    handleDeletePost,
    loading
}) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(!show);
    return (
        <>
            <div className="d-flex gap-2">
                {/* <LoadingButton {...sxButtonEdit}>
                    <EditIcon />
                </LoadingButton> */}

                <button
                    title={`Eliminar`}
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleShow()}>
                    <DeleteForeverIcon />
                </button>
            </div>
            <Modal
                show={show}
                onHide={handleShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ zIndex: 2000 }}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalBody}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleShow}>
                        Cancelar
                    </Button>
                    <LoadingButton
                        {...sxButton}
                        loading={loading}
                        onClick={() => handleDeletePost()}>
                        Eliminar
                    </LoadingButton>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RowButtonsGroud;

const sxButton = {
    sx: {
        backgroundColor: '#FFC206',
        color: 'black',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#d1a10a',
            color: '#fff',
            fontWeight: 'bold'
        }
    },
    variant: 'contained',
    disableElevation: true
};
const sxButtonEdit = {
    sx: {
        backgroundColor: '#211951',
        color: '#F0F3FF',
        '&:hover': {
            backgroundColor: '#836FFF',
            color: '#fff',
            fontWeight: 'bold'
        }
    },
    variant: 'contained',
    disableElevation: true
};
