import { Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import CustomTextField from '../../FicharPost/components/CustomTextField';
import { LoadingButton } from '@mui/lab';
import { useNotasStore } from '../store/store';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

const FormCrearNotas = ({ handleSubmit, loading, handleUpdateNota }) => {
    const { notaEdit, setNotaEdit } = useNotasStore();
    const isEdit = Object.keys(notaEdit).length === 0;

    const handleForm = async values => {
        if (isEdit) {
            await handleSubmit(values);
        } else {
            await handleUpdateNota(values);
        }
    };

    const handleReset = resetForm => {
        setNotaEdit({});
        resetForm();
    };

    return (
        <Formik
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
                await handleForm(values);
                setNotaEdit({});
                resetForm();
            }}
            initialValues={{
                nota: notaEdit?.text ?? '',
                id: notaEdit?.id ?? ''
            }}>
            {({ values, handleChange, resetForm }) => (
                <Form>
                    <Stack
                        direction={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        gap={1}
                        sx={{
                            width: '50%',
                            margin: '0 auto',
                            height: '100%'
                        }}>
                        <CustomTextField
                            type="textarea"
                            variant="outlined"
                            label=""
                            placeholder="Agregar Nota..."
                            name="nota"
                            value={values.nota}
                            onChange={handleChange}
                            sx={{
                                height: '100%',
                                borderRadius: '10px',
                                backgroundColor: '#F3F6F9',
                                padding: '10px',
                                fontSize: '15px',
                                fontWeight: 'bold'
                            }}
                            minRows={3}
                        />

                        <Stack direction={'row'} width={'100%'}>
                            <LoadingButton
                                loadingIndicator="Fichando..."
                                fullWidth
                                {...sxButton}
                                loading={loading}>
                                {isEdit ? 'Crear Nota' : 'Editar Nota'}
                            </LoadingButton>
                            <LoadingButton
                                {...sxButtonReloa}
                                loading={loading}
                                onClick={() => handleReset(resetForm)}>
                                <RotateLeftIcon />
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default FormCrearNotas;

const sxButton = {
    sx: {
        backgroundColor: '#FFC206',
        color: 'black',
        fontWeight: 'bold',
        position: 'relative',
        display: 'inline-block',
        width: '100%',
        height: '100%',
        '&:hover': {
            backgroundColor: '#d1a10a',
            color: '#fff',
            fontWeight: 'bold'
        }
    },
    variant: 'contained',
    type: 'submit'
};
const sxButtonReloa = {
    sx: {
        backgroundColor: '#260505',
        color: '#fff',
        fontWeight: 'bold',
        position: 'relative',
        display: 'inline-block',
        '&:hover': {
            backgroundColor: '#003C43',
            color: '#fff',
            fontWeight: 'bold'
        }
    },
    variant: 'contained',
    type: 'button'
};
