import axios from 'axios';
import { API_JUSTWHATCH, REACT_APP_APIKEY_THEMOVIES } from '../env';

export const getMovieIdJust = async (justWhatchId, type) => {
    try {
        const { data } = await axios.get(
            `https://api.themoviedb.org/3/${type}/${justWhatchId}/watch/providers?api_key=${REACT_APP_APIKEY_THEMOVIES}`
        );

        return data;
    } catch (error) {
        console.error(error);
    }
};

export const getSearchJustWhatch = async nombrePeli => {
    try {
        const { data } = await axios.get(
            `${API_JUSTWHATCH}/search/${nombrePeli}`
        );
        return data;
    } catch (error) {
        console.error(error);
    }
};
