import React, { useEffect, useState } from 'react';
import {
    getNotifications,
    updateNotifications
} from '../service/getNotifications';
import { getLocalStorage } from '../../../utils/functions';
import { create } from 'sortablejs';
import { nanoid } from 'nanoid';

const useNotification = () => {
    const userData = getLocalStorage('user');
    const [notificationsAll, setNotificationsAll] = useState([]);

    const getAllNotifications = async () => {
        const notifications = await getNotifications(userData.id);
        setNotificationsAll(() => handlePrepareNotification(notifications));
    };

    const handlePrepareNotification = notifications => {
        const arrayNoti = notifications?.map(notification => {
            return {
                id: nanoid(),
                read: notification.read,
                createdAt: Date.now(),
                content: {
                    typeNotify: notification.ReportedPost.rep_typeNotify,
                    title: notification.ReportedPost.rep_title,
                    text: notification.ReportedPost.rep_content,
                    url: notification.ReportedPost.rep_url,
                    uploader: notification.ReportedPost.rep_uploader
                },
                data: {
                    id: notification.ReportedPost.id
                },
                type: notification.read ? 'success' : 'info'
            };
        });
        return arrayNoti;
    };

    const handleUpdateNotification = async reportedPostId => {
        await updateNotifications(userData.id, reportedPostId);
    };

    useEffect(() => {
        getAllNotifications();
    }, []);

    return { notificationsAll, handleUpdateNotification };
};

export default useNotification;
