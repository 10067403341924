import React from 'react';
import { subirPosterImgur } from '../../utils/subirimg';
import Button from '../button/Button';
import { uploadImgPixhost } from '../../utils/api';
import { toast } from 'react-toastify';

const FileUploaderUrl = ({
    subirPoster,
    handleChangePoster,
    name,
    url,
    setLoading,
    children,
    imgur = false,
    pixhost = false
}) => {
    const handleChange = async event => {
        setLoading(true);
        try {
            // const { data } = await subirPoster(url);

            let mydata;
            if (imgur) {
                try {
                    /**
                     * SUBIR IMAGEN A IMGUR
                     */
                    mydata = await subirPosterImgur(url);
                    mydata = {
                        ...mydata,
                        data: {
                            ...mydata.data,
                            url: mydata.data.link,
                            title: mydata.data.deletehash
                        }
                    };
                    toast.success('Imagen subida correctamente!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } catch (error) {
                    toast.error('Error al subir la imagen!', {
                        position: toast.POSITION.TOP_CENTER
                    });

                    console.log(error);
                }
            } else if (pixhost) {
                /**
                 * SUBIR IMAGEN A PIXHOST
                 */
                try {
                    mydata = await uploadImgPixhost(url);

                    toast.success('Imagen subida correctamente!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } catch (error) {
                    toast.error('Error al subir la imagen!', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    console.log(error);
                }
            } else {
                /**
                 * SUBIR IMAGEN A IMGBB
                 */
                try {
                    mydata = await subirPoster(url);
                    toast.success('Imagen subida correctamente!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } catch (error) {
                    toast.error('Error al subir la imagen!', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    console.log(error);
                }
            }

            const { data } = mydata;

            handleChangePoster(data?.url, name);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <Button
            props={{
                size: 'sm',
                variant: 'flat',
                type: 'button'
            }}
            onClick={handleChange}>
            {children}
        </Button>
    );
};

export default FileUploaderUrl;
