export const bannerColectionButton = `[url=https://vip.hdlatino.us/tag/saw-collection][img]https://i.ibb.co/KDB7550/BANNER-SAW.jpg[/img][/url]
[url=https://vip.hdlatino.us/tag/john-Wick-Collection][img]https://vip.hdlatino.us/wp-content/uploads/2023/05/John-Wick_Banner.jpg[/img][/url]
[url=https://vip.hdlatino.us/tag/gran-turismo-coleccion][img]https://vip.hdlatino.us/wp-content/uploads/2023/09/BANNER-con-Granturismo.jpg[/img][/url]


[size=30px][color=red]░H░D░L░A░T░I░N░O░[/color][/size]

[url=https://t.me/viphdlatino][img]https://i.ibb.co/P4yNFyt/icons8-telegram-app.gif[/img][/url] [url=https://www.facebook.com/groups/hdlatinovip][img]https://i.ibb.co/KGxKPGs/icons8-facebook-48.png[/img][/url][url=https://shorturl.at/eqsX9][img]https://i.ibb.co/ByPbVFP/icons8-email-48.png[/img][/url]
`;

export const bannerColectionTop = `[url=https://hdlatinovip.mysellix.io/group/61490174095e3][size=20px][img]https://i.ibb.co/8bwB3j7/7514571.jpg[/img][/size][/url]`;
