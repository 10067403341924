import { Box, Button, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MovieIcon from '@mui/icons-material/Movie';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import React from 'react';
import images from '../../../assets/images';
import styled from '@emotion/styled';
import { extraerUrlsDeTextos, getLocalStorage } from '../../../utils/functions';
import useStyles from './hooks/useStyles';
import CitaBloque from './CitaBloque';

const Layout = ({
    tipoPost,
    dataPost,
    textoAdicional,
    urlPortada,
    alias,
    nombresCategorias,
    numeroCapitulo
}) => {
    const {
        ImgLogo,
        SpanTitle,
        SpanSuscriptores,
        SectionBody,
        TextBody,
        ImgChat,
        Imgposter,
        TextContent
    } = useStyles();
    return (
        <>
            <Grid
                sx={{
                    bgcolor: '#517da2',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#fff',
                    padding: '10px 14px',
                    borderRadius: '5px 5px 0 0'
                }}>
                {/* Logo */}
                <Grid xs={3}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px'
                        }}>
                        <ArrowBackIcon />
                        <ImgLogo src={images.profile_telegram} alt="Logo" />
                    </Box>
                </Grid>
                {/* Titulo */}
                <Grid xs={6}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <SpanTitle>HdLatino</SpanTitle>
                        <SpanSuscriptores>650 suscriptores</SpanSuscriptores>
                    </Box>
                </Grid>

                {/* Icon 3 */}
                <Grid
                    xs={3}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <MoreVertIcon />
                </Grid>
            </Grid>
            <SectionBody>
                <Box
                    sx={{
                        background: '#fff',
                        borderRadius: '7.5px 7.5px 7.5px 0',
                        maxWidth: '70%',
                        marginRight: '15px',
                        fontSize: '1rem'
                    }}>
                    <TextBody>
                        <Imgposter
                            src={
                                urlPortada ||
                                'https://via.placeholder.com/878x1315.jpg'
                            }
                            alt="placeholder"
                        />
                        <TextContent>{tipoPost || '#'}</TextContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px'
                            }}>
                            <Box>
                                <MovieIcon /> {dataPost?.title?.rendered}
                            </Box>
                            <Box>{textoAdicional}</Box>
                            <Box>
                                <TextContent>
                                    🎭
                                    {nombresCategorias.map(
                                        i => `#${i.name.replace(' ', '_')} `
                                    )}
                                </TextContent>
                            </Box>
                            <Box>
                                <RecordVoiceOverIcon /> Uploader: {alias}
                            </Box>
                            {numeroCapitulo && (
                                <Box>
                                    <CitaBloque cita={numeroCapitulo} />
                                </Box>
                            )}
                            <Box>
                                <Button
                                    variant="outlined"
                                    startIcon={<InsertLinkIcon />}
                                    endIcon={<InsertLinkIcon />}
                                    size="small"
                                    disableRipple={true}
                                    disableFocusRipple={true}
                                    disableElevation={true}
                                    fullWidth={true}>
                                    Ir al enlace
                                </Button>
                            </Box>
                        </Box>
                    </TextBody>
                </Box>
                <ImgChat src={images.anroid_telegram_left_carat} alt="images" />
            </SectionBody>
        </>
    );
};

export default Layout;
