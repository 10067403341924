const types = {
    authLogin: 'auth - login',
    authLogout: 'auth - logout',
    productDeleteAll: 'product - delete all',
    productChange: 'product - change',
    MOVIE: 'MOVIE',
    SEARCH: 'SEARCH',
    LOADING: 'LOADING',
    STEPS: 'STEPS',
    DIRECTORS: 'DIRECTORS',
    VIEW: 'VIEW',
    DATAAENVIAR: 'DATAAENVIAR',
    TMDB: 'TMDB',
    CHANGE_ENLACE_STORED: 'CHANGE_ENLACE_STORED',
    CHANGE_ENLACE_STORED_SHORT: 'CHANGE_ENLACE_STORED_SHORT',
    BUTTONHAMBURGUESA: 'BUTTONHAMBURGUESA',
    MULTIPASTE_CREADO: 'MULTIPASTE_CREADO',
    POST_WORDPRESS: 'POST_WORDPRESS',
    STATE_CALIDADES_SELECTED: 'STATE_CALIDADES_SELECTED',
    PELIS_JUSTWHATCH_SEARCH: 'PELIS_JUSTWHATCH_SEARCH',
    PELIS_FILMAFFINITY_SEARCH: 'PELIS_FILMAFFINITY_SEARCH',
    PELIS_JUSTWHATCH_ID: 'PELIS_JUSTWHATCH_ID',
    METADATA_EXTRAS: 'METADATA_EXTRAS',
    FILL_TABS: 'FILL_TABS'
};

const initialStore = {
    user: { id: 1, name: 'Luis' },
    products: [
        { id: 1, title: 'Product #1' },
        { id: 2, title: 'Product #2' }
    ],
    movie: null,
    search: null,
    loading: false,
    steps: { step1: null, step2: null, step3: null, step4: null },
    directors: [],
    view: 0,
    dataAEnviar: null,
    TMDB: null,
    CHANGE_ENLACE_STORED_SHORT: null,
    buttonHamburguesa: true,
    multipasteCreado: null,
    postWordpress: null,
    stateCalidadesSelected: {},
    pelisJustWhatchSearch: {},
    pelisFilmaffinitySearch: [],
    pelisJustWhatchID: null,
    metadataExtras: [],
    fillTabs: ''
};

const storeReducer = (state, action) => {
    switch (action.type) {
        case types.FILL_TABS:
            return {
                ...state,
                fillTabs: action.payload
            };
        case types.METADATA_EXTRAS:
            return {
                ...state,
                metadataExtras: action.payload
            };
        case types.PELIS_JUSTWHATCH_ID:
            return {
                ...state,
                pelisJustWhatchID: action.payload
            };
        case types.PELIS_JUSTWHATCH_SEARCH:
            return {
                ...state,
                pelisJustWhatchSearch: action.payload
            };
        case types.PELIS_FILMAFFINITY_SEARCH:
            return {
                ...state,
                pelisFilmaffinitySearch: action.payload
            };
        case types.STATE_CALIDADES_SELECTED:
            return {
                ...state,
                stateCalidadesSelected: action.payload
            };
        case types.POST_WORDPRESS:
            return {
                ...state,
                postWordpress: action.payload
            };
        case types.MULTIPASTE_CREADO:
            return {
                ...state,
                multipasteCreado: action.payload
            };
        case types.BUTTONHAMBURGUESA:
            return {
                ...state,
                buttonHamburguesa: action.payload
            };
        case types.CHANGE_ENLACE_STORED_SHORT:
            return {
                ...state,
                CHANGE_ENLACE_STORED_SHORT: action.payload
            };
        case types.TMDB:
            return {
                ...state,
                TMDB: action.payload
            };
        case types.DATAAENVIAR:
            return {
                ...state,
                dataAEnviar: { ...state.dataAEnviar, ...action.payload }
            };
        case types.MOVIE:
            return {
                ...state,
                movie: action.payload
            };
        case types.SEARCH:
            return {
                ...state,
                search: action.payload
            };
        case types.LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case types.STEPS:
            return {
                ...state,
                steps: { ...state.steps, ...action.payload }
            };
        case types.DIRECTORS:
            return {
                ...state,
                directors: action.payload
            };
        case types.VIEW:
            return {
                ...state,
                view: action.payload
            };
        case types.authLogout:
            return {
                ...state,
                user: null
            };

        case types.authLogin:
            return {
                ...state,
                user: action.payload
            };

        case types.productDeleteAll:
            return {
                ...state,
                products: []
            };

        case types.productChange:
            return {
                ...state,
                products: [{ id: 3, title: 'Product #3' }]
            };

        default:
            return state;
    }
};

export { initialStore, types };
export default storeReducer;
