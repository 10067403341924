import React from 'react';
import BasicModal from '../../../components/MyModal/BasicModal';
import BasicDateTimePicker from '../../../components/BasicDateTimePicker/BasicDateTimePicker';
import dayjs from 'dayjs';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { postUpdateFecha } from '../../../utils/apiwp';

const EditFecha = ({ buttonTitle, postID }) => {
    const [currentDate, setCurrentDate] = React.useState(dayjs());
    const [loading, setLoading] = React.useState(false);

    const handleDateChange = date => {
        setCurrentDate(() => date);
    };

    const handleSubmitFecha = async () => {
        setLoading(true);
        const resul = await postUpdateFecha(postID, currentDate.format());
        console.log('resul:', resul);
        setLoading(false);
    };
    return (
        <BasicModal buttonTitle={buttonTitle} title="Editar Fecha">
            <Stack gap={1}>
                <BasicDateTimePicker
                    handleDateChange={handleDateChange}
                    currentDate={currentDate}
                />
                <LoadingButton
                    disableElevation
                    size="small"
                    onClick={handleSubmitFecha}
                    endIcon={<SaveIcon />}
                    loading={loading}
                    variant="contained">
                    <span>Guardar</span>
                </LoadingButton>
            </Stack>
        </BasicModal>
    );
};

export default EditFecha;
