import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles, styled } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        color: '#ffc206'
    }
});

export const StyledTab = styled(Tab)({
    '&.MuiTab-root.Mui-selected': {
        color: 'gold'
    },
    '&.MuiTab-root': {
        fontWeight: 'bold'
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

export default function BasicTabs({
    post,
    postBorradores,
    postEliminados,
    postTrash
}) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const classes = useStyles();

    return (
        <Box sx={{ width: '100%' }}>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: '#260505'
                }}>
                <Tabs
                    TabIndicatorProps={{
                        style: { backgroundColor: '#ffc206' }
                    }}
                    // textColor="secondary"
                    // indicatorColor="secondary"

                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example">
                    <StyledTab
                        label="PUBLICADOS"
                        {...a11yProps(0)}
                        className={classes.customLabelColor}
                    />
                    <StyledTab
                        label="REVISION"
                        {...a11yProps(1)}
                        classes={{
                            textColorSecondary: classes.customLabelColor
                        }}
                    />
                    <StyledTab
                        label="ELIMINADOS"
                        {...a11yProps(2)}
                        classes={{
                            textColorSecondary: classes.customLabelColor
                        }}
                    />
                    <StyledTab
                        label="BORRADORES"
                        {...a11yProps(3)}
                        classes={{
                            textColorSecondary: classes.customLabelColor
                        }}
                    />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {post}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {postBorradores}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {postEliminados}
            </TabPanel>
            <TabPanel value={value} index={3}>
                {postTrash}
            </TabPanel>
        </Box>
    );
}
