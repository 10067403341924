import styled from 'styled-components';

function DragAndDrop({
    handleChange,
    title,
    accept = 'video/*,.mkv',
    isFolder = false
}) {
    const propsIputs = {
        accept,
        multiple: true,
        directory: isFolder ? '' : isFolder,
        webkitdirectory: isFolder ? '' : isFolder
    };
    return (
        <div>
            <StyleDragArea>
                <br />
                <div className="image-upload-wrap">
                    <input
                        className="file-upload-input"
                        type="file"
                        onChange={handleChange}
                        {...propsIputs}
                    />
                    <div className="text-information">
                        <h3>{title}</h3>
                    </div>
                </div>
            </StyleDragArea>
        </div>
    );
}

export default DragAndDrop;

const StyleDragArea = styled.div`
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .file-upload-content {
        display: none;
        text-align: center;
    }

    .file-upload-input {
        position: absolute;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        outline: none;
        opacity: 0;
        cursor: pointer;
    }

    .image-upload-wrap {
        position: relative;
        height: 100px;
        border: 4px solid #d0d7de;
        margin-left: 10px;
        margin-right: 10px;
    }

    .image-upload-wrap:hover {
        background-color: transparent;
        border: 4px dashed #d0d7de;
    }
    .text-information {
        margin-top: 30px;
        text-align: center;
    }
`;
