import axios from 'axios';
import { API_REST_PEDIDOS, API_REST_UNIVERSAL } from '../../../env';

export const getPedidos = async id => {
    try {
        const response = await axios.get(`${API_REST_PEDIDOS}`);
        if (response.status === 200) {
            return response.data;
        } else {
            console.error(`Error en la consulta: ${response.statusText}`);
        }
    } catch (error) {
        console.error('error:', error.response);
        return error.response.data;
    }
};

export const deletePedido = async id => {
    try {
        const response = await axios.delete(`${API_REST_PEDIDOS}`, {
            data: { id }
        });
        return response.data;
    } catch (error) {
        console.error('Error getting notifications', error);
        return [];
    }
};
