import { io } from 'socket.io-client';
import { API_REST_UNIVERSAL_SOCKET } from './env';

// "undefined" means the URL will be computed from the `window.location` object

// const URL_DEVELOPER = `${process.env.REACT_APP_API_URL_DEVELOPER}/report-post`;
const URL_DEVELOPER = API_REST_UNIVERSAL_SOCKET;
const URL_PRODUCCION = process.env.REACT_APP_API_URL;
const URL =
    process.env.REACT_APP_NODE_ENV === 'production'
        ? URL_PRODUCCION
        : URL_DEVELOPER;

export const socket = io(URL);
