import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField
} from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import useLocalStorage from '../../../hooks/useLocalStorage';
import {
    crearAcortador,
    deleteAcortador,
    getAcortadores
} from '../../../utils/apihdlatino';
import { getLocalStorage } from '../../../utils/functions';
import CircularIndeterminate from '../../CircularProgress/CircularIndeterminate';

const objAcortatadoresStatic = {
    ouo: 'https://ouo.io/api/3mwnIjbm?s=',
    fc: 'https://fc.lc/api?api=5fb62b1cbfda63b881a01c16b3971f34877190fb&format=text&url=',
    cpmlink: 'https://cpmlink.net/api/vmYBAA?s=',
    exe: 'https://exe.io/api?api=94b51619aede984544b29ac2e64c4eb5bc1e5d4a&format=text&url=',
    shrinkme:
        'https://shrinkme.io/api?api=be0bfb5d41cd725559af623ec6bf47d8da8897f1&format=text&url='
};

const AddAcortadores = () => {
    const userData = getLocalStorage('user');
    const [myuser, setMyUser] = useLocalStorage('user', userData);
    const [nombreAcortador, setNombreAcortador] = useState();
    const [apiKey, setApyKey] = useState();
    const [arrayAcortadores, setArrayAcortadores] = useState([]);
    const [selectedIdToDelete, setSelectedIdToDelete] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingSelected, setLoadingSelected] = useState(false);
    const [loadingEliminar, setLoadingEliminar] = useState(false);
    const [selectAcortador, setSelectAcortador] = useState();
    const [apiKeySelect, setApiKeySelect] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        if (!nombreAcortador || !apiKey) {
            // alert('Debe agregar un nombre y una api key');
            toast('Debe agregar un nombre y una api key', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
            setLoading(false);
            return;
        }

        const objAcortadorEnviar = {
            userId: myuser?.id,
            nombre_acortador: nombreAcortador,
            token_acortador: apiKey
        };

        try {
            const result = await crearAcortador(objAcortadorEnviar);
            setArrayAcortadores([...arrayAcortadores, result?.data]);
            setNombreAcortador('');
            setApyKey('');
            setMyUser({
                ...myuser,
                acortadores: [...arrayAcortadores, ...result.data]
            });
            setLoading(false);
        } catch (error) {
            console.log('Error al crear acortador', error);
            setLoading(false);
        }
    };

    /**
     * TRAE LOS ACORTADORES POR PRIMERA VEZ
     */
    const handleFetchAcortadores = async () => {
        const result = await getAcortadores(myuser?.username);
        setArrayAcortadores([
            ...arrayAcortadores,
            ...result?.data?.acortadores
        ]);
    };

    /**
     * eliminación de un acortador específico mediante su ID
     */
    const handleDeleteAcortadores = async acortadorId => {
        setLoadingEliminar(true);
        const result = await deleteAcortador(acortadorId);
        if (result?.status === 200) {
            setArrayAcortadores(
                arrayAcortadores.filter(acor => acor.id !== acortadorId)
            );
        }
        setLoadingEliminar(false);
    };

    // Seleccionar valor del select
    const handleChangeSelect = e => {
        setSelectAcortador(e.target.value);
    };

    const handleSubmitSelect = async e => {
        e.preventDefault();
        setLoadingSelected(true);
        if (!selectAcortador || !apiKeySelect) {
            // alert('Debe agregar un nombre y una api key');
            toast('Debe seleccionar un nombre y una api key', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
            setLoadingSelected(false);
            return;
        }

        const obj = {
            ouo: `https://ouo.io/api/${apiKeySelect}?s=`,
            fc: `https://fc.lc/api?api=${apiKeySelect}&format=text&url=`,
            cpmlink: `https://cpmlink.net/api/${apiKeySelect}?s=`,
            exe: `https://exe.io/api?api=${apiKeySelect}&format=text&url=`,
            shrinkme: `https://shrinkme.io/api?api=${apiKeySelect}&format=text&url=`
        };

        const objAcortadorEnviar = {
            userId: myuser?.id,
            nombre_acortador: selectAcortador,
            token_acortador: obj[selectAcortador]
        };

        try {
            const result = await crearAcortador(objAcortadorEnviar);
            setArrayAcortadores([...arrayAcortadores, result?.data]);
            setApiKeySelect('');
            setMyUser({
                ...myuser,
                acortadores: [...arrayAcortadores, ...result.data]
            });
            setLoadingSelected(false);
        } catch (error) {
            console.log('Error al crear acortador', error);
            setLoadingSelected(false);
        }
    };

    useEffect(() => {
        handleFetchAcortadores();
    }, []);

    return (
        <>
            <Stack direction={'column'} alignItems={'start'} spacing={2} mt={2}>
                <Paper
                    elevation={3}
                    sx={{
                        width: '100%',
                        padding: '20px',
                        backgroundColor: '#e9e9e9'
                    }}>
                    <Stack direction="column" mb={2}>
                        <span>Acortadores predeterminados</span>
                    </Stack>
                    <Stack direction={'row'} spacing={2} sx={{ width: '100%' }}>
                        <FormControl sx={{ width: '20%' }}>
                            <InputLabel id="demo-simple-select-label">
                                Acortador
                            </InputLabel>
                            <Select
                                size="small"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectAcortador}
                                label="Acortador"
                                onChange={handleChangeSelect}>
                                {Object.keys(objAcortatadoresStatic).map(a => (
                                    <MenuItem value={a}>{a}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            // fullWidth
                            sx={{ maxWidth: '60%' }}
                            size="small"
                            id="outlined-basic"
                            label="Api key"
                            variant="outlined"
                            value={apiKeySelect}
                            onChange={e => setApiKeySelect(e.target.value)}
                        />
                        <div>
                            <Button
                                // sx={{ width: '100%' }}
                                disabled={loading}
                                variant="contained"
                                size="medium"
                                onClick={handleSubmitSelect}>
                                Guardar &nbsp;
                                {loadingSelected && <CircularIndeterminate />}
                            </Button>
                        </div>
                    </Stack>
                </Paper>
                <Paper
                    elevation={3}
                    sx={{
                        width: '100%',
                        padding: '20px'
                    }}
                    style={{ marginBottom: '16px' }}>
                    <Stack
                        direction={'row'}
                        // justifyContent={'end'}
                        // bgcolor={'red'}
                        spacing={2}
                        // mb={2}
                        sx={{ width: '100%' }}>
                        <TextField
                            size="small"
                            id="outlined-basic"
                            label="Nombre de acortador"
                            variant="outlined"
                            value={nombreAcortador}
                            onChange={e => setNombreAcortador(e.target.value)}
                            // sx={{
                            //     input: { color: '#fff' },
                            //     label: { color: '#fff' }
                            // }}
                        />

                        <TextField
                            fullWidth
                            sx={{ maxWidth: '60%' }}
                            size="small"
                            id="outlined-basic"
                            label="Url base"
                            variant="outlined"
                            value={apiKey}
                            onChange={e => setApyKey(e.target.value)}
                            helperText={
                                <>
                                    Ejemplo:
                                    <span
                                        style={{
                                            color: '#1d0606',
                                            fontWeight: 600
                                        }}>
                                        https://fc.lc/st?api=6fe33f3c2dd94220a014575bafc4fc9f5c9d5527&format=text&url=
                                    </span>
                                </>
                            }
                        />
                        <div>
                            <Button
                                // sx={{ width: '100%' }}
                                disabled={loading}
                                variant="contained"
                                size="medium"
                                onClick={handleSubmit}>
                                Guardar &nbsp;
                                {loading && <CircularIndeterminate />}
                            </Button>
                        </div>
                    </Stack>
                </Paper>

                {arrayAcortadores.map(acortador => (
                    <Stack
                        sx={{ width: '100%' }}
                        key={acortador?.id}
                        direction={'row'}
                        // justifyContent={'end'}
                        spacing={2}
                        // bgcolor={'red'}
                        mt={3}>
                        <TextField
                            size="small"
                            id="outlined-basic"
                            label="Nombre de acortador"
                            variant="outlined"
                            value={acortador?.acor_nombre}
                        />
                        <TextField
                            fullWidth
                            size="small"
                            id="outlined-basic"
                            label="Url base"
                            variant="outlined"
                            value={acortador?.acor_token}
                            sx={{ maxWidth: '60%' }}
                        />
                        <Button
                            disabled={
                                acortador.id === selectedIdToDelete
                                    ? loadingEliminar
                                    : false
                            }
                            variant="contained"
                            size="small"
                            color="error"
                            onClick={() => {
                                setSelectedIdToDelete(acortador?.id);
                                handleDeleteAcortadores(acortador?.id);
                            }}>
                            Eliminar &nbsp;
                            {acortador.id === selectedIdToDelete &&
                                loadingEliminar && (
                                    <CircularIndeterminate color={'info'} />
                                )}
                        </Button>
                    </Stack>
                ))}
            </Stack>
        </>
    );
};

export default AddAcortadores;
