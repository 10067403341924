import React from 'react';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IconButton, Stack } from '@mui/material';
import SearchCoincidence from './SearchCoincidence';
import images from '../../assets/images';

const SearchCoincidenceLayout = ({ resize, onClick }) => {
    return (
        <>
            <Stack
                direction={'row'}
                sx={{
                    position: 'absolute'
                }}>
                <IconButton
                    onClick={onClick}
                    aria-label="search"
                    sx={{
                        width: 50
                    }}>
                    {resize ? (
                        <NavigateNextIcon color="white" />
                    ) : (
                        <img
                            src={images.iconSearch}
                            alt=""
                            title="Buscar post"
                        />
                        // <ScreenSearchDesktopIcon color="vino" />
                    )}
                </IconButton>
            </Stack>
            {resize && (
                <Stack
                    width={'100%'}
                    direction={'row'}
                    justifyContent={'start'}
                    alignItems={'center'}
                    sx={{
                        p: 1
                    }}>
                    <SearchCoincidence />
                </Stack>
            )}
        </>
    );
};

export default SearchCoincidenceLayout;
