import React, { useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import useLocalStorage from '../../hooks/useLocalStorage';
import { updateUser } from '../../utils/apihdlatino';
import { loginPaste } from '../../utils/apipaste';
import { getLocalStorage } from '../../utils/functions';

const FormPaste = () => {
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        username: '',
        password: ''
    });

    // const userData = JSON.parse(localStorage.getItem('user'));
    const userData = getLocalStorage('user');

    const [myuser, setMyUser] = useLocalStorage('user', userData);

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();

        setLoading(true);
        if (form.username.length > 0 && form.password.length > 0) {
            const result = await loginPaste(form.username, form.password);

            if (result.status === 'error') {
                toast.error(result.message);
                setLoading(false);
                return;
            }
            if (result.status === 'success') {
                toast.success('Usuario agregado!');
                setMyUser({
                    ...myuser,
                    ...result.data,
                    id_paste: result.data.userID
                });

                const updateUseData = await updateUser(myuser?.id, {
                    username: myuser?.username,
                    id_paste: result.data.userID,
                    password: ''
                });

                window.location.href = '/config-paste';
                setLoading(false);
                return;
            }
        }
    };
    return (
        <Container style={{ height: '100%' }}>
            <Row className="justify-content-md-center pt-3">
                <Col md={6}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Usuario Multipaste</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Ingresar usuario de Multipaste"
                                name="username"
                                value={form.username}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={form.password}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Button
                            className="bg-dark btn btn-outline-light me-2"
                            type="submit">
                            Guardar
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                hidden={!loading}
                                className="mx-2"
                            />
                        </Button>
                    </Form>
                </Col>
            </Row>
            <Toaster position="top-center" reverseOrder={false} />
        </Container>
    );
};

export default FormPaste;
