import React from 'react';
import { BrowserRouter, Routes as RoutesOne, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { routes } from './Routes';

const AppRouter = () => {
    return (
        <BrowserRouter>
            <RoutesOne>
                {routes.map((route, index) => (
                    <Route
                        path={route.path}
                        element={
                            route.private ? (
                                <PrivateRoute permiso={route.permiso}>
                                    {route.element}
                                </PrivateRoute>
                            ) : (
                                <PublicRoute redirect={route.redirect}>
                                    {route.element}
                                </PublicRoute>
                            )
                        }
                        key={`${route.path}${index}`}
                    />
                ))}
            </RoutesOne>
        </BrowserRouter>
    );
};

export default AppRouter;
