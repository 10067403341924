import axios from 'axios';
import { API_REST_UNIVERSAL } from '../../../env';

export async function getServers() {
    try {
        const response = await axios(`${API_REST_UNIVERSAL}/servers/`);
        return response.data;
    } catch (error) {
        return error;
    }
}
export async function postCreateServer(data) {
    try {
        const response = await axios.post(
            `${API_REST_UNIVERSAL}/servers/`,
            data
        );
        return response.data;
    } catch (error) {
        return error;
    }
}
export async function deleteServer(id) {
    console.log('id', id);
    try {
        const response = await axios.delete(`${API_REST_UNIVERSAL}/servers/`, {
            data: {
                id: id
            }
        });
        return response.data;
    } catch (error) {
        return error;
    }
}
