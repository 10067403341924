import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
    checkTag,
    clearProvidersRepetidos,
    createCategoriesAndTasgs,
    extractBuy,
    extractNotRentAndBuy,
    extractRent,
    extractRentAndBuy,
    getCategoriesWordpress
} from '../../utils/functions';
import Loading from '../Loading/Loading';
import { nanoid } from 'nanoid';

const LogosProviders = ({ providers }) => {
    const [loading, setloading] = useState(false);

    if (!providers) return null;
    const stream = extractRentAndBuy(providers.results);
    const stream2 = extractNotRentAndBuy(providers.results);

    const rent = extractRent(providers.results);
    const buy = extractBuy(providers.results);

    // const clearStream = clearProvidersRepetidos(stream);
    // const clearRent = clearProvidersRepetidos(rent);
    // const clearBuy = clearProvidersRepetidos(buy);

    const objProviders = {
        stream:
            'MX' in stream2
                ? stream2?.MX?.flatrate
                : stream2[Object.keys(stream2)[0]]?.flatrate,
        rent: 'MX' in rent ? rent?.MX?.rent : rent?.US?.rent,
        buy: 'MX' in buy ? buy?.MX?.buy : buy?.US?.buy
    };

    /**
     * FUNCIONALIDAD PARA PROVIDERS DE JUSTWHATCH
     */

    const handleCreateTags = async newTag => {
        setloading(true);
        try {
            const tags = await getCategoriesWordpress('tags');
            const isExist = checkTag(tags, newTag);
            if (!isExist) {
                const result = await createCategoriesAndTasgs(
                    { name: newTag },
                    'tags'
                );

                toast(`Etiqueta ${newTag} creada correctamente`, {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored'
                });
            } else {
                toast(`La etiqueta ${newTag} ya existe`, {
                    position: 'top-center',
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                });
            }
        } catch (error) {
            console.log(
                '🚀 ~ file: LogosProviders.js:82 ~ handleCreateTags ~ error:',
                error.response
            );
            setloading(false);
            toast.error(
                'Error al crear tags: ' + error?.response?.data?.message,
                {
                    position: 'top-center',
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                }
            );
        }
    };

    return (
        <>
            {loading ? <Loading /> : null}

            {Object.keys(providers?.results)?.length > 0 &&
                Object.keys(objProviders).map(
                    sta =>
                        objProviders[sta]?.length > 0 && (
                            <LayoutProviders
                                providersLogos={objProviders[sta]}
                                handleCreateTags={handleCreateTags}
                                title={sta}
                            />
                        )
                )}
        </>
    );
};

const LayoutProviders = ({ providersLogos, handleCreateTags, title }) => {
    return (
        <>
            <Stack direction={'row'} sx={{ borderBottom: '1px solid #b7bfc6' }}>
                <Stack
                    direction={'row'}
                    sx={{
                        background: '#e6e6e6',
                        color: '#797a7b',
                        flexShrink: 0,
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        fontSize: '14px',
                        marginRight: '8px',
                        minWidth: '20px'
                    }}>
                    <Typography
                        component={'span'}
                        sx={{
                            transform: 'scale(-1)',
                            writingMode: 'vertical-lr',
                            fontSize: '0.8rem'
                        }}>
                        {title}
                    </Typography>
                </Stack>
                <Stack
                    flexDirection={'row'}
                    gap={'0.4rem'}
                    sx={{
                        marginTop: '1rem'
                    }}>
                    {providersLogos?.map(pro => (
                        <Box key={nanoid()}>
                            <img
                                onClick={() =>
                                    handleCreateTags(pro?.provider_name)
                                }
                                // src={`https://images.justwatch.com${pro?.icon_url.replace(
                                //     '{profile}',
                                //     's100'
                                // )}`}
                                src={`https://image.tmdb.org/t/p/w342${pro.logo_path}`}
                                alt={pro?.provider_name}
                                title={pro?.provider_name}
                                // style={{ maxWidth: '80%', maxHeight: '80%' }}
                                style={{
                                    maxWidth: 'unset',
                                    width: '50px',
                                    borderRadius: '20%'
                                }}
                            />
                        </Box>
                    ))}
                </Stack>
            </Stack>
        </>
    );
};

export default LogosProviders;
