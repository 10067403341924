import axios from 'axios';
const API_1 = 'https://filmaffinity-unofficial.herokuapp.com';

export const getSearchFilmaFfinity = async q => {
    const { data } = await axios.get(
        // `https://filmaffinity-unofficial.herokuapp.com/api/search?q=${q}&lang=ES`
        // `https://cors-proxy.hdlatino.us/${API_1}/api/search?q=${q}&lang=ES`
        `https://apipost3.hdlatino.us/api/busqueda/${q}`
    );
    return data;
};
