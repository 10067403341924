import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Grid,
    Input,
    Paper,
    Stack,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import useGetCapturas from './hook/useGetCapturas';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { nanoid } from 'nanoid';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    gridList: {
        width: '100%',
        height: 450
    },
    image: {
        // position: 'relative',
        width: '100%',
        height: '100%'
    },
    selected: {
        border: '5px solid blue'
    },
    button: {
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        opacity: 0,
        transition: 'opacity 0.3s',
        zIndex: 1
    },
    buttonVisible: {
        opacity: 1
    }
}));

const ShowCapturas = () => {
    const {
        capturas,
        loadingCapturas,
        loadingUploadCapturas,
        capturasSubidas,
        handleSubirCaptura,
        objValues
    } = useGetCapturas();
    const [selected, setSelected] = useState([]);

    const handleClick = index => {
        if (selected.includes(index)) {
            // Deseleccionar imagen
            setSelected(selected.filter(i => i !== index));
        } else if (selected.length < 5) {
            setSelected([...selected, index]);
        } else {
            alert('Solo se puede seleccionar 5 imágenes');
        }
    };

    const handleClickSubirCapturas = async () => {
        for (let i = 0; i < selected.length; i++) {
            await handleSubirCaptura(
                `https://image.tmdb.org/t/p/original${selected[i]}`,
                `captura${i + 1}`
            );
            toast.success('Apis agregadas!');
        }
        toast('Termino');
    };

    const classes = useStyles();

    if (loadingCapturas) {
        return <div>Cargando..</div>;
    }

    return (
        <>
            {/* <code>{JSON.stringify(capturas)}</code> */}
            <div className={classes.root}>
                {capturas?.images?.backdrops === undefined ||
                capturas?.images?.backdrops.length === 0 ? (
                    <Stack>
                        <Typography variant="h4" component="h2">
                            No hay capturas
                        </Typography>
                        ;
                    </Stack>
                ) : (
                    <>
                        <Stack
                            direction={'column'}
                            spacing={2}
                            sx={{ marginBottom: '1rem' }}
                            textAlign="center">
                            {objValues.map(cap => (
                                <Paper
                                    elevation={2}
                                    sx={{
                                        fontSize: '0.8rem',
                                        height: 40,
                                        textAlign: 'center',
                                        lineHeight: '40px'
                                    }}
                                    key={nanoid()}>
                                    {cap}
                                </Paper>
                            ))}
                            <LoadingButton
                                size="small"
                                onClick={handleClickSubirCapturas}
                                endIcon={<CloudUploadIcon />}
                                loading={loadingUploadCapturas}
                                // loadingPosition="end"
                                variant="contained">
                                <span>Subir imagenes seleccionadas</span>
                            </LoadingButton>
                        </Stack>
                        <Grid container spacing={3}>
                            {capturas?.images?.backdrops.map(img => (
                                <Grid item xs={12} sm={6} md={4} key={nanoid()}>
                                    <img
                                        src={`https://image.tmdb.org/t/p/original${img?.file_path}`}
                                        alt="image1"
                                        className={`${classes.image} ${
                                            selected.includes(img?.file_path)
                                                ? classes.selected
                                                : ''
                                        }`}
                                        onClick={() =>
                                            handleClick(img?.file_path)
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}
            </div>
            <Toaster position="top-center" reverseOrder={false} />
        </>
    );
};

export default ShowCapturas;
