import React, { useState } from 'react';
import { compressToUTF16, decompressFromUTF16 } from 'lz-string';

const useLocalStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = JSON.parse(
                decompressFromUTF16(window.localStorage.getItem(key) || 'ᯢ粠 ')
            );

            return Object.entries(item).length !== 0 ? item : initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    const setValue = value => {
        try {
            setStoredValue(value);
            localStorage.setItem(key, compressToUTF16(JSON.stringify(value)));
        } catch (error) {
            console.error(error);
        }
    };

    return [storedValue, setValue];
};

export default useLocalStorage;
