import axios from 'axios';
const key = [
    'AIzaSyD0a2jgfYteyfElaCS430S1bBrWZ0e4s_s',
    'AIzaSyDxUOZGM0BieMtIWrs7gkTRr2YZ3OGWVE8',
    'AIzaSyBb-M1btO38QmvpqRqDNCrQkk7ng02zKho'
];
export const getSearchYoutube = async q => {
    const { data } = await axios.get(
        `https://www.googleapis.com/youtube/v3/search?maxResults=5&key=${key[0]}&part=snippet&q=${q}&type=video`
    );
    return data;
};
