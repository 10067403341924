import { Store } from 'pullstate';

const SearchStore = new Store({
    search: '',
    loading: false
});

const setSearch = search => {
    SearchStore.update(s => {
        s.search = search;
    });
};
const setLoadingSearch = loading => {
    SearchStore.update(s => {
        s.loading = loading;
    });
};

function useSearch() {
    const search = SearchStore.useState(s => s);

    return [search, setSearch, setLoadingSearch];
}

export { useSearch };
