/**
 *
 * All the images are imported here to export them in the different components where they are needed
 *
 **/

import image870x1315 from './images/878x1315.webp';
import background from './images/background.png';
import logo from './images/HDLATINO-LOGO.png';
import banner from './images/Banner.png';
import buscador from './images/Buscador.png';
import configuracion from './images/Configuracion.png';
import crear_Post from './images/Crear_Post.png';
import perfil from './images/Perfil.png';
import posts_Creados from './images/Posts_Creados.png';
import mis_pastes_todos from './images/MIS_PASTES_TODOS.png';
import mis_post from './images/MIS_POSTS.png';
import agregar_paste from './images/AGREGAR_PASTE.png';
import crear_post1 from './images/Crear_Post1.png';
import crear_paste from './images/Crear_Paste.png';
import mis_pastes from './images/Mis_Pastes.png';
import usuario from './images/Usuario.png';
import worldpress from './images/Worldpress.png';
import wallpaper from './images/wallpaper.jpg';
import header from './images/header.png';
import crear_portada from './images/Crear_Portada.png';
import crear_portada_menu from './images/Crear_Portada_Menu.png';
import profile_telegram from './images/profile.webp';
import telegram_background from './images/telegram-background.webp';
import anroid_telegram_left_carat from './images/anroid-telegram-left-carat.webp';
import Logo_Telegram1 from './images/Logo_Telegram1.png';
import Logo_Telegram2 from './images/Logo_Telegram2.png';
import Logo_Telegram3 from './images/Logo_Telegram3.png';
import users from './images/users.png';
import calendario from './images/Calendario.png';
import usuarios_2 from './images/Usuarios_2.png';
import Correo_imagen from './images/Correo_imagen.png';
import LogoDrive from './images/LogoDrive.png';
import Usuarios from './images/Usuarios.png';
import imdb_logo from './images/imdb_logo.svg';
import JustWatch_logo from './images/JustWatch_logo.webp';
import filmaffinity from './images/logo_filma.jpg';
import cropIcon from './images/crop.png';
import note from './images/note.png';
import home from './images/home.png';
import apartar_post from './images/apartar_post.png';
import iconSearch from './images/icons8-search-in-list.gif';

export default {
    image870x1315,
    background,
    logo,
    banner,
    buscador,
    configuracion,
    crear_Post,
    perfil,
    posts_Creados,
    mis_pastes_todos,
    mis_post,
    agregar_paste,
    crear_post1,
    crear_paste,
    mis_pastes,
    worldpress,
    usuario,
    wallpaper,
    header,
    crear_portada,
    crear_portada_menu,
    profile_telegram,
    telegram_background,
    anroid_telegram_left_carat,
    Logo_Telegram1,
    Logo_Telegram2,
    Logo_Telegram3,
    users,
    calendario,
    usuarios_2,
    Correo_imagen,
    LogoDrive,
    Usuarios,
    imdb_logo,
    JustWatch_logo,
    filmaffinity,
    cropIcon,
    note,
    home,
    apartar_post,
    iconSearch
};
