import axios from 'axios';
import { REACT_APP_API_REST_WP } from '../env';
import { getLocalStorage } from './functions';
import { toast } from 'react-toastify';

// const userData = JSON.parse(localStorage.getItem('user'));
const userData = getLocalStorage('user');
const { id_wordpress: userId } = userData || {};

const headers = {
    'Content-Type': 'application/json',
    Authorization: `Basic ${userData?.keywp}`
};

export const getpostsWordpress = async (
    page = false,
    perPage = false,
    pending = false,
    eliminados = false,
    borrador = false
) => {
    const MyPage = page ? `&page=${page}` : '';
    const MyPerPage = perPage ? `&_embed&per_page=${perPage}` : '';
    const statusPending = pending ? '&status=pending' : '';
    const statusTrash = eliminados ? '&status=trash' : '';
    const statusDraft = borrador ? '&status=draft' : '';
    const url = REACT_APP_API_REST_WP;
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Basic ${userData?.keywp}`,
        'User-Agent':
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36'
    };
    try {
        const data = await axios.get(
            `${url}/posts?author=${userId}${MyPerPage}${MyPage}${statusPending}${statusDraft}${statusTrash}`,
            // `${url}/posts?author=${userId}&_embed&per_page=${perPage}&page=${page}&${statusPending}${statusDraft}${statusTrash}`,
            {
                headers
            }
        );
        return data;
    } catch (error) {
        return error;
    }
};

export const getpostWordpress = async postId => {
    const url = REACT_APP_API_REST_WP;
    try {
        const { data } = await axios.get(`${url}/posts/${postId}`, { headers });
        return data;
    } catch (error) {
        return error;
    }
};
export const getWordpressPostSearch = async query => {
    const url = REACT_APP_API_REST_WP;
    try {
        const { data } = await axios.get(`${url}/posts?search=${query}`, {
            headers
        });
        return data;
    } catch (error) {
        return error.response;
    }
};
export const getWordpressSearch = async query => {
    const url = REACT_APP_API_REST_WP;
    try {
        const { data } = await axios.get(`${url}/search?search=${query}`, {
            headers
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export async function fetchUserWP({ userId }) {
    const apiUrl = `${REACT_APP_API_REST_WP}/users/${userId}`;

    try {
        const response = await axios.get(apiUrl);
        return response.data;
    } catch (error) {
        console.error('Error al obtener los usuarios:', error);
    }
}

export const getCategoriaWordpress = async idCategoria => {
    const url = REACT_APP_API_REST_WP;
    try {
        const { data } = await axios.get(`${url}/categories/${idCategoria}`);
        return data;
    } catch (error) {
        return error;
    }
};
export const postUpdateWordpress = async (postId, values) => {
    const url = REACT_APP_API_REST_WP;
    try {
        const data = await axios.post(`${url}/posts/${postId}`, values, {
            headers
        });
        return data;
    } catch (error) {
        return error;
    }
};
export const deletePostWordpress = async postId => {
    const url = REACT_APP_API_REST_WP;
    try {
        const data = await axios.delete(`${url}/posts/${postId}`, {
            headers
        });
        return data;
    } catch (error) {
        return error;
    }
};

export async function searchEntriesByTitle(searchTerm, perPage = 10) {
    try {
        const apiUrl = `${REACT_APP_API_REST_WP}/posts?author=${userId}`;

        // Construir la URL con los parámetros de búsqueda
        const url = new URL(apiUrl);
        url.searchParams.append('search', searchTerm);
        url.searchParams.append('per_page', perPage);
        url.searchParams.append('_fields', 'id,title,status,tags');
        console.log(url.toString());

        // Realizar la solicitud a la API de WordPress utilizando fetch
        const response = await axios.get(url.toString());
        console.log('response', response);
        if (response.status !== 200) {
            throw new Error('Error en la solicitud: ' + response.statusText);
        }

        // La respuesta contiene las entradas que coinciden con la búsqueda
        return response.data;
    } catch (error) {
        console.error('Error al realizar la solicitud:', error.message);
        throw error;
    }
}

export const fijarEntradaComoPegajosa = async (postId, sticky) => {
    const url = REACT_APP_API_REST_WP;
    try {
        const data = await axios.post(
            `${url}/posts/${postId}`,
            { sticky: !sticky },
            {
                headers
            }
        );
        return data;
    } catch (error) {
        return error;
    }
};

export const postUpdateFecha = async (postId, fecha) => {
    const url = REACT_APP_API_REST_WP;
    try {
        const data = await axios.post(
            `${url}/posts/${postId}`,
            { date: fecha },
            {
                headers
            }
        );
        toast.success('Fecha actualizada correctamente');
        return data;
    } catch (error) {
        toast.error('Error al actualizar la fecha');
        return error;
    }
};
