import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import {
    convertBlobToImageFile,
    getLocalStorage
} from '../../../utils/functions';
import { subirPosterImgur } from '../../../utils/subirimg';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { useDispatch, useStore } from '../../../store/StoreProvider';
import { types } from '../../../store/storeReducer';
import { toast } from 'react-toastify';

// funcion para redondear valores de un objeto
const roundObject = obj => {
    const newObj = {};
    for (const key in obj) {
        newObj[key] = Math.round(obj[key]);
    }
    return newObj;
};

const defaultSrc =
    'https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg';

const useCropper = () => {
    const cropperRef = useRef(null);
    const [imageFile, setImageFile] = useState(defaultSrc);
    const [cropBoxData, setCropBoxData] = useState();
    const [imageCropUrl, setImageCropUrl] = useState([]);
    const [loadingSave, setLoadingSave] = useState(false);
    const [newCropUrl, setNewCropUrl] = useState([]);
    // const [arrayCropsSize, setArrayCropsSize] = useState([]);
    const cropData = getLocalStorage('crop');
    const [arrayCropsSize, setArrayCropsSize] = useLocalStorage(
        'crop',
        cropData ?? []
    );
    const { dataAEnviar } = useStore();
    const dispatch = useDispatch();

    const handleFileChange = e => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImageFile(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const handleChangeCropData = (e, key) => {
        setCropBoxData(prev => ({
            ...cropBoxData,
            [key]: e
        }));
        cropperRef.current?.cropper.setCropBoxData({
            ...cropBoxData,
            [key]: e
        });
    };

    const handleSetBoxData = data => {
        if (cropperRef.current && imageFile) {
            cropperRef.current?.cropper.setCropBoxData(data);
        }
    };

    const handleCrop = () => {
        if (cropperRef.current && imageFile) {
            const cropper = cropperRef.current?.cropper;
            cropper.getCroppedCanvas().toBlob(async blob => {
                const imageUrl = URL.createObjectURL(blob);
                setImageCropUrl([...imageCropUrl, imageUrl]);
                setNewCropUrl([]);
                // setImageCropUrl(imageUrl);

                // const imagen = await convertBlobToImageFile(imageUrl);
                // console.log('imagen', imagen);

                // const data = await subirPosterImgur(imagen);
                // console.log(data);
            });
        }
    };
    const handleSaveCrop = async (imgAll = imageCropUrl) => {
        setNewCropUrl([]);
        let i = 1;
        for (const url of imgAll) {
            const img = await convertBlobToImageFile(url);
            const link = await handleSubirPoster(img);
            handleSetStep2(`captura${i}`, link);
            i++;

            if (link) {
                toast.success(link, {
                    position: 'bottom-left',
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light'
                });
            } else {
                toast.error('Ocurrió un error', {
                    position: 'bottom-left',
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light'
                });
            }
        }
    };

    const onCrop = () => {
        // if (typeof cropperRef.current?.cropper !== "undefined") {
        const data = cropperRef.current?.cropper.getCropBoxData();
        setCropBoxData(roundObject(data));
        // }
    };

    const handleSubirPoster = async imagen => {
        try {
            setLoadingSave(true);
            const data = await subirPosterImgur(imagen);
            setNewCropUrl(pre => [...pre, data?.data?.link]);
            // handleSetStep2('captura1', data?.data?.link);
            setLoadingSave(false);
            return data?.data?.link;
        } catch (error) {
            console.log(error);
            setLoadingSave(false);
            return null;
        }
    };

    const handleCropSaveSize = () => {
        if (cropperRef.current && imageFile && arrayCropsSize) {
            setArrayCropsSize([...arrayCropsSize, cropBoxData]);
        } else {
            setArrayCropsSize([cropBoxData]);
        }
    };

    const handleDeleteCrop = index => {
        setArrayCropsSize(arrayCropsSize.filter((_, i) => i !== index));
    };

    const handleSetStep2 = (numeroCaptura, link) => {
        dispatch({
            type: types.DATAAENVIAR,
            payload: { ...dataAEnviar, ...{ [numeroCaptura]: link } }
        });
    };

    const handleChangeNameCropBoxData = (e, key) => {
        setCropBoxData(prev => ({
            ...cropBoxData,
            name: e.target.value
        }));
    };

    const handleDeleteImageCropUrl = index => {
        setImageCropUrl(imageCropUrl.filter((_, i) => i !== index));
    };

    return {
        imageFile,
        cropperRef,
        cropBoxData,
        imageCropUrl,
        loadingSave,
        newCropUrl,
        arrayCropsSize,
        setImageCropUrl,
        handleFileChange,
        handleChangeCropData,
        handleSetBoxData,
        handleCrop,
        onCrop,
        handleSaveCrop,
        handleCropSaveSize,
        handleDeleteCrop,
        handleSetStep2,
        handleChangeNameCropBoxData,
        handleDeleteImageCropUrl
    };
};

export default useCropper;
