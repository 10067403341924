import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography, Skeleton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { useSearchFicharPost } from '../store/store';
import { makeStyles } from '@mui/styles';
import { renderUrl } from '../../../utils/functions';

const useStyles = makeStyles(theme => ({
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0,
        transition: 'opacity 0.3s ease'
    },
    card: {
        position: 'relative',
        '&:hover $overlay': {
            opacity: 1
        }
    },
    clickOption: {
        backgroundColor: '#ffffff',
        padding: '10px 20px',
        border: 'none',
        borderRadius: 5,
        cursor: 'pointer',
        fontSize: 14,
        fontWeight: 'bold',
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: '#f0f0f0'
        }
    }
}));

const theme = createTheme();

const GridContainer = () => {
    const { result, loading, setDetails } = useSearchFicharPost();
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const classes = useStyles();

    const handleMouseEnter = index => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    // Componente Skeleton
    const SkeletonCard = () => (
        <Card sx={{ maxWidth: 200 }}>
            <Skeleton variant="rectangular" width={200} height={200} />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    <Skeleton />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <Skeleton />
                </Typography>
            </CardContent>
        </Card>
    );

    const releaseDate = item => {
        if (item.release_date) {
            return item.release_date.slice(0, 4);
        } else if (item.first_air_date) {
            return item.first_air_date.slice(0, 4);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container spacing={3}>
                {/* Mostrar el Skeleton mientras se cargan los datos */}
                {loading ? (
                    <>
                        {[...Array(8)].map((_, index) => (
                            <Grid
                                item
                                key={index}
                                xs={12}
                                sm={6}
                                md={4}
                                lg={1.5}>
                                <SkeletonCard />
                            </Grid>
                        ))}
                    </>
                ) : (
                    // Mostrar los datos una vez cargados
                    result?.map((item, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4} lg={1.5}>
                            <Card
                                sx={{ maxWidth: 200, cursor: 'pointer' }}
                                className={classes.card}>
                                <div
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={() => setDetails(item)}>
                                    <ImageListItem key={item.img}>
                                        <img
                                            srcSet={renderUrl(item.poster_path)}
                                            src={renderUrl(item.poster_path)}
                                            alt={item.title || item.name}
                                            loading="lazy"
                                            title={item.title || item.name}
                                        />
                                        {hoveredIndex === index && (
                                            <div className={classes.overlay}>
                                                <button
                                                    className={
                                                        classes.clickOption
                                                    }
                                                    onClick={() =>
                                                        setDetails(item)
                                                    }>
                                                    Seleccionar
                                                </button>
                                            </div>
                                        )}
                                        <ImageListItemBar
                                            title={item.title || item.name}
                                            subtitle={
                                                <span>{releaseDate(item)}</span>
                                            }
                                            position="below"
                                            sx={{ margin: '0 0 0 5px' }}
                                        />
                                    </ImageListItem>
                                </div>
                            </Card>
                        </Grid>
                    ))
                )}
            </Grid>
        </ThemeProvider>
    );
};

export default GridContainer;
