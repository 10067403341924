import React, { useEffect, useState } from 'react';
import { Col, Row, Tab } from 'react-bootstrap';
import images from '../../assets/images';
import { useDispatch, useStore } from '../../store/StoreProvider';
import { types } from '../../store/storeReducer';
import {
    crearArrayValuesColeccion,
    crearArrayValuesSeasons,
    definirTrailer,
    getFirstYouTubeVideoLink,
    handleDirectors,
    handleRuntime,
    obtenerYearColecciones
} from '../../utils/functions';
import { getMovieIdJust } from '../../utils/justWhatch';
import { getMovies } from '../../utils/themvoies';
import { getTraduction } from '../../utils/traductor';
import Loading from '../Loading/Loading';
import TabsSearch from '../TabsSearch/TabsSearch';
import TitleIcons from '../titleIcons/TitleIcons';
import CardMovie from './CardMovie';
import styles from './listmovies.module.css';
import {
    getMovieFilmaffinityOficial,
    getSearchFilmaffinityOficial
} from '../../utils/filmaffinityOficial';
import { useSearch } from './store';
import { Stack, TextField, Typography } from '@mui/material';
import { useSearchFicharPost } from '../FicharPost/store/store';

const TYPE_SEARCH = {
    1: 'movie',
    0: 'tv',
    2: 'collection'
};
const ListMovies = ({ isPoster = false, isFilmaffinity = false }) => {
    const keyTab = isFilmaffinity ? 'peliculas_filmaffinity' : 'peliculas_imdb';
    const {
        search: movieData,
        search,
        pelisJustWhatchSearch,
        pelisJustWhatchID,
        pelisFilmaffinitySearch,
        movie
    } = useStore();
    const [searchStore, setSearch, setLoadingSearch] = useSearch();
    const [activeTab, setActiveTab] = useState(keyTab);
    const [loading, setloading] = useState(false);
    const dispatch = useDispatch();
    const { setShowButtonFilmaffinity } = useSearchFicharPost();

    const handleMovie = async (tmdb, just = false) => {
        setloading(true);

        if (!just) {
            dispatch({ type: types.LOADING, payload: true });
            dispatch({ type: types.TMDB, payload: tmdb });
            const datam = await getMovies({
                tmdb,
                movie: null,
                typeSearch: movieData.typeSearch,
                idioma: search?.idioma
            });

            if (movieData.typeSearch === '0') {
                datam.seasons = crearArrayValuesSeasons(datam);
            }

            let datamMovie = null;
            if (movieData.typeSearch === '2') {
                datamMovie = await getMovies({
                    tmdb: datam?.parts?.[0]?.id,
                    movie: null,
                    typeSearch: '1',
                    idioma: search?.idioma
                });
                datam.production_countries = datamMovie.production_countries;
                datam.credits = datamMovie.credits;
                datam.runtime = datamMovie.runtime;
                datam.parts = crearArrayValuesColeccion(datam);
            }

            // console.log('datam', datam);
            const isMovie = movieData.typeSearch === '0' ? 'tv' : 'movie';
            let dataJust = null;
            if (movieData.typeSearch === '2') {
                dataJust = await getMovieIdJust(datam?.parts?.[0]?.id, isMovie);
            } else {
                dataJust = await getMovieIdJust(tmdb, isMovie);
            }

            dispatch({ type: types.PELIS_JUSTWHATCH_ID, payload: dataJust });

            // if (movie !== null) {
            //     dispatch({
            //         type: types.STEPS,
            //         payload: { step1: true }
            //     });
            //     !isPoster && dispatch({ type: types.VIEW, payload: 1 });
            // }
            if (!datam.overview && movieData.typeSearch !== '2') {
                const datam2 = await getMovies({
                    tmdb,
                    movie: null,
                    typeSearch: movieData.typeSearch,
                    idioma: 'en-US'
                });
                const description = await getTraduction(datam2?.overview);
                description?.text && (datam.overview = description.text);
                description?.text && (datam.traducido = true);
            }
            const misDirectores = handleDirectors(datam);
            const trailer1 = definirTrailer(datam) ?? null;
            const runtime = handleRuntime(datam) ?? null;
            const year =
                movieData.typeSearch === '2'
                    ? obtenerYearColecciones(datam)
                    : datam?.release_date?.slice(0, -6) ||
                      datam?.first_air_date?.slice(0, -6) ||
                      null;
            const paisOrigen = datam?.production_countries?.[0]?.name || null;
            const generos =
                datam?.genres?.length > 0
                    ? datam?.genres?.map(ge => ' ' + ge.name)
                    : null;
            const director = misDirectores?.join(',') || null;
            const imdb = datam?.external_ids?.imdb_id || null;
            const trailer = trailer1?.[0]?.url || null;
            const description = datam?.overview || null;
            const original_title = datam?.original_title || datam?.name;

            const poster_path = datam?.poster_path
                ? `https://www.themoviedb.org/t/p/original${datam?.poster_path}`
                : null;
            const poster_path_respaldo = datam?.poster_path
                ? `https://www.themoviedb.org/t/p/original${datam?.poster_path}`
                : null;

            const dataMovie = {
                ...datam,
                original_title,
                poster_path: poster_path,
                poster_path_respaldo: poster_path_respaldo,
                misDirectores,
                trailer,
                runtime,
                year,
                paisOrigen,
                generos,
                director,
                imdb,
                description
            };

            dispatch({
                type: types.MOVIE,
                payload: {
                    ...dataMovie,
                    typeSearch: movieData.typeSearch,
                    myidioma: movieData.myidioma
                }
            });
            // verificamos si justwhatch tiene resultados para evitar cambiar de vista
            dispatch({ type: types.LOADING, payload: false });
            if (
                pelisJustWhatchSearch?.items.length === 0 ||
                pelisJustWhatchSearch?.items === undefined ||
                pelisJustWhatchID !== null
            ) {
                dispatch({
                    type: types.STEPS,
                    payload: { step1: true }
                });
                !isPoster && dispatch({ type: types.VIEW, payload: 1 });
            }
        }
        setActiveTab(a =>
            a === 'peliculas_imdb' ? 'peliculas_filmaffinity' : 'peliculas_imdb'
        );
        setloading(false);
    };

    const handleMovieFilmaffinity = async url => {
        setloading(true);
        // setActiveTab(a =>
        //     a === 'peliculas_filmaffinity'
        //         ? 'peliculas_justWhtach'
        //         : 'peliculas_imdb'
        // );
        const data = await getMovieFilmaffinityOficial(url);

        const {
            original_title,
            poster_path,
            poster_path_respaldo,
            misDirectores,
            trailer,
            runtime,
            year,
            paisOrigen,
            generos,
            director,
            description
        } = movie;

        const updatedMovie = {
            original_title: original_title ?? data?.title,
            poster_path: poster_path ?? data?.largeImageUrl,
            poster_path_respaldo: poster_path_respaldo ?? data?.largeImageUrl,
            misDirectores: misDirectores ?? [data?.director],
            // trailer: trailer ?? data?.trailers[0]?.iframeSrc,
            trailer: getFirstYouTubeVideoLink(data?.trailers) ?? '',
            runtime: runtime ?? data?.duration,
            year: year ?? data?.year,
            paisOrigen: paisOrigen ?? data?.country,
            generos: generos ?? data?.genres,
            director: director ?? data?.director,
            description: description ?? data?.synopsis,
            countryImageUrl: data?.countryImageUrl
        };

        // Reasignar los valores actualizados al objeto 'movie'
        // Object.assign(movie, updatedMovie);

        dispatch({
            type: types.MOVIE,
            payload: { ...movie, ...updatedMovie, filmaffinity: data }
        });
        setShowButtonFilmaffinity(false);
        setloading(false);
    };

    const handleSearchFilmaffinity = async () => {
        try {
            setLoadingSearch(true);
            const resultPelisFilmaffinity = await getSearchFilmaffinityOficial(
                searchStore.search
            );
            dispatch({
                type: types.PELIS_FILMAFFINITY_SEARCH,
                payload: resultPelisFilmaffinity
            });
            setLoadingSearch(false);
        } catch (error) {
            console.log(error);
            setLoadingSearch(false);
        }
    };

    useEffect(() => {
        // crea una funcion debounced que espera 500ms antes de ejecutar la handleSearchFilmaffinity()
        const debounced = setTimeout(() => {
            handleSearchFilmaffinity();
        }, 500);

        // limpiar el timeout si se ejecuta antes de los 500ms
        return () => clearTimeout(debounced);
    }, [searchStore?.search]);

    if (movieData?.results?.length === 0) {
        return <div>No se encontró lo que buscaba</div>;
    }

    return (
        <>
            {loading ? <Loading /> : null}
            <div className={styles.container__list}>
                <ul>
                    <TabsSearch
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}>
                        {/* Lista de peliculas para IMDB */}
                        <Tab
                            eventKey="peliculas_imdb"
                            title={<TitleIcons img={images.imdb_logo} />}>
                            <Row>
                                {movieData &&
                                    movieData?.results?.map(movie => (
                                        <Col xs={6} md={3} key={movie.id}>
                                            <a
                                                key={movie.id}
                                                href="#!"
                                                onClick={() =>
                                                    handleMovie(movie.id)
                                                }>
                                                <CardMovie
                                                    title={
                                                        movie.title ||
                                                        movie.original_name
                                                    }
                                                    tmdb={movie.id}
                                                    img={`https://www.themoviedb.org/t/p/w342/${movie.poster_path}`}
                                                    year={
                                                        movie?.release_date?.slice(
                                                            0,
                                                            -6
                                                        ) ||
                                                        movie?.first_air_date?.slice(
                                                            0,
                                                            -6
                                                        )
                                                    }
                                                    onClick={handleMovie}
                                                />
                                            </a>
                                        </Col>
                                    ))}
                            </Row>
                        </Tab>
                        {/* Lista de peliculas para Filmaffinity */}
                        <Tab
                            eventKey="peliculas_filmaffinity"
                            title={<TitleIcons img={images.filmaffinity} />}>
                            <Row>
                                <Stack alignItems={'center'} mb={1}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Buscar en Filmaffinity"
                                        variant="outlined"
                                        size="small"
                                        value={searchStore?.search}
                                        onChange={e =>
                                            setSearch(e.target.value)
                                        }
                                    />
                                    <Typography variant="caption" mt={1}>
                                        {' '}
                                        <b>
                                            {movie?.original_title
                                                ? `Titulo Original: ${movie?.original_title}`
                                                : ''}
                                        </b>
                                    </Typography>
                                </Stack>
                                {searchStore.loading ? <Loading /> : null}
                                {pelisFilmaffinitySearch?.length > 0 ? (
                                    pelisFilmaffinitySearch?.map(
                                        (movie, index) => {
                                            return (
                                                <Col
                                                    xs={6}
                                                    md={3}
                                                    key={movie.id}>
                                                    <a
                                                        key={index}
                                                        href="#!"
                                                        onClick={() =>
                                                            handleMovieFilmaffinity(
                                                                movie.url
                                                            )
                                                        }>
                                                        <CardMovie
                                                            title={
                                                                movie?.title ??
                                                                'No title'
                                                            }
                                                            tmdb={
                                                                movie?.countryImageUrl
                                                            }
                                                            img={
                                                                movie?.imageUrl
                                                            }
                                                            year=""
                                                            onClick={() =>
                                                                handleMovieFilmaffinity(
                                                                    movie.url
                                                                )
                                                            }
                                                        />
                                                    </a>
                                                </Col>
                                            );
                                        }
                                    )
                                ) : (
                                    <Typography>No hay resultados</Typography>
                                )}
                            </Row>
                        </Tab>
                        {/* Lista de peliculas para JustWhatch */}
                        {/* <Tab
                            eventKey="peliculas_justWhtach"
                            title={<TitleIcons img={images.JustWatch_logo} />}>
                            <Row>
                                {pelisJustWhatchSearch &&
                                    pelisJustWhatchSearch?.items?.map(movie => {
                                        return (
                                            <Col xs={6} md={3} key={movie.id}>
                                                <a
                                                    key={movie.id}
                                                    href="#!"
                                                    onClick={() =>
                                                        handleMovie(
                                                            movie.id,
                                                            movie.object_type
                                                        )
                                                    }>
                                                    <CardMovie
                                                        title={
                                                            movie?.title ??
                                                            'No title'
                                                        }
                                                        tmdb={movie.id}
                                                        img={`https://images.justwatch.com${movie.poster?.replace(
                                                            '{profile}',
                                                            's592'
                                                        )}`}
                                                        year={
                                                            movie?.original_release_year
                                                        }
                                                        onClick={() =>
                                                            handleMovie(
                                                                movie.id,
                                                                movie.object_type
                                                            )
                                                        }
                                                    />
                                                </a>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </Tab> */}
                    </TabsSearch>
                </ul>
            </div>
        </>
    );
};

export default ListMovies;
