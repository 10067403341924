import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { nanoid } from 'nanoid';
import { styled } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        width: 0
                    }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell
                    component="th"
                    scope="row"
                    padding="none"
                    align="left">
                    {row['@type']}
                </TableCell>
                <TableCell align="right">{row.name}</TableCell>
                {/* <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell> */}
            </TableRow>
            <StyledTableRow>
                <StyledTableCell
                    style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }}
                    colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {/* <Box> */}
                        {/* <Typography
                                variant="h6"
                                gutterBottom
                                component="div">
                                History
                            </Typography> */}
                        <Table size="small" aria-label="purchases">
                            {/* <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell align="right">
                                            Amount
                                        </TableCell>
                                        <TableCell align="right">
                                            Total price ($)
                                        </TableCell>
                                    </TableRow>
                                </TableHead> */}
                            <TableBody>
                                {Object.keys(row).map(rowKey => (
                                    <StyledTableRow key={nanoid()}>
                                        <StyledTableCell
                                            component="th"
                                            scope="row">
                                            {rowKey}
                                        </StyledTableCell>
                                        {/* <TableCell>:</TableCell> */}
                                        <StyledTableCell align="left">
                                            {typeof row[rowKey] === 'object'
                                                ? JSON.stringify(row[rowKey])
                                                : row[rowKey]}
                                        </StyledTableCell>
                                        {/* <TableCell align="right">
                                                {historyRow.amount}
                                            </TableCell>
                                            <TableCell align="right">
                                                {Math.round(
                                                    historyRow.amount *
                                                        row.price *
                                                        100
                                                ) / 100}
                                            </TableCell> */}
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {/* </Box> */}
                    </Collapse>
                </StyledTableCell>
            </StyledTableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired
            })
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired
    }).isRequired
};

export default function CollapsibleTable({ mediaInfo }) {
    return (
        <TableContainer component={Paper}>
            <Table
                aria-label="collapsible table"
                size="small"
                sx={{ backgroundColor: '#c8d1d9' }}>
                {/* <TableHead>
                    <TableRow
                        style={{
                            height: 33
                        }}>
                        <TableCell />
                        <TableCell>Dessert (100g serving)</TableCell>
                        <TableCell align="right">Calories</TableCell>
                        <TableCell align="right">Fat&nbsp;(g)</TableCell>
                        <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                        <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    </TableRow>
                </TableHead> */}
                <TableBody>
                    {mediaInfo.map(row => (
                        <Row key={nanoid()} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
