import axios from 'axios';
import { API_REST_HD } from '../env';

export const consultarUser = async username => {
    try {
        return await axios.get(`${API_REST_HD}${username}`);
    } catch (error) {
        return error;
    }
};

export const createUser = async values => {
    try {
        return await axios.post(`${API_REST_HD}`, values);
    } catch (error) {
        return error;
    }
};

export const updateUser = async (userId, values) => {
    try {
        return await axios.put(`${API_REST_HD}${userId}`, values);
    } catch (error) {
        return error;
    }
};


export const crearAcortador = async data => {
    try {
        return await axios.post(`${API_REST_HD}acortador`, data);
    } catch (error) {
        return error;
    }
};

export const getAcortadores = async username => {
    try {
        return await axios.get(`${API_REST_HD}acortador/${username}`);
    } catch (error) {
        return error;
    }
};

// export const deleteAcortador = async acortadorId => {
//     try {
//         return await axios.delete(`${API_REST_HD}acortador`, {
//             id: acortadorId
//         });
//     } catch (error) {
//         return error;
//     }
// };
export const deleteAcortador = async acortadorId => {
    console.log('acortadorId', acortadorId);
    try {
        const bodyContent = {
            id: acortadorId
        };
        return await axios({
            url: `${API_REST_HD}acortador`,
            method: 'DELETE',
            data: bodyContent
        });
    } catch (error) {
        return error;
    }
};
