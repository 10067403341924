import React, { useEffect, useState } from 'react';
import { useDispatch, useStore } from '../../../store/StoreProvider';
import { types } from '../../../store/storeReducer';
import { bytesToGB, obtenerTamanoAproximado } from '../../../utils/functions';

const useColecciones = () => {
    const [sizeAll, setSizeAll] = useState(0);
    const [totalSize, setTotalSize] = useState('');
    const [bitesAll, setBitesAll] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);

    const { movie, dataAEnviar } = useStore();
    const dispatch = useDispatch();

    const handleInputChange = (index, newValue) => {
        // Crear una copia del estado actual de movie para no mutar el estado directamente
        const updatedMovie = { ...movie };

        // Actualizar el valor del input específico en el array de parts
        updatedMovie.parts[index].title_fecha = newValue;

        // Actualizar el estado utilizando la función setMovie
        dispatch({
            type: types.MOVIE,
            payload: {
                ...updatedMovie
            }
        });
    };

    const handleChangeSize = (index, e) => {
        const files = e.target.files;
        let sizeBites = 0;
        for (let i = 0; i < files?.length; i++) {
            // const size = bytesToGB(files[i].size);
            sizeBites += files[i].size;
        }
        setTotalFiles(prev => prev + files?.length);
        setBitesAll(prev => prev + sizeBites);
        const size1 = bytesToGB(sizeBites);
        const size = `Peso: ${size1} GB`;
        const sizeBytesToGB = bytesToGB(bitesAll + sizeBites, 0);
        const sizeAproximadoPorCapitulo = obtenerTamanoAproximado(
            sizeBytesToGB,
            totalFiles + files?.length,
            'Película'
        );

        setSizeAll(prev => bytesToGB(bitesAll + sizeBites));
        setTotalSize(
            () =>
                `${sizeAproximadoPorCapitulo} (Total: ${bytesToGB(
                    bitesAll + sizeBites
                )} GB)`
        );

        // Crear una copia del estado actual de movie para no mutar el estado directamente
        const updatedMovie = { ...movie };

        // Actualizar el valor del input específico en el array de parts
        const newValue = `${updatedMovie.parts[index].title_fecha} ${size}`;

        handleInputChange(index, newValue);

        // dispatch({
        //     type: types.DATAAENVIAR,
        //     payload: {
        //         ...dataAEnviar,
        //         PesoArchivo: size
        //     }
        // });
    };

    useEffect(() => {
        dispatch({
            type: types.DATAAENVIAR,
            payload: {
                ...dataAEnviar,
                PesoArchivo: totalSize
            }
        });
    }, [totalSize]);

    return {
        handleInputChange,
        handleChangeSize,
        setTotalSize,
        movie,
        sizeAll,
        totalSize
    };
};

export default useColecciones;
