import React, { useEffect, useState } from 'react';
import { cuentaRegresiva } from '../../../utils/functions';
import { Chip } from '@mui/material';

const CuentaRegresiva = ({ row }) => {
    const [cuenta, setCuenta] = useState(null);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const cuentaActualizada = cuentaRegresiva(row.apar_expiration_date);
            setCuenta(cuentaActualizada);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [row.apar_expiration_date]);

    if (cuenta === null) {
        return <div>0</div>;
    }

    return (
        <Chip label={`${cuenta.horas}:${cuenta.minutos}:${cuenta.segundos}`} />
    );
};

export default CuentaRegresiva;
