import React, { useState } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { VscSettingsGear } from 'react-icons/vsc';
import { CgProfile } from 'react-icons/cg';
import { MdEditNote, MdImageSearch, MdPostAdd } from 'react-icons/md';
import {
    ProSidebar,
    SidebarContent,
    SidebarFooter,
    Menu,
    MenuItem,
    SubMenu
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import './sidebar.css';
import { useStore } from '../../store/StoreProvider';
import { Link } from 'react-router-dom';
import SearchGoogleImg from '../SearchGoogleImg/SearchGoogleImg';
import images from '../../assets/images';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { getLocalStorage } from '../../utils/functions';

const Sidebar = () => {
    const { buttonHamburguesa } = useStore();
    const userData = getLocalStorage('user');
    const [modalShow, setModalShow] = useState(false);

    return (
        <ProSidebar
            collapsed={buttonHamburguesa}
            className="bg-vino"
            style={{ position: 'absolute' }}>
            <SidebarContent>
                <Menu iconShape="circle">
                    <MenuItem
                        // active={true}
                        // icon={<CgProfile style={{ height: '100%' }} />}>
                        icon={<img src={images.home} alt="perfil" />}>
                        Dashboard <Link to="/dashboard" />
                    </MenuItem>
                    <MenuItem
                        // active={true}
                        // icon={<CgProfile style={{ height: '100%' }} />}>
                        icon={<img src={images.perfil} alt="perfil" />}>
                        Perfil <Link to="/perfil" />
                    </MenuItem>
                    <MenuItem
                        icon={<img src={images.crear_Post} alt="Crear Post" />}>
                        Crear Post <Link to="/" />
                    </MenuItem>

                    <MenuItem
                        icon={
                            <img
                                src={images.posts_Creados}
                                alt="Post Creados"
                            />
                        }>
                        Posts Creados <Link to="/list-post" />
                    </MenuItem>
                    <MenuItem
                        icon={
                            <img src={images.Logo_Telegram1} alt="Telegram" />
                        }>
                        Telegram <Link to="/telegram" />
                    </MenuItem>
                    {userData.roles === 'admin' ? (
                        <MenuItem
                            icon={
                                <img
                                    src={images.Usuarios}
                                    alt="Usuarios Multipaste"
                                />
                            }>
                            Usuarios Multipaste <Link to="/users-paste" />
                        </MenuItem>
                    ) : (
                        <></>
                    )}

                    <MenuItem
                        icon={
                            <img
                                src={images.buscador}
                                alt="Buscador de imagenes"
                            />
                        }>
                        Search Img{' '}
                        <Link to="" onClick={() => setModalShow(!modalShow)} />
                        <SearchGoogleImg
                            show={modalShow}
                            setModalShow={setModalShow}
                        />
                    </MenuItem>
                    <MenuItem icon={<img src={images.note} alt="Notas" />}>
                        Notas <Link to="/notas" />
                    </MenuItem>

                    <SubMenu
                        title="Configuración"
                        icon={
                            <img
                                src={images.configuracion}
                                alt="Configuracion"
                            />
                        }>
                        <MenuItem>
                            Multipaste <Link to="/config-paste" />
                        </MenuItem>
                        <MenuItem>
                            Acortadores <Link to="/acortadores" />
                        </MenuItem>
                    </SubMenu>
                </Menu>
            </SidebarContent>
            <SidebarFooter>
                <Menu iconShape="square">
                    <MenuItem icon={<FiLogOut />}>Logout</MenuItem>
                </Menu>
            </SidebarFooter>
        </ProSidebar>
    );
};

export default Sidebar;
