import { Formik, Form as MyForm } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';

import toast, { Toaster } from 'react-hot-toast';
import { FaRedoAlt } from 'react-icons/fa';
import styles from '../../assets/css/step3.module.css';
import Button from '../../components/button/Button';
import { validationSchema3 } from '../../config/validation-squema';
import { URL_PASTE } from '../../constants';
import { useDispatch, useStore } from '../../store/StoreProvider';
import { types } from '../../store/storeReducer';
import { getPastes } from '../../utils/apipaste';
import {
    arrayObjectsToSeasons,
    arrayObjectsToString,
    crearNombrePaste,
    createArrayServersToStep3,
    obtenerEpisodeNumberString,
    obtenerSeasonNumber,
    obtenerStringSeasonNumber,
    ordenarPorPasteID
} from '../../utils/functions';
import { useServerStore } from '../../components/paste/store/ServersStroe';
import BasicModal from '../../components/MyModal/BasicModal';
import Paste from '../../components/paste/Paste';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddIcon from '@mui/icons-material/Add';
import { min } from 'moment/moment';

const Step3 = () => {
    const {
        view,
        dataAEnviar,
        search: { typeSearch },
        movie,
        stateCalidadesSelected
    } = useStore();
    const dispatch = useDispatch();
    const [pastes, setPastes] = useState([]);
    const [tituloPaste, setTituloPaste] = useState('');
    const [data] = useServerStore();
    const servidores = createArrayServersToStep3(data);
    const nombrePasteACrear = crearNombrePaste(dataAEnviar);
    // const servidores = [
    //     { id: 'google_drive', servidor: 'Google Drive' },
    //     { id: 'mega', servidor: 'Mega' },
    //     { id: 'uptobox', servidor: 'Uptobox' },
    //     { id: 'mediafire', servidor: 'Mediafire' },
    //     { id: '1fichier', servidor: '1fichier' },
    //     { id: 'sync', servidor: 'Sync' },
    //     { id: 'terabox', servidor: 'Terabox' }
    // ];
    const {
        misServidores,
        aporteExclusivo,
        uploader,
        masInfo,
        creditos,
        enlaceFree,
        enlaceVip,
        showMediaInfo,
        temporada,
        capitulo
    } = dataAEnviar || {};

    const handleEnlacesPastes = async (
        setFieldValue,
        setTituloPaste,
        vipOrFree = null
    ) => {
        const linksPastes = await getPastes();
        const orderPastes = ordenarPorPasteID(linksPastes.data);
        if (linksPastes?.status === 'success') {
            setPastes(orderPastes.reverse());
            if (vipOrFree === '1') {
                setFieldValue(
                    'enlaceVip',
                    `${URL_PASTE}${orderPastes[0]?.url}`
                );
                setTituloPaste(orderPastes[0]?.Titulo);
            } else {
                setFieldValue(
                    'enlaceFree',
                    `${URL_PASTE}${orderPastes[0]?.url}`
                );
                setTituloPaste(orderPastes[0]?.Titulo);
            }
        }
    };

    const handleSubmitStep3 = values => {
        const sumar = view + 1;
        dispatch({
            type: types.DATAAENVIAR,
            payload: { ...values, tituloPaste }
        });
        dispatch({ type: types.VIEW, payload: sumar });
    };

    useEffect(() => {
        handleEnlacesPastes();
    }, []);

    return (
        <div className={styles.container__step3}>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    misServidores: misServidores || [],
                    aporteExclusivo: aporteExclusivo,
                    uploader: uploader,
                    masInfo:
                        masInfo || movie?.typeSearch === '0'
                            ? arrayObjectsToSeasons(movie)
                            : arrayObjectsToString(movie?.parts),
                    showMediaInfo: showMediaInfo,
                    creditos: creditos,
                    enlaceFree: enlaceFree,
                    enlaceVip: enlaceVip,
                    temporada:
                        temporada ||
                        obtenerStringSeasonNumber(movie?.seasons_info),
                    capitulo:
                        capitulo ||
                        obtenerEpisodeNumberString(movie?.seasons_info)
                }}
                validationSchema={validationSchema3}
                onSubmit={values => handleSubmitStep3(values)}>
                {({
                    values,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    touched,
                    errors
                }) => (
                    <MyForm id={`CreateForm${view}`}>
                        <Row>
                            <Col md={2}>
                                <p>Servidores</p>
                            </Col>
                            <Col sm={10} className="my-auto">
                                {servidores.map(ser => (
                                    <Form.Check
                                        key={ser.id}
                                        type="checkbox"
                                        id={`servidores-${ser.id}`}
                                        label={ser.servidor}
                                        size="sm"
                                        inline
                                        name="misServidores"
                                        value={ser.id}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        checked={values.misServidores.includes(
                                            ser.id
                                        )}
                                    />
                                ))}
                                {touched.misServidores &&
                                errors.misServidores ? (
                                    <div className="error-message">
                                        {errors.misServidores}
                                    </div>
                                ) : null}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="aporteExclusivo">
                                    Exclusivo
                                </label>
                            </Col>
                            <Col sm={10} className="my-auto">
                                <Form.Check
                                    type="checkbox"
                                    id="aporteExclusivo"
                                    label="Aporte Exclusivo de Break"
                                    size="sm"
                                    inline
                                    value="aporteExclusivoBreak"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label htmlFor="step3uploader">Uploader</label>
                            </Col>
                            <Col sm={10} className="my-auto">
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder={
                                            touched.uploader && errors.uploader
                                                ? 'El campo es obligatorio'
                                                : 'Break'
                                        }
                                        className={
                                            touched.uploader &&
                                            errors.uploader &&
                                            'error'
                                        }
                                        size="sm"
                                        id="step3uploader"
                                        name="uploader"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.uploader}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label htmlFor="step3creditos">Créditos</label>
                            </Col>
                            <Col sm={10} className="my-auto">
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Créditos"
                                        size="sm"
                                        id="step3creditos"
                                        name="creditos"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.creditos}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label htmlFor="step3masinformacion">
                                    Más Información
                                </label>
                            </Col>
                            <Col sm={10} className="my-auto">
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        as="textarea"
                                        rows={2}
                                        type="text"
                                        placeholder="Más Información"
                                        size="sm"
                                        id="step3masinformacion"
                                        name="masInfo"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.masInfo}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label htmlFor="step3showMediaInfo">
                                    Media Info
                                </label>
                            </Col>
                            <Col sm={10} className="my-auto">
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        as="textarea"
                                        rows={2}
                                        type="text"
                                        placeholder="Mostrar media info"
                                        size="sm"
                                        id="step3showMediaInfo"
                                        name="showMediaInfo"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.showMediaInfo}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label htmlFor="step3enlacefree">
                                    Enlace Free
                                </label>
                            </Col>
                            <Col sm={10} className="my-auto">
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder={
                                            touched.enlaceFree &&
                                            errors.enlaceFree
                                                ? 'El campo es obligatorio'
                                                : 'Enlace Free'
                                        }
                                        className={
                                            touched.enlaceFree &&
                                            errors.enlaceFree &&
                                            'error'
                                        }
                                        size="sm"
                                        id="step3enlacefree"
                                        name="enlaceFree"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.enlaceFree}
                                    />
                                    {touched.enlaceFree && errors.enlaceFree ? (
                                        <div className="error-message">
                                            {errors.enlaceFree}
                                        </div>
                                    ) : null}
                                    <Row>
                                        <Col className="d-flex gap-1 align-items-center justify-content-center">
                                            <Form.Select
                                                placeholder="Selecciona una opción"
                                                aria-label="Default select example"
                                                size="sm"
                                                value={values.enlaceFree}
                                                name="enlaceFree"
                                                onChange={e => {
                                                    handleChange(e);
                                                    setTituloPaste(
                                                        e.target[
                                                            e.target
                                                                .selectedIndex
                                                        ].text
                                                    );
                                                }}
                                                onBlur={handleBlur}>
                                                <option value="1">
                                                    Selecciona un enlace Publico
                                                    de la lista
                                                </option>
                                                {pastes.map(
                                                    paste =>
                                                        paste.vip === '0' && (
                                                            <option
                                                                key={paste.id}
                                                                value={`${URL_PASTE}${paste.url}`}>
                                                                {paste.Titulo}
                                                            </option>
                                                        )
                                                )}
                                            </Form.Select>
                                            <Button
                                                onClick={() => {
                                                    handleEnlacesPastes(
                                                        setFieldValue,
                                                        setTituloPaste
                                                    );
                                                }}
                                                props={{
                                                    variant: 'flat',
                                                    type: 'button'
                                                }}>
                                                <FaRedoAlt />
                                            </Button>
                                            <div>
                                                <BasicModal
                                                    buttonTitle={
                                                        <AddIcon
                                                            sx={{
                                                                fontSize:
                                                                    '1.5rem'
                                                                // color: '#fff'
                                                            }}
                                                        />
                                                    }
                                                    sxButton={sxButtonPaste}
                                                    styles={styleModalPaste}>
                                                    <Paste
                                                        inputNameProp={
                                                            nombrePasteACrear
                                                        }
                                                        handleEnlacesPastes={vipOrFree =>
                                                            handleEnlacesPastes(
                                                                setFieldValue,
                                                                setTituloPaste,
                                                                vipOrFree
                                                            )
                                                        }
                                                    />
                                                </BasicModal>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label htmlFor="step3enlacevip">
                                    Enlace VIP
                                </label>
                            </Col>
                            <Col sm={10} className="my-auto">
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Enlace VIP"
                                        size="sm"
                                        id="step3enlacevip"
                                        name="enlaceVip"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.enlaceVip}
                                    />
                                    <Row>
                                        <Col className="d-flex gap-1 align-items-center justify-content-center">
                                            <Form.Select
                                                placeholder="Selecciona una opción"
                                                aria-label="Default select example"
                                                size="sm"
                                                value={values.enlaceVip}
                                                name="enlaceVip"
                                                onChange={handleChange}
                                                onBlur={handleBlur}>
                                                <option value="1">
                                                    Selecciona un enlace VIP de
                                                    la lista
                                                </option>
                                                {pastes.map(
                                                    paste =>
                                                        paste?.vip !== '0' && (
                                                            <option
                                                                key={paste.id}
                                                                value={`${URL_PASTE}${paste.url}`}>
                                                                {paste.Titulo}
                                                            </option>
                                                        )
                                                )}
                                            </Form.Select>
                                            <Button
                                                onClick={() =>
                                                    handleEnlacesPastes()
                                                }
                                                props={{
                                                    variant: 'flat',
                                                    type: 'button'
                                                }}>
                                                <FaRedoAlt />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                        {typeSearch === '0' && (
                            <>
                                <Row>
                                    <Col>
                                        <label htmlFor="step3enlacevip">
                                            Temporada
                                        </label>
                                    </Col>
                                    <Col sm={10} className="my-auto">
                                        <Form.Group className="mb-3">
                                            <Form.Control
                                                type="text"
                                                placeholder="Temporada: Ej 01"
                                                size="sm"
                                                id="step3temporada"
                                                name="temporada"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.temporada}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label htmlFor="step3enlacevip">
                                            Capítulos
                                        </label>
                                    </Col>
                                    <Col sm={10} className="my-auto">
                                        <Form.Group className="mb-3">
                                            <Form.Control
                                                type="text"
                                                placeholder="Capítulo: Ej 03/xx"
                                                size="sm"
                                                id="step3capitulo"
                                                name="capitulo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.capitulo}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </MyForm>
                )}
            </Formik>
            <Toaster position="top-center" reverseOrder={false} />
        </div>
    );
};

export default Step3;

export const sxButtonPaste = {
    sx: {
        backgroundColor: '#260505',
        padding: '1px',
        margin: '1px 0',
        color: '#fff',
        fontSize: '1rem',
        // fontWeight: 'bold',
        position: 'relative',
        display: 'inline-block',
        width: '100%',
        height: '100%',
        minWidth: '2rem',
        '&:hover': {
            backgroundColor: '#260505',
            color: '#d1a10a',
            fontWeight: 'bold'
        }
    },
    variant: 'text',
    size: 'small'
};

export const styleModalPaste = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow: 12,
    p: 1,
    // overflowY: 'auto', // Agregar scroll vertical cuando sea necesario
    maxHeight: '90vh' // Limitar la altura para evitar que el modal sea demasiado grande
};
