import { Box, Chip, Stack, TextField, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import images from '../../assets/images';
import ButtonStyle from '../../components/button/ButtonStyle';
import {
    getUsersPaste,
    postApplicarVoucher,
    putUpdateUserPaste
} from '../../utils/apipaste';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import './UsersPaste.css';
import { toast, Toaster } from 'react-hot-toast';
import {
    getLocalStorage,
    parsearFecha,
    validarFechaVencida
} from '../../utils/functions';
import { useNavigate } from 'react-router-dom';

const conditionalRowStyles = [
    // {
    //     when: row => row.userID < 300,
    //     style: {
    //         backgroundColor: 'rgba(63, 195, 128, 0.9)',
    //         color: 'white',
    //         '&:hover': {
    //             cursor: 'pointer'
    //         }
    //     }
    // },
    {
        when: row => {
            return validarFechaVencida(row?.expirationDateGD);
        },
        style: {
            backgroundColor: 'rgba(248, 148, 6, 0.9)',
            color: 'white',
            '&:hover': {
                cursor: 'pointer'
            }
        }
    },
    {
        when: row => {
            const currentDate = moment();
            const dateToCompare = moment(row?.expirationDateGD);

            if (moment(row?.expirationDateGD).isValid()) {
                if (currentDate.isBefore(dateToCompare)) {
                    // la fecha aun no vence
                    return false;
                } else if (currentDate.isAfter(dateToCompare)) {
                    // la fecha ya esta vencida
                    return true;
                } else {
                    console.log('Las dos fechas son iguales');
                    return true;
                }
            }
        },
        style: {
            backgroundColor: 'rgba(242, 38, 19, 0.9)',
            color: 'white',
            '&:hover': {
                cursor: 'not-allowed'
            }
        }
    }
];

const ClearButton = styled(ButtonStyle)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField
            id="search"
            type="text"
            size="small"
            placeholder="Filtrar por User"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);

const UsersPaste = () => {
    const userData = getLocalStorage('user');
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [data, setData] = useState([]);
    const [fechaExpiracion, setFechaExpiracion] = useState('');
    const [show, setShow] = useState(false);
    const [dataColumn, setDataColumn] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingUp, setLoadingUp] = useState(false);
    const [FechaExp, setFechaExp] = useState('');
    const [correoGD, setcorreoGD] = useState('');
    const [voucher, setVoucher] = useState('');
    const [clickUpdateUsers, setClickUpdateUsers] = useState(0);
    const navigate = useNavigate();

    const handleShowModal = row => {
        setDataColumn(row);
        setcorreoGD(() => row?.correoGD);
        setFechaExp(() => row?.expirationDateGD);
        setShow(true);
    };

    const filteredItems = data?.filter(
        item =>
            item.user &&
            item.mail.toLowerCase().includes(filterText.toLowerCase())
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const getRowClassName = row => {
        console.log('rowww', row);
        if (row.userID !== '2') {
            return 'red_row';
        }
        return 'red_row';
    };

    const fetchUsers = async () => {
        setLoading(true);
        const { data, status } = await getUsersPaste();
        setLoading(false);

        if (status !== 'success') {
            console.log('error al pedir user', data);
            return;
        }
        setData(data);
        // setLoading(false);
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setLoadingUp(true);
        console.log('dataColumn', dataColumn);

        const usrData = {
            correoGD: correoGD,
            expirationDateGD: FechaExp
        };

        await putUpdateUserPaste(dataColumn?.userID, usrData);
        setData(() =>
            data.map(e =>
                e.userID === dataColumn?.userID
                    ? {
                          ...dataColumn,
                          correoGD: correoGD,
                          expirationDateGD: FechaExp
                      }
                    : e
            )
        );
        toast.success('Cambios guardados!');
        setLoadingUp(false);
    };

    const aplicarVoucher = async () => {
        // Aplicar voucher
        const dataVoucher = {
            user_id: dataColumn?.userID,
            voucher: voucher
        };
        try {
            await postApplicarVoucher(dataVoucher);
            setTimeout(function () {
                setClickUpdateUsers(pre => pre + 1);
                setVoucher('');
                setShow(false);
            }, 1000);
            toast.success('Voucher aplicado correctamente');
        } catch (error) {
            console.log('error al aplicar voucher');
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [clickUpdateUsers]);

    const columns = [
        {
            name: 'USER ID',
            selector: row => row.userID,
            sortable: true,
            width: '91px',
            grow: 1,
            style: {
                // color: '#202124',
                fontSize: '14px',
                fontWeight: 500
            }
        },
        {
            name: 'USER',
            selector: row => row?.user,
            sortable: true,
            // width: '91px',
            // grow: 1,
            style: {
                // color: '#202124',
                fontSize: '14px',
                fontWeight: 500
            }
        },
        {
            name: 'CORREO',
            selector: row => row.mail,
            sortable: true,
            grow: 3,
            style: {
                // color: '#202124',
                fontSize: '14px',
                fontWeight: 500
            },
            wrap: true
            // format: row => `${row.title.slice(0, 200)}`
        },
        {
            name: 'FECHA VIP',
            selector: row => {
                if (row?.vipdate) {
                    const diasRestantes = parsearFecha(row?.vipdate);
                    return diasRestantes < 0
                        ? 'Vencido'
                        : `${diasRestantes} dias restantes`;
                }
            },
            wrap: true,
            // selector: row => ,
            sortable: true,
            // width: '91px',
            grow: 1,
            style: {
                // color: '#202124',
                fontSize: '14px',
                fontWeight: 500
            }
        },
        {
            name: 'VIP',
            selector: row => (row.vip === '1' ? 'SI' : 'NO'),
            sortable: true,
            // grow: 1,
            style: {
                // color: '#202124',
                fontSize: '14px',
                fontWeight: 500
            }
        },
        {
            name: 'CORREO GD',
            selector: row => row?.correoGD,
            sortable: true,
            wrap: true,
            grow: 2,
            style: {
                // color: '#202124',
                fontSize: '14px',
                fontWeight: 500
            }
        },
        {
            name: 'FECHA DE EXPIRACIÓN',
            // selector: row => moment(row.modified).format('ll'),
            selector: row => row?.expirationDateGD,
            // cell: row => (
            //     <TextField
            //         type="date"
            //         placeholder="Filtrar por Titulo"
            //         aria-label="Search Input"
            //         value={fechaExpiracion}
            //         onChange={e => setFechaExpiracion(e.target.value)}
            //     />
            // ),
            sortable: true,
            wrap: true,
            grow: 2,
            style: {
                // color: '#202124',
                fontSize: '14px',
                fontWeight: 500
            }
        },
        {
            cell: row => (
                <button
                    className="btn btn-success"
                    onClick={() => handleShowModal(row)}>
                    Editar
                </button>
            )
            // allowOverflow: true,
            // button: true,
            // width: '300px'
        }
    ];

    if (userData.roles !== 'admin') navigate('/');

    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <DataTable
                        title={
                            <div className="d-flex justify-content-start gap-2 align-items-center fw-bold">
                                <div>
                                    <img
                                        src={images.usuarios_2}
                                        alt=""
                                        width="45"
                                        height="75"
                                    />
                                </div>
                                <div>
                                    <img
                                        src={images.calendario}
                                        alt=""
                                        width="45"
                                        height="75"
                                    />
                                </div>
                                {/* <div>USERS - MULTIPASTE</div> */}
                            </div>
                        }
                        columns={columns}
                        data={filteredItems}
                        // customStyles={customStyles}
                        // progressPending={loading}
                        pagination
                        // paginationServer
                        // paginationTotalRows={totalRows}
                        // onChangePage={handlePageChange}
                        // onChangeRowsPerPage={handlePerRowsChange}
                        // highlightOnHover
                        // pointerOnHover
                        subHeader
                        // paginationResetDefaultPage={resetPaginationToggle}
                        subHeaderComponent={subHeaderComponentMemo}
                        // selectableRows
                        // persistTableHead
                        // paginationRowsPerPageOptions={[10, 25, 50, 100]}
                        conditionalRowStyles={conditionalRowStyles}
                    />
                    <Modal
                        size="lg"
                        show={show}
                        onHide={() => setShow(false)}
                        aria-labelledby="example-custom-modal-styling-title"
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '5px',
                                        alignItems: 'center'
                                    }}>
                                    <div>
                                        <img
                                            // style={{ width: '14%' }}
                                            src={images.Correo_imagen}
                                            alt=""
                                            width="45"
                                            height="45"
                                        />
                                    </div>
                                    <Typography
                                        variant="h4"
                                        sx={{ fontWeight: 'bolder' }}>
                                        Correo de GoogleDrive
                                    </Typography>
                                    <div>
                                        <img
                                            // style={{ width: '14%' }}
                                            src={images.LogoDrive}
                                            alt=""
                                            width="45"
                                            height="45"
                                        />
                                    </div>
                                </Box>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={handleSubmit}>
                                <Stack direction="column">
                                    <Stack direction="row">
                                        <TextField
                                            style={{
                                                width: '100%',
                                                margin: '5px'
                                            }}
                                            type="email"
                                            label="Correo"
                                            variant="outlined"
                                            size="small"
                                            onChange={e =>
                                                setcorreoGD(e.target.value)
                                            }
                                            value={correoGD}
                                            // inputRef={correoGD}
                                        />
                                        <TextField
                                            style={{
                                                width: '300px',
                                                margin: '5px'
                                            }}
                                            type="date"
                                            label="Fecha de Expiración"
                                            variant="outlined"
                                            focused
                                            size="small"
                                            value={FechaExp}
                                            onChange={e =>
                                                setFechaExp(e.target.value)
                                            }
                                            // inputRef={FechaExp}
                                        />
                                        <LoadingButton
                                            className="btn btn-warning"
                                            style={{
                                                width: '270px',
                                                margin: '5px'
                                            }}
                                            type="submit"
                                            endIcon={<SendIcon />}
                                            loading={loadingUp}
                                            loadingPosition="end"
                                            variant="contained">
                                            Guardar
                                        </LoadingButton>
                                    </Stack>
                                    <Stack direction="row">
                                        <TextField
                                            style={{
                                                width: '100%',
                                                margin: '5px'
                                            }}
                                            type="text"
                                            label="Aplicar voucher"
                                            variant="outlined"
                                            size="small"
                                            onChange={e =>
                                                setVoucher(e.target.value)
                                            }
                                            value={voucher}
                                        />
                                        <LoadingButton
                                            className="btn btn-warning"
                                            style={{
                                                width: '200px',
                                                margin: '5px'
                                            }}
                                            onClick={aplicarVoucher}
                                            endIcon={<SendIcon />}
                                            loading={loadingUp}
                                            loadingPosition="end"
                                            variant="contained">
                                            Aplicar
                                        </LoadingButton>
                                    </Stack>
                                </Stack>
                            </form>
                        </Modal.Body>
                    </Modal>

                    <Toaster position="bottom-center" reverseOrder={false} />
                </>
            )}
        </>
    );
};

export default UsersPaste;
