import React, { useState } from 'react';
import SearchPostWordpress from '../SearchPostsWoredpress/SearchPostWordpress';
import FichaCoincidence from './FichaCoincidence';
import { Stack } from '@mui/material';

const SearchCoincidence = () => {
    const [dataPost, setDataPost] = useState({});
    console.log('dataPost', dataPost);
    return (
        <>
            <Stack sx={{ padding: '0 0 0 40px', width: '100%' }} spacing={1}>
                <SearchPostWordpress setDataPost={setDataPost} />
                <FichaCoincidence dataPost={dataPost} />
            </Stack>
        </>
    );
};

export default SearchCoincidence;
