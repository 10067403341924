import DataTable from 'react-data-table-component';
import usePasteVoucher from './hook/usePasteVoucher';
import { Box, Button, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';

const customStyles = {
    tableWrapper: {
        maxHeight: '400px', // ajusta el tamaño de la tabla aquí
        maxWidth: '100px'
    }
};

const Vouchers = () => {
    const { data, loading, error, columns, loadingCrear, handleCrearVoucher } =
        usePasteVoucher();
    const [days, setDays] = useState();

    return (
        <Stack direction="row" justifyContent="center" sx={{ width: '100%' }}>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <Box sx={{ width: '600px' }}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        gap={4}
                        mb="20px"
                        mt="20px">
                        <TextField
                            type="number"
                            id="outlined-basic"
                            label="Dias"
                            size="small"
                            name="days"
                            value={days}
                            onChange={e => setDays(e.target.value)}
                        />
                        <LoadingButton
                            loading={loadingCrear}
                            variant="contained"
                            onClick={() => handleCrearVoucher({ days: days })}>
                            Crear
                        </LoadingButton>
                    </Stack>
                    <DataTable
                        // title="Vouchers"
                        columns={columns}
                        data={data?.data}
                        // customStyles={customStyles}
                        // fixedHeader={true}
                        // fixedHeaderScrollHeight="400px"
                        responsive={true}
                        // customStyles={customStyles}
                        // progressPending={loading}
                        pagination
                    />
                </Box>
            )}
        </Stack>
    );
};

export default Vouchers;
