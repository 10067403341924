import { useEffect, useState } from 'react';
import { useDispatch, useStore } from '../../store/StoreProvider';
import { types } from '../../store/storeReducer';

const useFillTabs = defaultActiveKey => {
    const { fillTabs } = useStore();
    const dispatch = useDispatch();
    const [key, setKey] = useState(defaultActiveKey);

    const handleSetKey = key => {
        dispatch({
            type: types.FILL_TABS,
            payload: key
        });
        setKey(fillTabs);
    };

    useEffect(() => {
        dispatch({
            type: types.FILL_TABS,
            payload: defaultActiveKey
        });
    }, [defaultActiveKey]);

    return { key, handleSetKey, fillTabs };
};

export default useFillTabs;
