import axios from 'axios';
import { getLocalStorage } from '../../../utils/functions';
import { REACT_APP_API_REST_WP } from '../../../env';
// const userData = getLocalStorage('user');
// const userId = userData.id_wordpress;
let allPosts = [];
let totalPages = 1;
let currentPage = 1;

async function fetchPosts(page, userId) {
    const apiUrl = `${REACT_APP_API_REST_WP}/posts?author=${userId}&page=${page}`;

    try {
        const response = await axios.get(apiUrl);
        if (currentPage === 1) {
            totalPages = response.headers['x-wp-totalpages'];
        }
        const posts = response.data;
        allPosts = allPosts.concat(posts);
    } catch (error) {
        console.error('Error al obtener las entradas:', error);
    }
}

export async function fetchAllPosts(userId) {
    try {
        // Realiza la primera solicitud para obtener la información de paginación
        await fetchPosts(currentPage, userId);

        // Realiza solicitudes adicionales para obtener todas las páginas restantes
        const additionalRequests = [];
        for (let page = 2; page <= totalPages; page++) {
            // console.log('fetching page:', page);
            additionalRequests.push(fetchPosts(page, userId));
        }
        await Promise.all(additionalRequests);
        return allPosts;
        // console.log('Total de posts:', allPosts.length);
        // console.log("Entradas del usuario:", allPosts);
    } catch (error) {
        console.error('Error al obtener las entradas:', error);
    }
}

export async function fetchUsersWP() {
    const apiUrl = `${REACT_APP_API_REST_WP}/users`;

    try {
        const response = await axios.get(apiUrl);
        return response.data;
    } catch (error) {
        console.error('Error al obtener los usuarios:', error);
    }
}

// Llama a la función para obtener todos los posts
// fetchAllPosts();
