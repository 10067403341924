import axios from 'axios';
import { API_REST_UNIVERSAL } from '../../../env';

export const deletePostApartado = async id => {
    try {
        const response = await axios.delete(
            `${API_REST_UNIVERSAL}/apartar-post`,
            {
                data: { id }
            }
        );
        if (response.status === 200) {
            console.log(`Post con ID ${id} eliminado exitosamente`);
            // Puedes agregar aquí código para actualizar la interfaz o mostrar un mensaje al usuario.
        } else {
            console.error(`Error al eliminar el post: ${response.statusText}`);
        }
    } catch (error) {
        console.error('error:', error.response);
        return error.response;
    }
};
export const getPostApartados = async id => {
    try {
        const response = await axios.get(`${API_REST_UNIVERSAL}/apartar-post`);
        if (response.status === 200) {
            return response.data;
        } else {
            console.error(`Error al eliminar el post: ${response.statusText}`);
        }
    } catch (error) {
        console.error('error:', error.response);
        return error.response;
    }
};
export const getCreatePostApartados = async data => {
    try {
        const response = await axios.post(
            `${API_REST_UNIVERSAL}/apartar-post`,
            data
        );
        return response;
    } catch (error) {
        console.error('error:', error.response);
        return error.response;
    }
};
export const putUpdatePostApartados = async data => {
    try {
        const response = await axios.put(
            `${API_REST_UNIVERSAL}/apartar-post`,
            data
        );
        return response;
    } catch (error) {
        console.error('error:', error.response);
        return error.response;
    }
};
