import React from 'react';

const TitleIcons = ({ title, img }) => {
    return (
        <div className="d-flex justify-content-start gap-2 align-items-center fw-bold">
            <div>
                <img src={img} alt="" width="25" height="25" />
            </div>
            <div>{title}</div>
        </div>
    );
};

export default TitleIcons;
