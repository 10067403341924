import { uploadimg, uploadImgImgur, uploadImgPixhostFile } from './api';

// 9032662b1eebea80f969a4c6b629250f
export const subirPoster = async fileposter => {
    let formData = new FormData();
    formData.append('image', fileposter);

    const BBB = [
        '96a4555e7f77688807102d50a883d43f',
        '635ed4ce3ad75f557ea60f6010aea1d4'
    ];
    const random = Math.floor(Math.random() * BBB.length);

    console.log('random', random);

    const { data, status } = await uploadimg(BBB[random], formData);

    if (status !== 200) {
        return 'Error';
    }

    return data;
};

// subir poster a imgur
export const subirPosterImgur = async fileposter => {
    let formData = new FormData();
    formData.append('image', fileposter);

    const { data } = await uploadImgImgur(formData);

    return data;
};

export const subirPosterPixhostFile = async fileposter => {
    let formData = new FormData();
    formData.append('file', fileposter);

    const data = await uploadImgPixhostFile(formData);

    return data;
};
