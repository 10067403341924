import React, { useEffect, useState } from 'react';
import LayoutPlantillas from '../home/crearPortada/Plantillas/LayoutPlantillas';
import Mymetadata from '../../metadata.json';
import {
    crearPlantillaPoster,
    deletePlantilla,
    deletePlantillaPlus,
    getPlantillas,
    getPlantillasPlus,
    subirPlantilla,
    subirPlantillaPlus
} from '../../utils/api';
import { useDispatch } from '../../store/StoreProvider';
import { types } from '../../store/storeReducer';

const PageCasas = ({ plus = false }) => {
    const [isLoading, setisLoading] = useState(false);
    const [metadata, setMetadata] = useState(Mymetadata);
    const dispatch = useDispatch();

    const handledeGetImages = async () => {
        setisLoading(true);
        try {
            const { data } = await getPlantillas();

            const dataFormat = data.map(i => ({
                label: i.name,
                value: i.name,
                url: i.url,
                id: i.id
            }));

            setMetadata(() => [
                ...metadata?.map(obj =>
                    obj.name === 'Extra'
                        ? { name: 'Extra', data: dataFormat }
                        : obj
                )
            ]);
            dispatch({
                type: types.METADATA_EXTRAS,
                payload: [
                    ...metadata?.map(obj =>
                        obj.name === 'Extra'
                            ? { name: 'Extra', data: dataFormat }
                            : obj
                    )
                ]
            });
            setisLoading(false);
        } catch (error) {
            setisLoading(false);
            console.error('Error al traer las plantillas', error);
        }
    };
    const handledeGetImagesPlus = async () => {
        setisLoading(true);
        try {
            const { data } = await getPlantillasPlus();
            const dataFormat = data.map(i => ({
                label: i.name,
                value: i.name,
                url: i.url,
                id: i.id
            }));
            setMetadata(() => [
                ...metadata?.map(obj =>
                    obj.name === 'Plus'
                        ? { name: 'Plus', data: dataFormat }
                        : obj
                )
            ]);
            dispatch({
                type: types.METADATA_EXTRAS,
                payload: [
                    ...metadata?.map(obj =>
                        obj.name === 'Plus'
                            ? { name: 'Plus', data: dataFormat }
                            : obj
                    )
                ]
            });
            setisLoading(false);
        } catch (error) {
            setisLoading(false);
            console.error('Error al traer las plantillas', error);
        }
    };

    const handledeGetImagAlls = async () => {
        setisLoading(true);
        try {
            const { data } = await getPlantillas();
            const { data: data2 } = await getPlantillasPlus();

            const dataFormat = data.map(i => ({
                label: i.name,
                value: i.name,
                url: i.url,
                id: i.id
            }));
            const dataFormat2 = data2.map(i => ({
                label: i.name,
                value: i.name,
                url: i.url,
                id: i.id
            }));

            setMetadata(() => [
                ...metadata?.map(obj =>
                    obj.name === 'Extra'
                        ? { name: 'Extra', data: dataFormat }
                        : obj.name === 'Plus'
                        ? { name: 'Plus', data: dataFormat2 }
                        : obj
                )
            ]);
            dispatch({
                type: types.METADATA_EXTRAS,
                payload: [
                    ...metadata?.map(obj =>
                        obj.name === 'Extra'
                            ? { name: 'Extra', data: dataFormat }
                            : obj.name === 'Plus'
                            ? { name: 'Plus', data: dataFormat2 }
                            : obj
                    )
                ]
            });
            setisLoading(false);
        } catch (error) {
            setisLoading(false);
            console.error('Error al traer las plantillas', error);
        }
    };

    const subir = plus ? subirPlantillaPlus : subirPlantilla;
    const deletePlan = plus ? deletePlantillaPlus : deletePlantilla;
    const getImages = plus ? handledeGetImagesPlus : handledeGetImages;

    useEffect(() => {
        handledeGetImagAlls();
    }, []);

    return (
        <LayoutPlantillas
            metadata={metadata}
            setMetadata={setMetadata}
            handledeGetImages={getImages}
            isLoading={isLoading}
            crearPlantillaPoster={crearPlantillaPoster}
            deletePlantilla={deletePlan}
            subirPlantilla={subir}
            plus={plus}
        />
    );
};

export default PageCasas;
