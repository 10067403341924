import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Grid,
    Stack,
    MenuItem,
    TextField
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import React, { useEffect } from 'react';
import Layout from './components/Layout';
import { useState } from 'react';
import SearchPostWordpress from '../SearchPostsWoredpress/SearchPostWordpress';
import { makePostRequest } from '../../utils/objectFormat';
import {
    extraerEnlaceFree,
    extraerEnlaceVip,
    extraerUrlsDeTextos,
    getLocalStorage,
    obtenerNombresDeHost,
    searchDataUser
} from '../../utils/functions';
import { getCategoriaWordpress, getpostsWordpress } from '../../utils/apiwp';
import images from '../../assets/images';
import { toast, Toaster } from 'react-hot-toast';
import { useStore } from '../../store/StoreProvider';
import UnstyledTextareaIntroduction from './components/TextArea';
import DragAndDrop from '../DragArea/DragAndDrop';
import { subirPosterImgur } from '../../utils/subirimg';

const tipoDePublicaion = [
    { value: '#NuevoPost', label: 'Post nuevo', selected: true },
    { value: '#NuevaSerie', label: 'Nueva Serie' },
    { value: '#NuevoCapitulo', label: 'Nuevo capitulo (Series)' },
    { value: '#NuevaTemporada', label: 'Nueva temporada (Series)' },
    { value: '#Resubida', label: 'Resubida' },
    { value: '#Comunicado', label: 'Comunicado' },
    { value: '#Colección', label: 'Colección' },
    { value: '#ContenidoExclusivo', label: 'Contenido exclusivo' }
];

const URL_PASOS_PARA_SER_VIP =
    'https://telegra.ph/Cu%C3%A1l-es-el-proceso-para-completar-mi-mebres%C3%ADa-VIP-11-16';

const StylesSelected = {
    width: '100%',
    marginBottom: '20px'
};

const StylesGridContainer = {
    backgroundColor: '#EFF5F5',
    marginTop: '10px',
    borderRadius: '5px',
    padding: '10px'
};

// GRUPO PRINCIPAL -1001527192006
// AnimeGalaxy.net (Canal) -1001500601482
// Pan •Noticias• -1001615539761
const arrayChatID = ['-1001527192006', '-1001500601482', '-1001615539761'];
// const chatID = '-1001637261112';

const TelegramLibre = () => {
    const { dataAEnviar, postWordpress, view, search } = useStore();

    const [tipoPost, setTipoPost] = useState(
        `${view === 4 ? '#NuevoPost' : ''}`
    );
    const [dataPost, setDataPost] = useState({});
    const [arrayDataPost, setArrayDataPost] = useState([]);
    const [textoAdicional, setTextoAdicional] = useState('');
    const [nombresCategorias, setNombresCategorias] = useState([]);
    const [alias, setAlias] = useState('');
    const [pesoArchivo, setPesoArchivo] = useState('');

    const urlPortada = extraerUrlsDeTextos(dataPost?.content?.rendered);
    const enlaceVip = extraerEnlaceVip(dataPost?.content?.rendered);
    const enlaceFree = extraerEnlaceFree(dataPost?.content?.rendered);
    const userData = getLocalStorage('user') || {};
    const nombreServidores = obtenerNombresDeHost(dataAEnviar?.misServidores);

    const [imageFile, setImageFile] = useState(null);
    const [imageLoading, setImageLoading] = useState(false);
    const [message, setMessage] = useState('');

    /**
     * VALIDAR EL PESO DEL ARCHIVO
     */
    const pesoArchivoString =
        search?.typeSearch === '1'
            ? `🎞️ Peso: ${dataAEnviar?.PesoArchivo}`
            : search?.typeSearch
            ? `🎞️ Peso: ${dataAEnviar?.PesoArchivo} GB Aprox./Capitulo`
            : '';

    /**
     * FUNCIONALIDAD PARA ENVIAR POST A TELEGRAM
     */

    const newLineToken = '\n';

    const handlePost = async () => {
        // let stringCategorias = '';
        // for (let cat = 0; cat < nombresCategorias.length; cat++) {
        //     stringCategorias += `#${nombresCategorias[cat]?.name.replace(
        //         ' ',
        //         '_'
        //     )} `;
        // }

        // let misServidores = '☁️ ' + nombreServidores.join(', ');

        // const escapedMsg = `${tipoPost} ${newLineToken} ${newLineToken} 🎬 <b>${
        //     dataPost?.title?.rendered
        // }</b> ${newLineToken} ${newLineToken} ${
        //     textoAdicional &&
        //     `${textoAdicional} ${newLineToken} ${newLineToken}`
        // } ${misServidores} ${newLineToken} ${newLineToken} ${stringCategorias} ${newLineToken} ${newLineToken} 👤 Uploader: ${alias} | ${pesoArchivo}`;

        for (let chatId = 0; chatId < arrayChatID.length; chatId++) {
            const response = await makePostRequest({
                photo: `${imageFile}`,
                caption: message,
                parse_mode: 'html',
                chat_id: arrayChatID[chatId]
                // reply_markup: {
                //     inline_keyboard: [
                //         [{ text: '🔥IR AL ENLACE🔥', url: dataPost?.link }],
                //         [
                //             {
                //                 text: '🍿 Ir a HDLATINO',
                //                 url: 'https://vip.hdlatino.us/'
                //             },
                //             {
                //                 text: `👤 Aportes: ${alias}`,
                //                 url: `https://vip.hdlatino.us/?s=${alias}`
                //             }
                //         ],
                //         [
                //             { text: '🏆 ENLACES  VIP', url: enlaceVip },
                //             { text: '🤲 ENLACES FREE', url: enlaceFree }
                //         ],
                //         [
                //             {
                //                 text: '¿PASOS PARA SER VIP?',
                //                 url: URL_PASOS_PARA_SER_VIP
                //             }
                //         ]
                //     ]
                // }
            });
            console.log('response', response);
        }
        toast.success('Enviado!');
    };

    // const getGaterias = async () => {
    //     let categorias = [];
    //     let nombresCategorias = [];
    //     if (dataPost?.categories.length > 3) {
    //         categorias = dataPost?.categories.slice(0, 3);
    //     } else {
    //         categorias = dataPost?.categories;
    //     }

    //     for (let i = 0; i < categorias.length; i++) {
    //         const result = await getCategoriaWordpress(categorias[i]);
    //         nombresCategorias.push(result);
    //     }
    //     setNombresCategorias(nombresCategorias);
    // };

    // const getUser = async () => {
    //     const users = await searchDataUser();
    //     const user = users.data.filter(
    //         item => item.slug === userData.username.toLowerCase()
    //     );
    //     setAlias(user[0]?.name);
    // };

    // /**
    //  *
    //  * LISTAR INPUT CON LOS ULTIMOS 10 POST PUBLICADOS
    //  */

    // const getPost = async () => {
    //     const { data, status } = await getpostsWordpress();

    //     if (status !== 200) {
    //         toast.error('Ocurrio un error');
    //         return;
    //     }

    //     setArrayDataPost(data);
    // };

    // useEffect(() => {
    //     getUser();
    //     getPost();
    //     setPesoArchivo(pesoArchivoString);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    // useEffect(() => {
    //     getGaterias();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dataPost]);
    // useEffect(() => {
    //     getPost();
    //     setDataPost(postWordpress);
    // }, [postWordpress]);

    const handleFileChange = async e => {
        e.preventDefault();
        try {
            setImageLoading(true);
            const file = e.target.files[0];
            const imagen = await subirPosterImgur(file);
            setImageFile(imagen?.data?.link);
            setImageLoading(false);
        } catch (error) {
            console.log('error', error);
            setImageLoading(false);
            toast.error('Ocurrio un error');
        }
    };

    return (
        <Grid container spacing={2} sx={{ marginTop: '10px' }}>
            <Grid item xs={12} sx={StylesGridContainer}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                    <img
                        src={images.Logo_Telegram2}
                        alt=""
                        style={{ width: '60px' }}
                    />
                    <h2 style={{ fontWeight: 'bold' }}>
                        Publicador de Post Telegram Libre
                    </h2>
                </Box>
                <Stack mb={1} direction={'row'} justifyContent={'center'}>
                    <img src={imageFile} alt="" style={{ width: '40%' }} />
                </Stack>
                <Box>
                    <Box mb={1}>
                        {imageLoading ? (
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center">
                                <CircularProgress />
                            </Stack>
                        ) : (
                            <DragAndDrop
                                title={'Arrastra una imagen'}
                                accept="image/*"
                                handleChange={handleFileChange}
                            />
                        )}
                    </Box>

                    <UnstyledTextareaIntroduction
                        setMessage={setMessage}
                        message={message}
                    />

                    <Button
                        sx={{
                            backgroundColor: '#ffc107',
                            color: '#000',
                            fontWeight: 'bold'
                        }}
                        onClick={() => handlePost()}
                        variant="contained"
                        // endIcon={<SendIcon />}
                        fullWidth={true}>
                        <div className="d-flex gap-2 align-items-center">
                            <img
                                src={images.Logo_Telegram3}
                                alt="telegram"
                                style={{ width: '26px' }}
                            />
                            Enviar mensaje a Telegram
                        </div>
                    </Button>
                </Box>
            </Grid>
            {/* <Grid item xs={4}>
                <Layout
                    tipoPost={tipoPost}
                    dataPost={dataPost}
                    textoAdicional={textoAdicional}
                    urlPortada={urlPortada?.length > 0 ? urlPortada[0] : ''}
                    alias={`${alias} | ${pesoArchivo}`}
                    nombresCategorias={nombresCategorias}
                />
            </Grid> */}
            <Toaster position="top-center" reverseOrder={false} />
        </Grid>
    );
};

export default TelegramLibre;
