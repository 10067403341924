import React, { useEffect, useState } from 'react';
import { crearAudio, deleteAudio, obtenerAudios } from '../../../utils/api';
import { toast } from 'react-toastify';

const useStep1 = () => {
    const [loadingAudios, setLoadingAudios] = useState();
    const [dataAudios, setDataAudios] = useState([]);

    /**
     * Obtener todos los audios
     */
    const handleGetAudios = async () => {
        setLoadingAudios(true);
        const result = await obtenerAudios();

        const arrayAudioPreparados = result?.data.map(audio => ({
            id: audio.id,
            label: audio.nombre_audio,
            value: audio.nombre_audio
        }));

        setDataAudios(arrayAudioPreparados);
        setLoadingAudios(false);
    };

    /**
     * Borrar audios
     */

    const handleDeleteAudio = async audioId => {
        const obj = {
            id: audioId
        };
        const result = await deleteAudio(obj);
        await handleGetAudios();
        toast('🗑️ Se elimno el audio!', {
            position: 'top-center',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });
    };

    /**
     * Crear Audios
     */

    const handleClickCreateAudio = async audioid => {
        const objAudio = {
            nombre_audio: audioid
        };
        const result = await crearAudio(objAudio);
        handleGetAudios();
        toast('🔊 Audio creado!', {
            position: 'top-center',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });
    };

    useEffect(() => {
        handleGetAudios();
    }, []);

    return {
        dataAudios,
        loadingAudios,
        handleClickCreateAudio,
        handleGetAudios,
        handleDeleteAudio
    };
};

export default useStep1;
