import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../components/Header/Header';
import SearchGoogleImg from '../components/SearchGoogleImg/SearchGoogleImg';
import Sidebar from '../components/Sidebar/Sidebar';

const LayoutMain = ({ children }) => {
    return (
        <>
            <Header />
            <Sidebar />
            <Container fluid="md" className="px-5 pt-2 ">
                {children}
            </Container>
        </>
    );
};

export default LayoutMain;
