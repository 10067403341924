import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Chip } from '@mui/material';
import ShowPoster from './components/ShowPoster';
import { deletePedido, getPedidos } from './services/pedidos.services';
import RowButtonsGroud from '../RowButtonGroud/RowButtonsGroud';

const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página:',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
};

const DatatablePedidos = () => {
    const [posts, setPosts] = useState([]);
    const [data, setData] = useState([]);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const fetchPosts = async page => {
        setLoading(true);
        const response = await getPedidos();
        console.log('response', response.data);
        setPosts(response);
        setData(response);
        setTotalRows(response?.length);
        setLoading(false);
    };

    const handleDeletePost = async id => {
        setLoadingDelete(true);
        const response = await deletePedido(id);
        if (response) {
            setLoadingDelete(false);
            fetchPosts();
        }
    };

    useEffect(() => {
        fetchPosts(); // fetch page 1 of users
    }, []);

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         fetchPosts();
    //     }, 8000);

    //     return () => clearInterval(intervalId);
    // }, [posts]);

    const columns = [
        {
            name: 'Poster',
            selector: row => (
                <ShowPoster poster={row?.ped_poster} title={row?.ped_title} />
            ),
            sortable: true,
            width: '100px'
        },
        // {
        //     name: 'TMDB',
        //     selector: row => row.ped_tmdb,
        //     // sortable: true
        //     width: '100px'
        // },
        {
            name: 'Titulo',
            selector: row => row?.ped_title,
            sortable: true,
            wrap: true
        },
        {
            name: 'Año',
            selector: row => row?.ped_year,
            sortable: true,
            wrap: true,
            width: '80px'
        },
        {
            name: 'Calidad',
            selector: row => row?.ped_calidad,
            sortable: true,
            width: '100px'
        },
        {
            name: 'Resolución',
            selector: row => row?.ped_resolucion,
            sortable: true,
            width: '120px'
        },
        // {
        //     name: 'Idioma',
        //     selector: row => row.ped_idioma,
        //     sortable: true
        // },
        {
            name: 'User',
            selector: row => `${row?.ped_username} (${row?.ped_vip})`,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Tipo',
            selector: row => <Chip label={row?.ped_type} />,
            sortable: true,
            width: '110px'
        },
        {
            name: 'Detalles',
            selector: row => row?.ped_detalle,
            sortable: true,
            wrap: true,
            width: '180px'
        },
        {
            name: 'Casa',
            selector: row => row?.ped_casa,
            sortable: true
        },
        {
            cell: row => (
                <RowButtonsGroud
                    modalTitle={`Eliminar Post`}
                    modalBody={`¿Esta seguro de eliminar ${row?.ped_title}?`}
                    loading={loadingDelete}
                    handleDeletePost={() => handleDeletePost(row?.id)}
                />
            ),
            wrap: true
        }
    ];

    console.log('posts', posts);
    return (
        <DataTable
            fixedHeader
            fixedHeaderScrollHeight="650px"
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationTop
            title="Pedidos"
            columns={columns}
            data={posts}
            customStyles={customStyles}
            // conditionalRowStyles={conditionalRowStyles}
            highlightOnHover
            pointerOnHover
            // paginationRowsPerPageOptions={[100, 10, 20, 50]}
            // paginationDefaultPage={1}
        />
    );
};

export default DatatablePedidos;

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#F0F3FF',
            borderBottomColor: '#FFFFFF',
            borderRadius: '15px',
            outline: '1px solid #FFFFFF'
        }
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '14px'
        }
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '15px',
            outline: '1px solid #FFFFFF'
        }
    },
    pagination: {
        style: {
            border: 'none'
        }
    }
};
