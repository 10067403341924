import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Paper, Stack } from '@mui/material';
import Dashboard from './Dashboard';
import SearchCoincidenceLayout from '../../components/SearchCoincidence/SearchCoincidenceLayout';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2)
    },
    div1: {
        height: 100,
        marginBottom: 10,
        transition: 'width 0.5s ease'
    },
    div2: {
        backgroundColor: '#496989',
        marginBottom: 10,
        transition: 'width 0.5s ease'
    }
}));

const ResizeDivs = () => {
    const classes = useStyles();
    const [div1Width, setDiv1Width] = useState('100%');
    const [div2Width, setDiv2Width] = useState('auto');
    const [resize, setResize] = useState(false);

    const handleDivClick = resize => {
        if (!resize) {
            setDiv1Width('25%');
            setDiv2Width('75%');
            setResize(true);
        } else {
            setDiv1Width('100%');
            setDiv2Width('auto');
            setResize(false);
        }
    };

    return (
        <Stack direction={'row'}>
            <Box
                className={classes.div1}
                style={{ width: div1Width }}
                elevation={3}>
                <Dashboard resize={resize} />
            </Box>
            <Paper
                className={classes.div2}
                style={{ width: div2Width }}
                sx={{ mt: 2 }}
                elevation={3}>
                <SearchCoincidenceLayout
                    resize={resize}
                    onClick={() => handleDivClick(resize)}
                />
            </Paper>
        </Stack>
    );
};

export default ResizeDivs;
