import axios from 'axios';

const API_TRADUCTOR = 'https://api-translate.hdlatino.us';

export const getTraduction = async (query, lang = false) => {
    const langg = lang ? `lang=${lang}` : '';
    try {
        const { data } = await axios.get(
            `${API_TRADUCTOR}/translate?tr=${query}&${langg}`
        );
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};
