import React from 'react';
import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    TextField
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFetch } from '../../../../hooks/useFetch';
import { API_REST_UNIVERSAL } from '../../../../env';
import useFormChild from '../../hooks/useFormChild';
import { Stack } from 'react-bootstrap';

const ServersList = () => {
    const { handleDeleteServer, data } = useFormChild();

    return (
        <Paper elevation={0} sx={{ backgroundColor: 'transparent', mt: 2 }}>
            <List>
                {data?.map(server => (
                    <>
                        <ListItem
                            key={server.id}
                            secondaryAction={
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() =>
                                        handleDeleteServer(server.id)
                                    }>
                                    <DeleteIcon />
                                </IconButton>
                            }>
                            <ListItemAvatar>
                                <Avatar
                                    src={`http://www.google.com/s2/favicons?domain=${server.url}`}></Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={server.name}
                                // secondary={secondary ? 'Secondary text' : null}
                            />
                            <Box display={'flex'} gap={2}>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    // disabled
                                    value={server.url}
                                />
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    // disabled
                                    value={server.url_image_free}
                                />
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    // disabled
                                    value={server.url_image_vip}
                                />
                            </Box>
                        </ListItem>
                    </>
                ))}
            </List>
        </Paper>
    );
};

export default ServersList;
