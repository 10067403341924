import React, { useEffect, useState } from 'react';
import WordpressPostsChart from './GraficaYear';
import { getLocalStorage } from '../../utils/functions';
import Loading from '../Loading/Loading';
import { fetchAllPosts } from './service/services';

const MeGrafica = () => {
    const userData = getLocalStorage('user');
    const [loading, setLoading] = useState(true);
    const [wordpressPosts, setWordpressPosts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                const posts = await fetchAllPosts(userData.id_wordpress);
                setWordpressPosts(posts);
                setLoading(false);
            } catch (error) {
                console.error('Hubo un error al obtener datos:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);
    if (loading) {
        return <Loading text={'Cargando datos...'} />;
    }
    return (
        <WordpressPostsChart
            wordpressPosts={wordpressPosts}
            userDataAlias={userData.alias}
        />
    );
};

export default MeGrafica;
