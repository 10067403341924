import axios from 'axios';
import { getLocalStorage } from './functions';
import { API_REST_HD } from '../env';
// https://cpmlink.net/api/t4oBAA?s=yourdestinationlink.com
// https://shrinkme.io/api?api=11acb8b2e9df1c59e70c5d0c01fce54dd8500ee9&url=https://shrinke.me/IfI9YY&format=text
// https://exe.io/api?api=942ff6c35b2341f7efccd565ba3c04adf0ae2468&url=https://google.com&format=text

// const userData = JSON.parse(localStorage.getItem('user'));
const userData = getLocalStorage('user');

const { ouo, cpmlink, shrinkme, exe, fc } = userData || {};

const headers = { 'Content-Type': 'text/plain' };

const APIS = {
    ouo: {
        host: 'http://ouo.io/api/',
        key: `${ouo}?s=`
    },
    cpmlink: {
        host: 'https://cpmlink.net/api/',
        key: `${cpmlink}?s=`
    },
    shrinkme: {
        host: 'https://shrinkme.io/api?format=text&api=',
        key: `${shrinkme}&url=`
    },
    exe: {
        host: 'https://exe.io/api?format=text&api=',
        key: `${exe}&url=`
    },
    fc: {
        host: 'https://fc.lc/api?api=',
        key: `${fc}&url=`
    }
};

export const acortadorCustom3 = async (url, nombreAcortador) => {
    // const apiUrl = `https://api.allorigins.win/get?url=${encodeURIComponent(
    //     `${nombreAcortador}${encodeURIComponent(url)}`
    // )}`;
    const apiUrl = `${API_REST_HD}acortar?urll=${encodeURIComponent(
        `${nombreAcortador}${encodeURIComponent(url)}`
    )}`;

    const { data } = await axios.get(apiUrl, {
        withCredentials: false
    });

    // const { data } = await axios(
    //     `https://api.allorigins.win/get?url=${nombreAcortador}${url}`,
    //     {
    //         method: 'GET',
    //         withCredentials: false
    //     }
    // );
    return data;
};
export const acortadorCustom = async (url, nombreAcortador) => {
    const API_KEY = APIS[nombreAcortador].key;
    const HOST = APIS[nombreAcortador].host;

    const { data } = await axios(
        `https://cors-proxy.hdlatino.us/${HOST}${API_KEY}${url}`,
        {
            method: 'GET',
            withCredentials: false
            // mode: 'no-cors',
            // headers: {
            //     'Access-Control-Allow-Origin': '*',
            //     'Content-Type': 'application/json'
            // },

            // credentials: 'same-origin'
        }
    );
    return data;
};
export const acortadorCustom2 = async (url, nombreAcortador) => {
    const API_KEY = APIS[nombreAcortador].key;
    const HOST = APIS[nombreAcortador].host;

    const { data } = await axios(
        `http://worldtimeapi.org/api/timezone/America/Bogota`,
        {
            method: 'GET'
        }
    );
    return data;
};

export const acortadorHdLatino = async url => {
    try {
        const userId = '64b5de5b2daa8a2bfd7c287c'; // Reemplaza con el ID de usuario proporcionado por 'https://adclicker.io/url/'
        const landing = 'https://adclicker.io/url/'; // URL base del servicio

        // Construye la URL para acortar utilizando los parámetros requeridos por 'https://adclicker.io/url/'
        const urlApi = `api=${userId}&url=${encodeURIComponent(url)}`;
        const urlAcortada = `${landing}#${btoa(btoa(btoa(urlApi)))}`;

        return urlAcortada;
    } catch (error) {
        console.error('Error al acortar la URL:', error.message);
        return url; // Devolver la URL original en caso de error
    }
};
