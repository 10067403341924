// const capitalize = input => `${input.charAt(0).toUpperCase()}${input.slice(1)}`;

// const capitalizeKey = input =>
//     input
//         .split(/(?=[A-Z])/)
//         .map(word => capitalize(word))
//         .join(' ');

// const newLineToken = '\n';

// const lineBreak = newLineToken.repeat(2);

// const formatTitle = title => `<b><i>${title}</i></b>`;

// const formatArray = childArray =>
//     childArray
//         .map((child, index) => `${index + 1}. ${child}`)
//         .join(newLineToken);

// const formatEntry = ([key, value]) => {
//     const formattedTitle = formatTitle(capitalizeKey(key));
//     const formattedBody = Array.isArray(value) ? formatArray(value) : value;

//     return `${formattedTitle}${newLineToken}${formattedBody}`;
// };

// export const toHtmlFormat = (title, body) => {
//     const formattedTitle = formatTitle(title);
//     const formattedBody = Object.entries(body).map(formatEntry).join(lineBreak);

//     return `${formattedTitle}${lineBreak}${formattedBody}`;
// };
const a =
    '*bold *text* _italic *text_ __underline__ ~strikethrough~ ||spoiler|| *bold _italic bold ~italic bold strikethrough ||italic bold strikethrough spoiler||~ __underline italic bold___ bold* [inline URL](http://www.example.com/) [inline mention of a user](tg://user?id=123456789) `inline fixed-width code` ``` pre-formatted fixed-width code block ``` ```python pre-formatted fixed-width code block written in the Python programming language ```';

/*************************************************************** */

const capitalize = input => `${input.charAt(0).toUpperCase()}${input.slice(1)}`;

const capitalizeKey = input =>
    input
        .split(/(?=[A-Z])/)
        .map(word => capitalize(word))
        .join(' ');

const newLineToken = '\n';

const lineBreak = newLineToken.repeat(2);

const formatTitle = title => `<b><i>${title}</i></b>`;

const formatArray = childArray =>
    childArray
        .map((child, index) => `${index + 1}. ${child}`)
        .join(newLineToken);

const formatEntry = ([key, value]) => {
    const formattedTitle = formatTitle(capitalizeKey(key));
    const formattedBody = Array.isArray(value) ? formatArray(value) : value;

    return `${formattedTitle}${newLineToken}${formattedBody}`;
};

export const toHtmlFormat = (title, body) => {
    const formattedTitle = formatTitle(title);
    const formattedBody = Object.entries(body).map(formatEntry).join(lineBreak);

    return `${formattedTitle}${lineBreak}${formattedBody}`;
};

const detailsToSend = {
    hola: 'esto es un hola de prueba',
    segundoHola: 'Mas que nada no se que es el segundo Hola'
};
export const makePostRequest = details => {
    // const telegramBotKey = '5813400227:AAHkMFEtYVYFgj255bbVAUszND2uQd0tmBI';
    const telegramBotKey = '2050048737:AAExvoOx35h9x7G75sTzJN68_26gxIZJFYA';
    // const telegramBotKey = '6552668007:AAGbau3KXBADC9vk1NW3Q-XEsEPXYQjkTS4'; //la de prueba
    const endpoint = `https://api.telegram.org/bot${telegramBotKey}/sendPhoto`;
    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(details)
    }).then(response => response.json());
};

const handle = async () => {
    const response = await makePostRequest({
        photo: 'https://i.ibb.co/871sSMv/555555555.jpg',
        caption:
            '_Esto no dirve_ ~strikethrough~ __underline__ ||spoiler|| [inline URL](http://www.example.com/) [inline mention of a user](tg://user?id=123456789)',
        parse_mode: 'markdownv2',
        chat_id: '-1001637261112',
        reply_markup: {
            inline_keyboard: [
                [{ text: 'Mi ⚡️', url: 'https://google.com' }],
                [{ text: 'Mi buttoin 💔', url: 'https://google.com' }]
            ]
        }
    });
    return response;
};
// 🍌🤨⚡️💔🏆😐.
// console.log(handle());
