import { Chip } from '@mui/material';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import CustomMaterialMenu from '../components/CustomMaterialMenu/CustomMaterialMenu';
import ButtonsColeections from '../components/multipasteButtons/ButtonsColeections';

export const DataTableConfig = (type, handleDeletePost) => {
    let columns = [];

    if (type === 'multipaste') {
        columns = [
            // {
            //     cell: () => <AppsIcon style={{ fill: '#43a047' }} />,
            //     width: '56px', // custom width for icon button
            //     style: {
            //         borderBottom: '1px solid #FFFFFF',
            //         marginBottom: '-1px'
            //     }
            // },
            // {
            //     name: 'ID',
            //     selector: row => row.id,
            //     sortable: true,
            //     width: '80px',
            //     style: {
            //         color: '#202124',
            //         fontSize: '14px',
            //         fontWeight: 500
            //     },
            //     wrap: true
            //     // format: row => `${row.id.slice(0, 200)}`
            // },
            {
                name: 'ID',
                selector: row => row.pasteID,
                sortable: true,
                width: '91px',
                grow: 1,
                style: {
                    color: '#202124',
                    fontSize: '14px',
                    fontWeight: 500
                }
            },
            {
                name: 'TITULO',
                selector: row => row.Titulo,
                sortable: true,
                grow: 5,
                style: {
                    color: '#202124',
                    fontSize: '14px',
                    fontWeight: 500
                },
                wrap: true
                // format: row => `${row.title.slice(0, 200)}`
            },
            {
                name: 'VIEWS',
                selector: row => row.views,
                sortable: true,
                grow: 2,
                style: {
                    color: '#202124',
                    fontSize: '14px',
                    fontWeight: 500
                }
            },
            {
                name: 'TIPO',
                selector: row => (row.vip === '0' ? 'Publico' : 'VIP'),
                sortable: true,
                wrap: true,
                // grow: 2,
                style: {
                    color: '#202124',
                    fontSize: '14px',
                    fontWeight: 500
                }
            },
            {
                cell: row => (
                    <ButtonsColeections
                        row={row}
                        handleDeletePost={handleDeletePost}
                        multipaste
                    />
                ),
                // allowOverflow: true,
                // button: true,
                width: '300px'
            }
        ];
    }

    return columns;
};
