// export const API_REST = 'http://localhost:4000/api/v1/users';
export const REACT_APP_APIKEY_THEMOVIES = 'be58b29465062a3b093bc17dacef8bf3';
export const REACT_APP_IMGUNR_CLIENT_ID = '7939ac876f141b0';
export const REACT_APP_DOMINIO_APITHEMOVIES = 'https://api.themoviedb.org/3/';
export const REACT_APP_API_REST_WP = 'https://vip.hdlatino.us/wp-json/wp/v2';
export const REACT_APP_API_REST_WP_JWT =
    'https://vip.hdlatino.us/wp-json/jwt-auth/v1/token';
// export const API_REST = 'http://localhost:4001/api/v1/users';
export const API_REST = 'https://apipost2.hdlatino.us/api/v1/users';
export const API_REST_HD = 'https://apipost1.hdlatino.us/api/v1/users/';
export const API_JUSTWHATCH = 'https://apipost1.hdlatino.us/api/v1/juswatch';
export const API_PLANTILLAS = 'https://apipost1.hdlatino.us/api/v1/plantillas';
export const API_PLANTILLAS_PLUS =
    'https://apipost1.hdlatino.us/api/v1/plantillas-extras';
export const API_REST_UNIVERSAL = 'https://apipost1.hdlatino.us/api/v1';
export const API_REST_UNIVERSAL_SOCKET = 'https://apipost1.hdlatino.us';
// export const API_REST_UNIVERSAL = 'http://localhost:4000/api/v1';
// export const API_PLANTILLAS = 'http://localhost:4000/api/v1/plantillas';
// export const API_REST_HD = 'http://localhost:4000/api/v1/users/';

export const API_REST_PASTE = 'https://vip.hdlatino.us/pastes/api';
export const API_REST_PEDIDOS =
    'https://apipost1.hdlatino.us/posts/api/pedidos';
// export const API_REST_PASTE = 'http://multipaste.test/api';
export const API_KEY_PASTE =
    'bc1pssax9hs02227xmkcxterglg6lljnvvlj8ndvwpqxldsd6g8re6qqvxd899bc1qve9gwcmlsv8srgkx9zd587ta98jkwcvqjsvurnhz7f3xydcr67yrdrmhn97lgl4hemkkz0x3dw05skvshfk8kfv5yqjszzt7';
