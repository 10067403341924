import React, { useEffect, useState } from 'react';
import { deleteServer, postCreateServer } from '../services/serversServices';
import { toast } from 'react-toastify';
import { API_REST_UNIVERSAL } from '../../../env';
import { useFetch } from '../../../hooks/useFetch';
import { useServerStore } from '../store/ServersStroe';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { createArrayServersToStep3 } from '../../../utils/functions';

const useFormChild = () => {
    const [loading, setLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [servidores, setServidores] = useLocalStorage('servidores', []);
    const [data, setServer, setLoadingServer] = useServerStore();
    const { data: mydata } = useFetch(
        `${API_REST_UNIVERSAL}/servers/`,
        {
            repetir: refetch
        },
        setLoadingServer
    );

    const handleSubmit = async values => {
        try {
            setLoading(true);
            const dataToSend = {
                name: values.nombreServidor,
                url: values.urlServidor,
                url_image_free: values.urlImagenFree,
                url_image_vip: values.urlImagenVip
            };

            const res = await postCreateServer(dataToSend);
            setRefetch(prevRefetch => !prevRefetch);

            console.log(res);
            toast('Servidor creador correctamente', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
            setLoading(false);
        } catch (error) {
            toast.error(
                'Error al crear servidor: ' + error?.response?.data?.message,
                {
                    position: 'top-center',
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                }
            );
            setLoading(false);
            return error;
        }
    };

    const handleDeleteServer = async id => {
        try {
            // setLoading(true);
            const res = await deleteServer(id);
            setRefetch(prevRefetch => !prevRefetch);
            console.log(res);
            toast('Servidor eliminado correctamente', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
            // setLoading(false);
        } catch (error) {
            toast.error(
                'Error al eliminar servidor: ' + error?.response?.data?.message,
                {
                    position: 'top-center',
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                }
            );
            // setLoading(false);
            return error;
        }
    };

    useEffect(() => {
        setServer(mydata);
        setServidores(createArrayServersToStep3(mydata));
    }, [mydata]);

    return { handleSubmit, handleDeleteServer, loading, refetch, data };
};

export default useFormChild;
