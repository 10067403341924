import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const SearchGoogleImg = ({ show = false, setModalShow }) => {
    // const [modalShow, setModalShow] = useState(show);
    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://cse.google.com/cse.js?cx=a44cd587fd0072f0f';
        script.async = true;

        document.body.appendChild(script);
    }, [show]);

    return (
        <Modal
            size="lg"
            show={show}
            onHide={() => setModalShow(false)}
            fullscreen={'lg-down'}
            // size="lg"
            // dialogClassName="modal-90w"
            // aria-labelledby="contained-modal-title-vcenter"
            aria-labelledby="example-custom-modal-styling-title"
            centered>
            <div className="gcse-search"></div>
        </Modal>
    );
};

export default SearchGoogleImg;
