import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import images from '../../assets/images';
import useLocalStorage from '../../hooks/useLocalStorage';
import {
    arrayVacio,
    getUserInfo,
    LoginWP,
    searchDataUser,
    verificarRol
} from '../../utils/functions';
import './login.css';
import toast, { Toaster } from 'react-hot-toast';
import { consultarUser, createUser } from '../../utils/apihdlatino';
import { Buffer } from 'buffer';
import axios from 'axios';

/******************************************************* */

const Login = () => {
    const [isAuthenticated, setIsAuthenticated] = useLocalStorage(
        'isAuthenticated',
        false
    );
    const [loading, setLoading] = useState(false);
    const [myuser, setMyUser] = useLocalStorage('user', false);
    const [user, setUser] = useState({
        username: '',
        password: ''
    });
    const { username, password } = user;

    const users = [
        {
            username: 'admin',
            password: 'admin'
        }
    ];

    const handleChange = e => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        const data = await getUserInfo(
            e.target.username.value,
            e.target.password.value
        ).catch(error => {
            setLoading(false);
            toast.error(`${error.response.data.message}`, {
                duration: 12000
            });
        });
        if (data === undefined) {
            return;
        }

        const keywp = Buffer.from(
            `${e.target.username.value}:${e.target.password.value}`
        ).toString('base64');

        let rol;
        const roles = await verificarRol(keywp).catch(err => {
            rol = err.response.status !== 200 ? 'autor' : 'admin';
        });
        if (roles !== undefined) {
            rol = 'admin';
        }

        // const users = await searchDataUser();

        // console.log('users.data', users);

        // const user = users.data.filter(
        //     item => item.slug === e.target.username.value.toLowerCase()
        // );

        // if (arrayVacio(user)) {
        //     toast.error(
        //         'No se pudo encontrar el usuario, valide que tenga un post',
        //         {
        //             duration: 20000
        //         }
        //     );
        //     setLoading(false);
        //     return;
        // }

        toast.success('Exito!');

        // const { slug, id, name } = user[0];
        const { nicename, id, firstName } = data.data.data;

        const dataUser = await consultarUser(nicename);

        if (dataUser.status !== 200) {
            const values = {
                username: nicename,
                password: '',
                alias: firstName,
                id_wordpress: id
            };

            const dataUserCreado = await createUser(values);

            if (dataUserCreado.status !== 200) {
                toast.error(`Error: ${dataUserCreado.response.data.message}`, {
                    duration: 12000
                });
                setLoading(false);
                return true;
            }

            setMyUser({
                ...dataUserCreado.data,
                keywp: keywp,
                roles: rol
            });
            setIsAuthenticated(true);
            setLoading(false);
            return;
        }
        setMyUser({
            ...dataUser.data,
            keywp: Buffer.from(
                `${e.target.username.value}:${e.target.password.value}`
            ).toString('base64'),
            roles: rol
        });
        setIsAuthenticated(true);
        setLoading(false);
        return;
    };
    useEffect(() => {
        if (isAuthenticated) {
            window.location.href = '/dashboard';
        }
    }, [isAuthenticated]);

    return (
        <>
            <section className="vh-100">
                <div className="backdrop">
                    <div className="Image">
                        {/* <figure>
                            <img src={images.background} alt="background" />
                        </figure> */}
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="container py-5 h-100">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                                <div
                                    className="card bg-vino text-white"
                                    style={{ borderRadius: '1rem' }}>
                                    <div className="card-body px-4 text-center">
                                        <div>
                                            <div>
                                                <img
                                                    src={images.logo}
                                                    alt=""
                                                    width="190"
                                                    height="190"
                                                />
                                            </div>
                                            <div>
                                                <img
                                                    src={images.banner}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="form-outline form-white mb-4">
                                                <input
                                                    type="text"
                                                    id="typeEmailX"
                                                    className="form-control form-control-lg"
                                                    placeholder="Usuario"
                                                    value={username}
                                                    onChange={handleChange}
                                                    name="username"
                                                />
                                            </div>
                                            <div className="form-outline form-white mb-4">
                                                <input
                                                    type="password"
                                                    id="typePasswordX"
                                                    className="form-control form-control-lg"
                                                    placeholder="Contraseña"
                                                    value={password}
                                                    onChange={handleChange}
                                                    name="password"
                                                />
                                            </div>
                                            <button
                                                className="btn btn btn-warning px-5"
                                                type="submit">
                                                Login
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    hidden={!loading}
                                                    className="mx-2"
                                                />
                                            </button>
                                            <div className="header_login">
                                                <img
                                                    src={images.header}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Toaster position="bottom-center" reverseOrder={false} />
            </section>
        </>
    );
};

export default Login;
