import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import {
    deleteReportPost,
    getNotifications
} from '../service/getNotifications';
import ShowButtons from '../../FicharPost/components/ShowButtons';
import { esJSON, getLocalStorage } from '../../../utils/functions';
import { motivoOptions } from '../../../constants';
import ShowButtonReportPost from './ShowButtonReportPost';
import { Typography } from '@mui/material';

const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página:',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
};

const PostReportados = () => {
    const userData = getLocalStorage('user');
    const [posts, setPosts] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const handleDeletePost = async (PostId, handleShow) => {
        setLoading(true);
        await deleteReportPost(PostId);

        setPosts(posts.filter(item => item.ReportedPost.id !== PostId));
        setLoading(false);
        handleShow();
    };

    const columns = [
        {
            name: 'Motivo',
            selector: row => motivoOptions[row.ReportedPost.rep_title],
            sortable: true,
            wrap: true
        },
        {
            name: 'Titulo',
            selector: row => (
                <Typography
                    sx={{
                        textDecoration: 'none',
                        fontSize: 'inherit',
                        color: 'inherit'
                    }}
                    component={'a'}
                    target="_blank"
                    href={row.ReportedPost.rep_url}>
                    {row.ReportedPost.rep_content}
                </Typography>
            ),
            sortable: true,
            wrap: true,
            width: '400px'
        },
        {
            name: 'Uploader',
            selector: row => row.ReportedPost.rep_uploader,
            sortable: true,
            wrap: true
        },
        {
            name: 'Descripción',
            selector: row => esJSON(row.ReportedPost.rep_description)?.text,
            sortable: true,
            wrap: true
        },

        {
            cell: row => (
                <ShowButtonReportPost
                    owner={row.ReportedPost.rep_uploader === userData.alias}
                    id={row.ReportedPost.id}
                    handleDeletePost={handleDeletePost}
                    loading={loading}
                    postID={esJSON(row.ReportedPost.rep_description)?.postid}
                    url={row.ReportedPost.rep_url}
                />
            ),
            wrap: true
        }
    ];

    const conditionalRowStyles = [
        {
            when: row => row.apar_uploader !== userData.alias,
            style: {
                cursor: 'default'
            }
        },
        {
            when: row => row.apar_completed,
            style: {
                borderRadius: '15px',
                backgroundColor: '#2D9596',
                color: 'white'
            }
        }
    ];

    const fetchPosts = async page => {
        setLoading(true);

        const response = await getNotifications(userData.id);
        const resfilter = response.filter(
            item => item.ReportedPost.rep_typeNotify === 'post_reported'
        );

        setPosts(resfilter);
        setData(resfilter);
        setTotalRows(resfilter.length);
        setLoading(false);
    };

    useEffect(() => {
        fetchPosts(); // fetch page 1 of users
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchPosts();
        }, 8000);

        return () => clearInterval(intervalId);
    }, [posts]);

    return (
        <DataTable
            fixedHeaderScrollHeight="650px"
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationTop
            title="Post Reportados"
            columns={columns}
            data={posts}
            customStyles={customStyles}
            conditionalRowStyles={conditionalRowStyles}
            highlightOnHover
            pointerOnHover
        />
    );
};

export default PostReportados;

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#F0F3FF',
            borderBottomColor: '#FFFFFF',
            borderRadius: '15px',
            outline: '1px solid #FFFFFF'
        }
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '14px'
        }
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '15px',
            outline: '1px solid #FFFFFF'
        },
        style: {
            fontSize: '14px'
        }
    },
    pagination: {
        style: {
            border: 'none'
        }
    }
};
