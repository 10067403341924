import React, { useEffect, useState } from 'react';
import { getCategoriaWordpress, getpostsWordpress } from '../../utils/apiwp';
import {
    ceil10,
    extraerImdbPlantilla,
    getCategoriesWordpress
} from '../../utils/functions';
import { getMovieImdb } from '../../utils/themvoies';
import ListPost from './ListPost';

const addImdbInData = async arrayData => {
    const data1 = arrayData?.map(post => ({
        ...post,
        imdb: extraerImdbPlantilla(post?.content?.rendered)
    }));

    let data2 = [];

    for (let i = 0; i < data1.length; i++) {
        const tmdb = await getMovieImdb(data1[i]?.imdb);
        if (tmdb) {
            data2.push({ ...data1[i], tmdb: tmdb[0]?.id });
        } else {
            data2.push({ ...data1[i], tmdb: null });
        }
    }

    // obj[0]._embedded['wp:term'][0][0].name

    let data3 = [];
    for (let i = 0; i < data2.length; i++) {
        let categoriasAndEtiquetas = [];
        for (let ii = 0; ii < data2[i]._embedded['wp:term'].length; ii++) {
            for (
                let iii = 0;
                iii < data2[i]._embedded['wp:term'][ii].length;
                iii++
            ) {
                categoriasAndEtiquetas.push(
                    data2[i]._embedded['wp:term'][ii][iii]?.name
                );
            }
        }
        data3.push({
            ...data2[i],
            categorias_Etiquetas: categoriasAndEtiquetas
        });
    }

    return data3;
};

const Post = ({ borradores = false, eliminados = false, trash = false }) => {
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [arrayPost, setArrayPost] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async (page, perPage) => {
        setLoading(true);
        const { headers, data } = await getpostsWordpress(
            page,
            perPage,
            borradores,
            eliminados,
            trash
        );
        const dataPreparada = await addImdbInData(data);

        setArrayPost(dataPreparada);
        setTotalRows(headers['x-wp-total']);
        setLoading(false);
    };
    useEffect(async () => {
        fetchData(1, perPage);
        // const cat = await getCategoriesWordpress('categories');
        // console.log('categorias', cat);
    }, [perPage]);

    const handlePageChange = page => {
        fetchData(page, perPage);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const { data } = await getpostsWordpress(
            page,
            perPage,
            borradores,
            eliminados,
            trash
        );
        const dataPreparada = await addImdbInData(data);

        setArrayPost(dataPreparada);
        setPerPage(newPerPage);
        setLoading(false);
    };

    console.log(ceil10(440, 1));

    return (
        <ListPost
            arrayPost={arrayPost}
            totalRows={totalRows}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
            loading={loading}
        />
    );
};

export default Post;
