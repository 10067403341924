import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import FormCrearNotas from './components/FormCrearNotas';
import CardNota from './components/CardNota';
import useNotes from './hooks/useNotes';

const NotasLayout = () => {
    const {
        notas,
        loading,
        handleSubmit,
        handleGetNotes,
        handleDelete,
        handleUpdateNota
    } = useNotes();

    useEffect(() => {
        handleGetNotes();
    }, []);
    return (
        <>
            <FormCrearNotas
                loading={loading}
                handleSubmit={handleSubmit}
                handleUpdateNota={handleUpdateNota}
            />
            <Box sx={{ flexGrow: 1, width: '100%', mt: 2 }}>
                <Grid
                    container
                    spacing={{ xs: 2, md: 1 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}>
                    {notas?.map((note, index) => (
                        <Grid xs={2} sm={4} md={3} key={index}>
                            <>
                                <CardNota
                                    text={note.not_text}
                                    id={note.id}
                                    handleDelete={handleDelete}
                                />
                            </>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default NotasLayout;
