import axios from 'axios';
import { REACT_APP_APIKEY_THEMOVIES } from '../../env';

class MovieDBAPI {
    constructor() {
        this.apiKey = REACT_APP_APIKEY_THEMOVIES;
        this.baseUrl = 'https://api.themoviedb.org/3';
    }

    async searchMovie(query) {
        try {
            const response = await axios.get(`${this.baseUrl}/search/movie`, {
                params: {
                    api_key: this.apiKey,
                    query: query,
                    language: 'es-ES'
                }
            });
            return response.data.results;
        } catch (error) {
            console.error('Error searching movie:', error);
            return [];
        }
    }

    async searchTVSeries(query) {
        try {
            const response = await axios.get(`${this.baseUrl}/search/tv`, {
                params: {
                    api_key: this.apiKey,
                    query: query,
                    language: 'es-ES'
                }
            });
            return response.data.results;
        } catch (error) {
            console.error('Error searching TV series:', error);
            return [];
        }
    }

    async searchCollection(query) {
        try {
            const response = await axios.get(
                `${this.baseUrl}/search/collection`,
                {
                    params: {
                        api_key: this.apiKey,
                        query: query,
                        language: 'es-ES'
                    }
                }
            );
            return response.data.results;
        } catch (error) {
            console.error('Error searching collection:', error);
            return [];
        }
    }

    async getDetails(type, id) {
        try {
            const response = await axios.get(`${this.baseUrl}/${type}/${id}`, {
                params: {
                    api_key: this.apiKey,
                    language: 'es-ES'
                }
            });
            return response.data;
        } catch (error) {
            console.error(
                `Error getting details for ${type} with id ${id}:`,
                error
            );
            return null;
        }
    }
}

// Ejemplo de uso:
// export default MovieDBAPI;

const movieDB = new MovieDBAPI();
export default movieDB;

// (async () => {
//   const movies = await movieDB.searchMovie('The Matrix');
//   console.log('Movies:', movies);

//   const tvSeries = await movieDB.searchTVSeries('Breaking Bad');
//   console.log('TV Series:', tvSeries);

//   const collections = await movieDB.searchCollection('Harry Potter');
//   console.log('Collections:', collections);

//   if (movies.length > 0) {
//     const movieDetails = await movieDB.getDetails('movie', movies[0].id);
//     console.log('Movie Details:', movieDetails);
//   }
// })();
