import { Store } from 'pullstate';

const ServerStore = new Store({
    data: [],
    loadingServerStore: false
});

const setServer = data => {
    ServerStore.update(s => {
        s.data = data;
    });
};
const setLoadingServer = loading => {
    ServerStore.update(s => {
        s.loadingServerStore = loading;
    });
};

function useServerStore() {
    const data = ServerStore.useState(s => s.data);
    const loadingServerStore = ServerStore.useState(
        ss => ss.loadingServerStore
    );

    return [data, setServer, setLoadingServer, loadingServerStore];
}

export { useServerStore };
