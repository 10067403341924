import { Box, Container, Grid, Stack } from '@mui/material';
import React, { useState } from 'react';
import DragAndDrop from '../DragArea/DragAndDrop';
import { ReactSortable } from 'react-sortablejs';
import ImageWithDeleteButton from '../ImageCropper/ImageWithDeleteButton ';
import useCropper from '../ImageCropper/hooks/useCropper';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';

const SubirPortadas = () => {
    const { handleSaveCrop, setImageCropUrl } = useCropper();
    const [images, setImages] = useState([]);
    const [imagesSelected, setImagesSelected] = useState([]);
    const [loading, setLoading] = useState(false);

    // componente para renderizar una imagen
    const renderImg = objimg => (
        <Box
            component="img"
            alt={objimg.name}
            title={objimg.name}
            src={URL.createObjectURL(objimg)}
            sx={{
                cursor: 'pointer',
                border: imagesSelected.includes(objimg)
                    ? '2px solid blue'
                    : 'none'
            }}
            onClick={() => handleImageClick(URL.createObjectURL(objimg))}
        />
    );

    // borrar imagen seleccionada
    const handleDelecteImgSelected = index => {
        setImagesSelected(imagesSelected.filter((_, i) => i !== index));
    };

    // capturar imagenes cargadas
    const handleChange = e => {
        const files = Array.from(e.target.files);
        const imageFiles = files.filter(file => file.type.startsWith('image/'));
        setImages(imageFiles);
        console.log('imageFiles', imageFiles);
    };

    // seleccionar imagenes a enviar
    const handleImageClick = img => {
        if (imagesSelected.includes(img)) {
            setImagesSelected(imagesSelected.filter(image => image !== img));
        } else {
            setImagesSelected([...imagesSelected, img]);
        }
    };

    //Setear imagenes para subir
    const handleSubmit = async () => {
        setLoading(true);
        setImageCropUrl(() => imagesSelected);
        await handleSaveCrop(imagesSelected);
        setLoading(false);
    };
    return (
        <Container>
            <Stack direction={'row'} gap={1}>
                <Box width={'100%'}>
                    <Box>
                        <DragAndDrop
                            handleChange={e => handleChange(e)}
                            title="Agregar Capturas"
                            accept="image/*"
                            isFolder
                        />
                    </Box>
                    <Grid container width={'100%'} spacing={2} mt={1}>
                        {images.map((img, index) => (
                            <Grid item key={index} xs={12} sm={6} md={4}>
                                {renderImg(img)}
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Stack width={'30%'} mt={3} gap={1}>
                    <LoadingButton
                        // size="small"
                        onClick={handleSubmit}
                        endIcon={<SendIcon />}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained">
                        <span>Enviar Capturas</span>
                    </LoadingButton>

                    <ReactSortable
                        animation={200}
                        list={imagesSelected}
                        setList={setImagesSelected}>
                        {imagesSelected?.map((url, index) => (
                            <>
                                <Box key={index}>
                                    <ImageWithDeleteButton
                                        imageUrl={url}
                                        onClick={() =>
                                            handleDelecteImgSelected(index)
                                        }
                                    />
                                    {/* <img src={url} alt="" /> */}
                                </Box>
                            </>
                        ))}
                    </ReactSortable>
                </Stack>
            </Stack>
        </Container>
    );
};

export default SubirPortadas;
