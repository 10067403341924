import React from 'react';
import { crearNotas, deleteNota, getNotas, updateNota } from '../services/api';
import { useNotasStore } from '../store/store';

const useNotes = () => {
    const [loading, setLoading] = React.useState(false);
    const [notes, setNotes] = React.useState([]);
    const { notas, setNotas, setNotaEdit } = useNotasStore();

    const handleGetNotes = async () => {
        const notes = await getNotas();
        setNotas(notes?.data);
    };

    const handleSubmit = async values => {
        setLoading(true);
        await crearNotas(values.nota);
        handleGetNotes();
        setLoading(false);
    };

    const handleDelete = async id => {
        // setLoading(true);
        await deleteNota(id);
        handleGetNotes();
        // setLoading(false);
    };

    const handleUpdateNota = async values => {
        // setLoading(true);
        await updateNota(values);
        handleGetNotes();
        setNotaEdit({});
        // setLoading(false);
    };

    return {
        notas,
        loading,
        handleSubmit,
        handleGetNotes,
        handleDelete,
        handleUpdateNota
    };
};

export default useNotes;
