import React, { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom';
import { useStore } from '../../store/StoreProvider';
import { getSearchYoutube } from '../../utils/youtube';

const YoutubeSearch = () => {
    const { movie } = useStore();

    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState();
    const { typeSearch } = movie || {};

    const handleSearch = async query => {
        setIsLoading(true);
        const results = await getSearchYoutube(query);
        setOptions(results?.items);
        setIsLoading(false);
    };

    const textABuscar =
        typeSearch === '1'
            ? `Trailer ${movie?.original_title} ${movie?.year}`
            : `Trailer Serie ${movie?.original_title} ${movie?.year}`;

    return (
        <>
            {movie?.original_title && (
                <div className="col">
                    <label htmlFor="searchbox">Buscar video</label>
                    <form className="searchbox">
                        <AsyncTypeahead
                            defaultInputValue={textABuscar}
                            ignoreDiacritics={true}
                            isLoading={isLoading}
                            emptyLabel="No se encontraron resultados"
                            labelKey={option => `${option?.snippet?.title}`}
                            id="search"
                            onChange={selected => setSelected(selected[0])}
                            onSearch={query => {
                                handleSearch(query);
                            }}
                            options={options}
                        />
                    </form>

                    <div className="d-flex flex-wrap mx-auto mt-3">
                        <div className="col-12 mb-4 player">
                            <div className="embed-responsive embed-responsive-16by9">
                                {/* first item of playlist */}
                                <iframe
                                    id={selected?.id?.videoId}
                                    className="embed-responsive-item"
                                    frameBorder={0}
                                    // width={560}
                                    height={315}
                                    src={
                                        selected
                                            ? `https://www.youtube.com/embed/${selected?.id?.videoId}`
                                            : `https://www.youtube.com/embed/${options[0]?.id?.videoId}`
                                    }
                                    allowFullScreen
                                />
                            </div>
                        </div>
                        {/*/.player*/}
                        <div className="col-12 text-center playlist">
                            <div className="row">
                                {options?.map(option => (
                                    <Link
                                        key={option.id.videoId}
                                        to="#"
                                        className="col-6 mb-3"
                                        onClick={() => setSelected(option)}>
                                        <img
                                            className="img-fluid w-100"
                                            src={
                                                option?.snippet?.thumbnails
                                                    ?.high?.url
                                            }
                                            alt=""
                                        />
                                        <div className="card-body py-2">
                                            <small>
                                                {option?.snippet?.title}
                                            </small>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                        {/*/.playlist*/}
                    </div>
                </div>
            )}
        </>
    );
};

export default YoutubeSearch;
