import React, { useState, useEffect } from 'react';
import { MyForm } from './MyForm';
import DescriptionAlerts from './DescriptionAlerts';
import { socket } from '../../socket';
import useNotification from './hook/useNotification';

export default function AppNotify() {
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [fooEvents, setFooEvents] = useState([]);
    const [event, setEvent] = useState();
    const { notificationsAll } = useNotification();

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
        }

        function onFooEvent(value) {
            // console.log('fooEvents', fooEvents);
            setFooEvents(previous => [...previous, value]);
            setEvent(value);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('report-post-notification-response', onFooEvent);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('report-post-notification-response', onFooEvent);
        };
    }, []);

    console.log('event', event);

    return (
        <div className="Appp">
            {/* {JSON.stringify(isConnected)} */}
            {notificationsAll.length > 0 && (
                <DescriptionAlerts
                    event={event}
                    notificationsAll={notificationsAll}
                />
            )}
            {/* <MyForm /> */}
        </div>
    );
}
