import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import AlarmIcon from '@mui/icons-material/Alarm';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function IconButtons({ handleNext, handlePrevious }) {
    return (
        <Stack
            direction="row"
            sx={{
                justifyContent: 'space-between'
            }}>
            <div>
                <IconButton
                    aria-label="back"
                    // color="primary"
                    onClick={handlePrevious}>
                    <ArrowBackIosIcon />
                </IconButton>
            </div>
            <div>
                <IconButton aria-label="next" onClick={handleNext}>
                    <ArrowForwardIosIcon />
                </IconButton>
            </div>
        </Stack>
    );
}
