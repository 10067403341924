import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import images from '../../assets/images';
import TitleIcons from '../titleIcons/TitleIcons';

const TabsSearch = ({ setActiveTab, activeTab, children }) => {
    return (
        <Tabs
            // defaultActiveKey="peliculas_imdb"
            onSelect={e => setActiveTab(e)}
            id="justify-tab-example"
            // activeKey={activeTab ? 'peliculas_justWhtach' : 'peliculas_imdb'}
            activeKey={activeTab}
            className="mb-3 d-flex flex-wrap align-items-center justify-content-center">
            {children}
        </Tabs>
    );
};

export default TabsSearch;
