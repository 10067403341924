import React from 'react';
import { useSearchFicharPost } from '../store/store';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    MenuItem,
    Stack
} from '@mui/material';
import { Form, Formik } from 'formik';
import {
    eliminarPrimeraI,
    getLocalStorage,
    obtenerLabelResolucion,
    renderUrl
} from '../../../utils/functions';
import CustomTextField from './CustomTextField';
import metadata from '../../../metadata.json';
import { getCreatePostApartados } from '../service/services';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { createPostSeparetedtNotification } from '../../Notification/service/getNotifications';

const OBJDATA = [
    {
        id: 1,
        name: 'Calidad',
        key: 'calidad',
        type: 'select',
        options: metadata[0].data.map(item => ({
            label: item.label,
            value: item.value
        }))
    },
    {
        id: 2,
        name: 'Resolucion',
        key: 'resolucion',
        type: 'select',
        options: metadata[1].data.map(item => ({
            label: item.label,
            value: item.value
        }))
    },
    {
        id: 3,
        name: 'Idioma',
        key: 'idioma',
        type: 'select',
        options: metadata[2].data.map(item => ({
            label: item.label,
            value: item.value
        }))
    },
    {
        id: 4,
        name: 'Uploader',
        key: 'uploader',
        type: 'text'
    }
];

const FicharForm = () => {
    const { details, setDetails, setPosts, posts, type } =
        useSearchFicharPost();
    const userData = getLocalStorage('user');
    const [loading, setLoading] = React.useState(false);

    const handleSubmit = async values => {
        const data = {
            poster: details.poster_path,
            tmdb: details.id,
            titulo: details.title || details.name,
            calidad: values.calidad,
            resolucion: values.resolucion,
            idioma: values.idioma,
            type: type,
            uploader: userData.alias,
            mensaje: values.mensaje
        };
        setLoading(true);
        const response = await getCreatePostApartados(data);

        if (response.status === 400) {
            toast.warn('El post ya existe!', {
                position: 'bottom-left',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });

            setLoading(false);
            return;
        }
        if (response.status !== 200) {
            toast.error('Error al apartar post!', {
                position: 'bottom-left',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
            setLoading(false);
            return;
        }

        setPosts([...posts, response.data]);

        toast.success('Post apartado!', {
            position: 'bottom-left',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });

        // llamar funcion para notificar
        await createPostSeparetedtNotification(
            `${data.titulo} ${data.calidad} ${obtenerLabelResolucion(
                data.resolucion
            )} ${data.idioma}`,
            userData.alias
        );
        setDetails({});
        setLoading(false);
    };
    return (
        <Box p={1}>
            <Formik
                initialValues={{
                    poster: details.poster_path,
                    calidad: OBJDATA[0].options[0].value,
                    resolucion: OBJDATA[1].options[0].value,
                    idioma: OBJDATA[2].options[0].value,
                    title: details.title || details.name,
                    uploader: userData.alias,
                    mensaje: ''
                }}
                onSubmit={handleSubmit}>
                {({ values, handleChange }) => (
                    <Form>
                        {/* <code>{JSON.stringify(values)}</code> */}
                        <Stack
                            justifyContent={'center'}
                            alignItems={'center'}
                            gap={1}>
                            <div>
                                <img
                                    src={renderUrl(details.poster_path)}
                                    alt=""
                                    srcset=""
                                    style={{ width: 200, height: 300 }}
                                />
                            </div>
                            <Grid
                                container
                                // spacing={{ xs: 2, md: 1 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                                sx={{
                                    backgroundColor: '#CCD3CA',
                                    width: '100%',
                                    maxWidth: '430px',
                                    borderRadius: '5px'
                                }}>
                                <Grid item md={12} padding={1}>
                                    <CustomTextField
                                        fullWidth
                                        placeholder={'Titulo de la pelicula'}
                                        variant="outlined"
                                        value={values.title}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                {OBJDATA.map((item, index) => (
                                    <Grid
                                        item
                                        key={index}
                                        xs={2}
                                        sm={4}
                                        md={6}
                                        padding={0.7}>
                                        {item.type === 'select' ? (
                                            <CustomTextField
                                                size="small"
                                                fullWidth
                                                select
                                                variant="outlined"
                                                label={item.name}
                                                name={item.key}
                                                placeholder={item.name}
                                                defaultValue={
                                                    item.options[0].value
                                                }
                                                onChange={handleChange}>
                                                {item.options.map(
                                                    (option, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={
                                                                option.value
                                                            }>
                                                            {option.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </CustomTextField>
                                        ) : (
                                            <CustomTextField
                                                disabled={
                                                    item.key === 'uploader'
                                                        ? true
                                                        : false
                                                }
                                                type={item.type}
                                                fullWidth
                                                variant="outlined"
                                                label={item.name}
                                                placeholder={item.name}
                                                name={item.key}
                                                value={values[item.key]}
                                                onChange={handleChange}
                                            />
                                        )}
                                    </Grid>
                                ))}
                                <Grid
                                    item
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    sx={{
                                        padding: '2.8px 5.6px 5.5px 5.6px '
                                    }}>
                                    <CustomTextField
                                        type="textarea"
                                        fullWidth
                                        variant="outlined"
                                        label=""
                                        placeholder="Mensaje"
                                        name="mensaje"
                                        value={values.mensaje}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    sx={{
                                        padding: '2.8px 5.6px 5.5px 5.6px '
                                    }}>
                                    <LoadingButton
                                        loadingIndicator="Fichando..."
                                        fullWidth
                                        {...sxButton}
                                        loading={loading}>
                                        Fichar
                                    </LoadingButton>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    sx={{
                                        padding: '2.8px 5.6px 5.5px 5.6px '
                                    }}>
                                    <LoadingButton
                                        fullWidth
                                        onClick={() => setDetails({})}
                                        {...sxButtonCancel}>
                                        Cancelar
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default FicharForm;

const sxButton = {
    sx: {
        backgroundColor: '#FFC206',
        color: 'black',
        fontWeight: 'bold',
        position: 'relative',
        display: 'inline-block',
        width: '100%',
        height: '100%',
        '&:hover': {
            backgroundColor: '#d1a10a',
            color: '#fff',
            fontWeight: 'bold'
        }
    },
    variant: 'contained',
    type: 'submit'
};

const sxButtonCancel = {
    sx: {
        backgroundColor: '#260505',
        color: '#F7F6BB',
        fontWeight: 'bold',
        position: 'relative',
        display: 'inline-block',
        width: '100%',
        height: '100%',
        '&:hover': {
            backgroundColor: '#114232',
            color: '#fff',
            fontWeight: 'bold'
        }
    },
    variant: 'contained'
};
