import React from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import './styles.css';

const AlertInputUrl = () => {
    return (
        <div className="warning">
            <div className="d-flex">
                <div>
                    <FiAlertTriangle />
                </div>
            </div>
            <span>Por favor, genera una URL antes de continuar</span>
        </div>
    );
};

export default AlertInputUrl;
