import Layout from './layout/Layout';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppRouter from './routes/AppRouter';
import StoreProvider from './store/StoreProvider';
import { ToastContainer } from 'react-toastify';

const theme = createTheme({
    palette: {
        ochre: {
            main: '#ffc107',
            light: '#E9DB5D',
            dark: '#A29415',
            contrastText: '#242105'
        },
        white: {
            main: '#dae1e7',
            light: '#f8f9fa'
        },
        vino: {
            main: '#260505',
            light: '#4a0a0a',
            dark: '#1a0303'
        },
        whiteMain: {
            main: '#ffffff'
        },
        blueLight: {
            main: '#496989'
        },
        yellow: {
            main: '#FFB20B',
            contrastText: '#fff'
        }
    }
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <StoreProvider>
                {/* <div className="App"> */}
                <AppRouter />

                {/* </div> */}
                <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </StoreProvider>
        </ThemeProvider>
    );
}

export default App;
