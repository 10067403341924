import React from 'react';
import Select, { components } from 'react-select';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import makeAnimated from 'react-select/animated';

const MultiSelectFieldDelete = props => {
    const { options, setFieldValue, loadingAudios, handleDeleteAudio } = props;
    const animatedComponents = makeAnimated();

    const MultiValueRemove = props => {
        return (
            <div onClick={() => handleDeleteAudio(props.data.id)}>
                <components.MultiValueRemove {...props}>
                    <DeleteForeverIcon fontSize="1rem" />
                </components.MultiValueRemove>
            </div>
        );
    };
    return (
        <Select
            isLoading={loadingAudios}
            options={options}
            closeMenuOnSelect={false}
            defaultValue={[options[1], options[2]]}
            components={{ ...animatedComponents, MultiValueRemove }}
            isMulti
            value={props.value}
            onChange={value => setFieldValue(props.name, value)}
            styles={{
                multiValueRemove: base => ({
                    ...base,
                    border: `1px dotted #fff`,
                    height: '100%'
                })
            }}
        />
    );
};

export default MultiSelectFieldDelete;
