import { Button, Stack, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { validationSchemaConfigPaste } from '../../../../config/validation-squema';
import useFormChild from '../../hooks/useFormChild';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

const FormChild = () => {
    const { handleSubmit, loading } = useFormChild();

    const buttonStyle = {
        color: loading ? 'rgb(255 194 6 / 78%)' : '' // Cambia el color de fuente cuando loading es true
    };
    return (
        <Formik
            initialValues={{
                nombreServidor: '',
                urlServidor: '',
                urlImagenFree: '',
                urlImagenVip: ''
            }}
            validationSchema={validationSchemaConfigPaste}
            onSubmit={async (values, { resetForm }) => {
                await handleSubmit(values);
                resetForm(); // Utiliza la función resetForm para restablecer el formulario.
            }}>
            {({ values, errors, submitForm, handleChange, handleBlur }) => (
                <Form id="formi-config-paste">
                    <Stack direction={'row'} gap={2}>
                        <TextField
                            error={errors.nombreServidor ?? false}
                            id="outlined-nombre-servidor"
                            label="Nombre del servidor"
                            defaultValue="Hello World"
                            size="small"
                            name="nombreServidor"
                            value={values.nombreServidor}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.nombreServidor}
                        />
                        <TextField
                            error={errors.urlServidor ?? false}
                            id="outlined-url-servidor"
                            label="Url del servidor"
                            size="small"
                            name="urlServidor"
                            value={values.urlServidor}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.urlServidor}
                        />
                        <TextField
                            error={errors.urlImagenFree ?? false}
                            id="outlined-url-imagen-free"
                            label="Url de imagen free"
                            size="small"
                            name="urlImagenFree"
                            value={values.urlImagenFree}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.urlImagenFree}
                        />
                        <TextField
                            error={errors.urlImagenVip ?? false}
                            id="outlined-url-imagen-vip"
                            label="Url de imagen vip"
                            size="small"
                            name="urlImagenVip"
                            value={values.urlImagenVip}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.urlImagenVip}
                        />
                        <LoadingButton
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            onClick={submitForm}
                            form="formi-config-paste"
                            type="button"
                            variant="contained"
                            disableElevation
                            className="btn-primary"
                            style={buttonStyle}>
                            Guardar
                        </LoadingButton>
                        {/* <Button
                            onClick={submitForm}
                            form="formi-config-paste"
                            type="button"
                            variant="contained"
                            disableElevation
                            className="btn-primary">
                            Enviar
                        </Button> */}
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default FormChild;
