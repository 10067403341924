import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '../../store/StoreProvider';
import { getSearchFilmaFfinity } from '../../utils/filmaffinity';
// https://www.filmaffinity.com/co/film506901.html
const FilmaFfinity = () => {
    const { movie } = useStore();
    const [movieItems, setMovieItems] = useState([]);

    const handleSearchFilma = async e => {
        let dataFiltrada = [];
        const result = await getSearchFilmaFfinity(movie?.original_title);

        const max = result.length > 6 ? 5 : result.length;
        for (let i = 0; i < max; i++) {
            dataFiltrada.push(result[i]);
        }
        setMovieItems(dataFiltrada);
    };

    const handlePop = url => {
        window.open(
            url,
            'Nombre de la ventana',
            'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=1,width=900,height=750,left = 390,top = 50'
        );
    };
    useEffect(() => {
        handleSearchFilma();
    }, [movie]);

    return (
        <>
            {movieItems.length > 0 ? (
                <div>
                    <ul>
                        <h5>Filmaffinity</h5>
                        {movieItems.map(item => (
                            <li key={item.id}>
                                <Link
                                    to="/"
                                    key={item.id}
                                    onClick={() =>
                                        handlePop(
                                            // `https://www.filmaffinity.com/co/film${item.id}.html`
                                            `${item.id}.html`
                                        )
                                    }>
                                    {item.titulo}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <h5>No hay resultados Filmaffinity</h5>
            )}
        </>
    );
};

export default FilmaFfinity;
