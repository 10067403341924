import axios from 'axios';
import { getLocalStorage } from '../../../utils/functions';
import { API_REST_UNIVERSAL } from '../../../env';
const userData = getLocalStorage('user');

export async function getNotas() {
    try {
        const notas = await axios(`${API_REST_UNIVERSAL}/notas/${userData.id}`);
        return notas;
    } catch (error) {
        console.error(error);
    }
}
export async function crearNotas(nota) {
    try {
        const notas = await axios.post(`${API_REST_UNIVERSAL}/notas`, {
            text: nota,
            userId: userData.id
        });
        return notas;
    } catch (error) {
        console.error(error);
    }
}
export async function deleteNota(id) {
    try {
        const notas = await axios.delete(`${API_REST_UNIVERSAL}/notas`, {
            data: { id }
        });
        return notas;
    } catch (error) {
        console.error(error);
    }
}
export async function updateNota(values) {
    try {
        const notas = await axios.put(`${API_REST_UNIVERSAL}/notas`, {
            text: values.nota,
            noteId: values.id,
            userId: userData.id
        });
        return notas;
    } catch (error) {
        console.error(error);
    }
}
